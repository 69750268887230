import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    caption: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      paddingBottom: 40,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 55,
        fontSize: 18,
        lineHeight: 1.67,
      },
    },

    comparisonWrap: {
      width: '100%',
      display: 'grid',
      gridTemplateAreas: `
        "comparison"
        "disclaimer"
      `,

      gridTemplateColumns: '100%',

      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: '"disclaimer comparison"',
        gridTemplateColumns: '33.333333% 66.666667%',
      },
    },

    disclaimer: {
      gridArea: 'disclaimer',
      display: 'flex',
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-end',
        flexDirection: 'column',
        width: 292,
      },
    },

    comparison: {
      gridArea: 'comparison',
    },

    gap: {
      width: '100%',
      height: 8,
      [theme.breakpoints.up('sm')]: {
        height: 28,
      },
      [theme.breakpoints.up('lg')]: {
        height: 40,
      },
    },
  }),
  { name: 'Comparison' }
)
