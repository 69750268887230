/**
 * // useWindowDimension.ts
 * * This hook returns the viewport/window height and width
 */

import { useEffect, useState } from 'react'

type WindowDimentions = {
  width: number | undefined
  height: number | undefined
  refWidth: number | undefined
  refHeight: number | undefined
}

const relativeDimension = (ref: React.MutableRefObject<null>): WindowDimentions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
    width: undefined,
    height: undefined,
    refWidth: undefined,
    refHeight: undefined,
  })
  useEffect(() => {
    function handleResize(): void {
      const cRef: any = ref?.current
      setWindowDimensions({
        width: window?.innerWidth,
        height: window?.innerHeight,
        refWidth: cRef?.offsetWidth,
        refHeight: cRef?.offsetHeight,
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowDimensions
}

export default relativeDimension
