import React, { useState, useRef } from 'react'
import { Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import dynamic from 'next/dynamic'

import { ComparsionFields } from '../../../@types/Section/Comparsion'

import Module from '../Module'
// import Accordion from '../Accordion'
import Controls from './components/Controls'
import ComparisonSlider from './components/ComparisonSlider/'
// import comparisonMockData from './components/ComparisonSlider/ComparisonSlider.mockData.json'
// import accordionMockData from '../Accordion/Accordion.mockData.json'
// import detailsMockData from './components/Details/Details.mockData.json'

import Disclaimer from '../Disclaimer'

import useStyles from './Comparison.styles'
import Details from './components/Details'
import EditorText from '../EditorText'

type Props = {
  data: ComparsionFields
}

const Comparison: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  const prevPositionRef = useRef<number>(0)

  const [current, setCurrent] = useState<number>(0)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const total = data.comparsions.length

  const currentComparison = data.comparsions[current] || null
  const currentComparisonTitle = currentComparison?.title || ''

  const handleNext = () => {
    setCurrent(current + 1 === total ? current : current + 1)
  }

  const handleCurrent = (index: number) => {
    setCurrent(index)
  }

  const handleBack = () => setCurrent(current === 0 ? current : current - 1)

  const handleSliderPositionChange = (position: number) => {
    /**
     * During the initial load the position slightly changes
     * example: first 49.999999999999986 second 49.99999999999999 it varies betwean these values
     * Below line is decreasing the sensitivity so we can monitor only actuall user interactions
     */
    const roundedPosition = Math.round(position * 10000000) / 10000000

    if (prevPositionRef.current === 0) {
      return (prevPositionRef.current = roundedPosition)
    }

    const hasChanged = prevPositionRef.current !== 0 && prevPositionRef.current !== roundedPosition

    if (sessionStorage && hasChanged) {
      const interactionKey = encodeURIComponent(`${data.label}-${data.headline}-${currentComparisonTitle}`)
      const wasInteractedWith = interactionKey in sessionStorage

      if (!wasInteractedWith) {
        sessionStorage.setItem(interactionKey, '1')

        /** Google Analytics Event */
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({ event: 'Vorher-Nachher', vorherNachher: currentComparisonTitle })
        }
      }
    }
  }

  const leftImageDesktop = data.comparsions[current].left.image
  const leftIamgeMobile = data.comparsions[current]?.left_mobile?.image
  const leftImage = isMobile && Boolean(leftIamgeMobile) ? leftIamgeMobile : leftImageDesktop

  const rightImageDesktop = data.comparsions[current].right.image
  const rightImageMobile = data.comparsions[current]?.right_mobile?.image
  const rightImage = isMobile && Boolean(rightImageMobile) ? rightImageMobile : rightImageDesktop

  return (
    <Module label={data.label} disableHeading={!data?.label || !data?.headline} title={data.headline} fullWidthChildren>
      {data?.copy && (
        <>
          <Grid item xs={12} lg={4} />
          <Grid item xs={12} lg={8}>
            <Typography className={classes.caption}>{data.copy}</Typography>
          </Grid>
        </>
      )}

      <div className={classes.comparisonWrap}>
        <div className={classes.comparison}>
          {data.comparsions.length > 1 && (
            <Controls
              setCurrent={handleCurrent}
              current={current}
              total={total}
              comparsions={data.comparsions}
              onNext={handleNext}
              onBack={handleBack}
              colorOverwrite={data?.color_overwrite}
            />
          )}
          <ComparisonSlider
            label_left={data.comparsions[current].left.title}
            label_right={data.comparsions[current].right.title}
            image_left={leftImage}
            image_right={rightImage}
            onPositionChange={handleSliderPositionChange}
          />
        </div>

        <div className={classes.disclaimer}>
          <Disclaimer title={data?.disclaimer.title || ''} text={data?.disclaimer.copy || ''} />
        </div>
      </div>

      {!data?.comparsions?.[current]?.hide_comparison_copy && (
        <>
          <Grid item xs={12} lg={4} />
          <Grid item xs={12} lg={8}>
            <Details data={data.comparsions[current]} />
            <EditorText className={'no-top-margin'} content={data.comparsions[current].copy || ''} />
          </Grid>
        </>
      )}

      <div className={classes.gap} />
    </Module>
  )
}

export default dynamic(() => Promise.resolve(Comparison), { ssr: false })
