import useStyles from './DocFinderSearchItemOpen.styles'
import { Link, Typography } from '@material-ui/core'
import StarIcon from '../../../../../../public/icons/star-black.svg'
import WebIcon from '../../../../../../public/icons/icon-website.svg'
import MailIcon from '../../../../../../public/icons/icon-e-mail-black.svg'
import PhoneIcon from '../../../../../../public/icons/icon-phone-black.svg'
import FilterLabel from './components/FilterLabel'
import BaseButton from '../../../../BaseButton'
import { DocSearchFilter, DocSearchResultItem } from '../../../../../../@types/DocSearch'
import { useEffect, useMemo } from 'react'
import { docSearchFilters } from '../../docSearchFilters'
import useMatomoDocSearchOutboundLinkClick from '../../../../../hooks/useMatomoDocSearchOutboundLinkClick'

type ShareData = {
  title: string
  text: string
  url: string
}

type Props = {
  results: Partial<DocSearchResultItem>[]
  clickedPinSlug: string
  onClickPin: (slug: string) => void
  isOpen: boolean
}

const DocFinderSearchItemOpen: React.FC<Props> = ({ results, clickedPinSlug, onClickPin, isOpen }) => {
  const classes = useStyles({ isOpen })

  const { trackDocSearchOutboundLinkClick } = useMatomoDocSearchOutboundLinkClick()

  const result = results.find((item) => item.slug === clickedPinSlug)

  const openDirectionsLink = `http://maps.apple.com/?daddr=${result?.infos?.join(', ').replace(/ /g, '+')}` // Interestingly, http://maps.apple.com links will open directly in Apple Maps on an iOS device, or redirect to Google Maps otherwise (which is then intercepted on an Android device), so you can craft a careful URL that will do the right thing in both cases using an "Apple Maps" URL

  // Scroll THE OPENED RESULT to top of drawer
  useEffect(() => {
    const resultsElement = document.getElementById('drawer-content')
    resultsElement?.scrollTo(0, 0)
  }, [result])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSharing = async () => {
    // This is a modern approach to sharing, but still unstable in browsers

    const shareData: ShareData = {
      title: result?.title || '',
      text: result?.title || '',
      url: result?.website?.toLowerCase() || '',
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      console.error(`Error: ${err}`)
    }
  }

  const arealOptions = useMemo(() => {
    const resultAreas =
      result?.properties
        ?.find((item) => item.id === 'areas' && item.options.length > 0)
        ?.options.map((item) => item.id) || []
    const defaultGroupsOfAreas = docSearchFilters.filter(
      (item) => item.frontEndGroup === 'Behandlungsareal' && !item.isSelectingTheWholeGroup
    )
    const finalAreas = defaultGroupsOfAreas.reduce((prev, item) => {
      if (item.areas?.some((r) => resultAreas.includes(r))) {
        return [...prev, { id: item.frontEndName, name: item.frontEndName }]
      }
      return prev
    }, [] as DocSearchFilter[])
    return finalAreas
  }, [result?.properties])

  const productOptions = useMemo(
    () => result?.properties?.find((item) => item.id === 'products' && item.options.length > 0)?.options || [],
    [result?.properties]
  )

  return isOpen && result ? (
    <div className={classes.root}>
      <Typography component="p" onClick={() => onClickPin('')} className={classes.link}>
        Zurück zur Übersicht
      </Typography>

      <div className={classes.labelWrapper}>
        <span className={classes.iconWrap}>
          <StarIcon className={classes.star} />
        </span>
        <Typography component="p" className={classes.label}>
          Privatpraxis
        </Typography>
      </div>

      <Typography component="p" className={classes.title}>
        {result?.title}
      </Typography>

      {[...arealOptions, ...productOptions].length ? (
        <div className={classes.filterLabels}>
          <div className={classes.labels}>
            {productOptions.map((option) => (
              <FilterLabel key={option?.id} label={option?.name} labelcolor={option?.color} />
            ))}
          </div>
          <div className={classes.labels}>
            {arealOptions.map((option) => (
              <FilterLabel key={option?.id} label={option?.name} labelcolor={option?.color} />
            ))}
          </div>
        </div>
      ) : null}

      {result?.infos?.length && (
        <div className={classes.textInfo}>
          {result?.infos?.map((info, index) => (
            <Typography component="p" key={index} className={classes.info}>
              {info}
            </Typography>
          ))}
        </div>
      )}

      {result?.website && (
        <Link
          href={result.website.toLowerCase()}
          target="_blank"
          onClick={trackDocSearchOutboundLinkClick({
            doctor_name: result.title || '',
            outboundTarget: result.website.toLowerCase(),
            type: 'url',
            clickHandler: (e: any) => e.stopPropagation(),
          })}
        >
          <Typography component="p" className={classes.info}>
            <WebIcon className={classes.icon} /> {result.website.toLowerCase()}
          </Typography>
        </Link>
      )}

      {result?.email && (
        <Link
          href={`mailto:${result?.email}`}
          target="_blank"
          onClick={trackDocSearchOutboundLinkClick({
            doctor_name: result.title || '',
            outboundTarget: `mailto:${result?.email}`,
            type: 'email',
            clickHandler: (e: any) => e.stopPropagation(),
          })}
        >
          <Typography component="p" className={classes.info}>
            <MailIcon className={classes.icon} /> {result?.email}
          </Typography>
        </Link>
      )}

      {result?.phone && (
        <Link
          href={result?.phoneLink ? result?.phoneLink : `tel:${result?.phone}`}
          target="_blank"
          onClick={trackDocSearchOutboundLinkClick({
            doctor_name: result.title || '',
            outboundTarget: result?.phoneLink ? result?.phoneLink : `tel:${result?.phone}`,
            type: 'phone',
            clickHandler: (e: any) => e.stopPropagation(),
          })}
        >
          <Typography component="p" className={classes.info}>
            <PhoneIcon className={classes.icon} /> {result?.phone}
          </Typography>
        </Link>
      )}

      <div className={classes.btnWrap}>
        {result?.appointment_url ? (
          <Link
            href={result?.appointment_url}
            target="_blank"
            onClick={trackDocSearchOutboundLinkClick({
              doctor_name: result.title || '',
              outboundTarget: result?.appointment_url,
              type: 'book_appointment',
              clickHandler: (e: any) => e.stopPropagation(),
            })}
          >
            <BaseButton label="Online Termin vereinbaren" classNames={classes.btn} />
          </Link>
        ) : null}

        {result?.infos?.[0] ? (
          <Link
            href={openDirectionsLink}
            target="_blank"
            onClick={trackDocSearchOutboundLinkClick({
              doctor_name: result.title || '',
              outboundTarget: openDirectionsLink,
              type: 'address',
              clickHandler: (e: any) => e.stopPropagation(),
            })}
          >
            <BaseButton label="Zum Routenplaner" classNames={classes.btnSecondary} />
          </Link>
        ) : null}

        <Typography component="p" className={classes.shareInfo}>
          Informationen teilen mit
          <Link
            href={`mailto:?subject=${result?.title}&body=${result?.website?.toLowerCase()}`}
            target="_blank"
            className={classes.btnLink}
            onClick={trackDocSearchOutboundLinkClick({
              doctor_name: result.title || '',
              outboundTarget: `mailto:?subject=${result?.title}&body=${result?.website?.toLowerCase()}`,
              type: 'email',
              clickHandler: (e: any) => e.stopPropagation(),
            })}
          >
            Email
          </Link>
          oder
          <Link
            href={`whatsapp://send?text=${result?.title} - ${result?.website?.toLowerCase()}`}
            target="_blank"
            className={classes.btnLink}
            onClick={trackDocSearchOutboundLinkClick({
              doctor_name: result.title || '',
              outboundTarget: `whatsapp://send?text=${result?.title} - ${result?.website?.toLowerCase()}`,
              type: 'WhatsApp',
              clickHandler: (e: any) => e.stopPropagation(),
            })}
          >
            WhatsApp
          </Link>
        </Typography>
      </div>
    </div>
  ) : null
}

export default DocFinderSearchItemOpen
