import { TextField, FormControl } from '@material-ui/core'
import LocationIconArrow from '../../../../../../public/icons/location-arrow.svg'
import HeaderInfo from './components/HeaderIntro'
import useStyles from './DocFinderHeader.styles'

type Props = {
  onChangePostalCode: (e: React.ChangeEvent<HTMLInputElement>) => void
  postalCode: string
  onSearch: () => void
}

const DocFinderHeader: React.FC<Props> = ({ onChangePostalCode, postalCode, onSearch }) => {
  const classes = useStyles()

  const handleChangePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangePostalCode(e)
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (
      event?.code === 'Enter' ||
      event?.key === 'Enter' ||
      event?.code === 'NumpadEnter' ||
      event?.key === 'NumpadEnter'
    ) {
      onSearch()
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <HeaderInfo />
      </div>

      <FormControl className={classes.formControl}>
        <TextField
          variant="outlined"
          label="ORT"
          name="postalCode"
          value={postalCode}
          required
          InputLabelProps={{ classes: { root: classes.label } }}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.input,
            },
          }}
          onChange={handleChangePostalCode}
          onKeyUp={handleKeyUp}
        />
        <LocationIconArrow className={classes.locationIcons} onClick={onSearch} />
      </FormControl>
    </div>
  )
}

export default DocFinderHeader
