import React from 'react'

import { Typography } from '@material-ui/core'

import VideoPlayer from '../../../VideoPlayer'

import useStyles from './VideoComponent.styles'
import { VideoListItem } from '../../../../../@types/Section/VideoList'

type Props = {
  date: string
  title: string
  description: string
  data: VideoListItem
}

const VideoComponent: React.FC<Props> = ({ date, title, description, data }) => {
  const classes = useStyles()

  return (
    <div id="iframewrapper" className={classes.root}>
      <div className={classes.thumbnail}>
        <VideoPlayer data={data} />
      </div>

      <div className={classes.infoWrap}>
        <div className={classes.dateWrap}>
          <Typography className={classes.date}>
            Video <span /> {date}
          </Typography>
        </div>

        <Typography className={classes.title}>{title}</Typography>

        <Typography className={classes.description}>{description}</Typography>
      </div>
    </div>
  )
}
export default VideoComponent
