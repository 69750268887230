import React from 'react'

import { Typography, IconButton } from '@material-ui/core'

import clsx from 'clsx'

import SlideArrow from '../../../public/icons/icon-control-arrow.svg'

import useStyles from './SlideControlls.styles'

type Props = {
  activeSlideIndex: number
  slidesCount: number
  onBack?: () => void
  onNext?: () => void
}

const SlideControlls: React.FC<Props> = ({ onBack, onNext, activeSlideIndex = 0, slidesCount }) => {
  const classes = useStyles()

  const currentSlideNumber = (activeSlideIndex + 1).toString().padStart(2, '0')

  return (
    <div className={classes.sliderControlsWrapper}>
      <div className={classes.control}>
        {onBack && (
          <IconButton onClick={onBack} className={clsx(classes.controlButton, classes.controlLeftButton)}>
            <SlideArrow className={classes.arrow} />
          </IconButton>
        )}
      </div>

      <div className={classes.sliderProgresWrap}>
        <Typography variant="body1" className={classes.leftCounter}>
          {currentSlideNumber}
        </Typography>
        <div className={classes.sliderProgres}>
          <div
            className={classes.sliderProgresIndicator}
            style={{
              width: `${((activeSlideIndex + 1) / slidesCount) * 100}%`,
            }}
          ></div>
        </div>
        <Typography variant="body1" className={classes.rightCounter}>
          {slidesCount >= 10 ? slidesCount : `0${slidesCount}`}
        </Typography>
      </div>

      <div className={classes.control}>
        {onNext && (
          <IconButton onClick={onNext} className={classes.controlButton}>
            <SlideArrow className={classes.arrow} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default SlideControlls
