import React from 'react'
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'

import clsx from 'clsx'

import ExpandIcon from '../../../public/icons/icon-expand.svg'

import useStyles from './BaseSelect.styles'

type Props = {
  value: string | unknown
  onChange: (data: { name: string | undefined; value: number }) => void
  name: string
  options?: any[]
  label?: string
  unit?: string
  error?: boolean
  required?: boolean
  children?: React.ReactNode
  variant?: 'standard' | 'outlined' | 'filled'
  noBorders?: boolean
  className?: string
  labelClassName?: string
  selectIconClassName?: string
  selectClasses?: {
    root?: string
  }
  [x: string]: any
}

const BaseSelect: React.FC<Props> = ({
  onChange,
  value,
  label,
  name,
  options,
  unit,
  error,
  required,
  children,
  variant = 'outlined',
  noBorders = false,
  className,
  labelClassName,
  selectClasses,
  selectIconClassName,
  ...rest
}) => {
  const classes = useStyles()

  const handleSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    onChange({ name: event.target.name, value: event.target.value } as {
      name: string | undefined
      value: number
    })
  }

  return (
    <FormControl
      {...rest}
      variant={variant}
      classes={{
        root: classes.formControl,
      }}
      error={error}
      required={required}
    >
      <InputLabel className={clsx(classes.selectLabel, labelClassName)}>{label}</InputLabel>
      <Select
        className={clsx(classes.select, className, {
          [classes.noBorders]: noBorders,
        })}
        variant={variant}
        label={label}
        labelId={label}
        value={value}
        IconComponent={() => (
          <ExpandIcon width={32} height={32} className={clsx(classes.selectIcon, selectIconClassName)} />
        )}
        classes={{
          root: clsx(classes.selectRoot, selectClasses?.root),
        }}
        name={name}
        onChange={handleSelect}
        MenuProps={{
          classes: {
            paper: classes.paper,
            list: classes.list,
          },
          getContentAnchorEl: null,
        }}
        error={error}
      >
        {children
          ? children
          : options?.map((option, index) => (
              <MenuItem key={index} value={option} disableRipple>{`${option} ${unit ? unit : ''}`}</MenuItem>
            ))}
      </Select>
    </FormControl>
  )
}

export default BaseSelect
