import { makeStyles, Theme } from '@material-ui/core'

const TAB_HEIGHT = 54

type Props = {
  color?: string
}

const getIndicatorColor = (props: Props, theme: Theme) => (props?.color ? props?.color : theme.palette.beige.main)

export default makeStyles<Theme, Props>(
  (theme) => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(1),
      },

      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(2.5),
      },
    },
    tabs: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      minHeight: TAB_HEIGHT,
      display: 'none',
      visibility: 'hidden',

      borderColor: theme.palette.beige.main,
      borderStyle: 'solid',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,

      '& $tabRoot + $tabRoot': {
        marginLeft: theme.spacing(4),
      },

      [theme.breakpoints.up('sm')]: {
        display: 'inline-flex',
        visibility: 'visible',
      },
    },
    scroller: {
      display: 'inline-flex',
    },
    tabRoot: {
      minWidth: 'auto',
      minHeight: TAB_HEIGHT,
      padding: 0,
      fontSize: 20,
      fontFamily: 'ProximaNova-Regular',
      textTransform: 'initial',
      letterSpacing: 1,

      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
      },
    },
    tabTextColorPrimary: {
      color: theme.palette.common.black,
      '&$tabSelected': {
        color: theme.palette.common.black,
      },
    },
    tabSelected: {
      color: theme.palette.common.black,
      fontFamily: 'ProximaNova-SemiBold',
    },
    tabIndicator: {
      color: theme.palette.beige.main,
      backgroundColor: (props) => getIndicatorColor(props, theme),
      height: 4,
    },
    tabPanel: {
      padding: 0,
    },
    selectWrap: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
        visibility: 'hidden',
      },
    },
    baseSelect: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px !important',
      },
    },
    select: {
      padding: 0,
      fontSize: 20,
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: 1,
      paddingTop: 19,
      paddingBottom: 19,
    },
    selectLabel: {},
    selectIcon: { right: -4 },
  }),
  { name: 'Tabs' }
)
