import { makeStyles, styled } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({}))

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.up('md')]: { paddingTop: 32 },
      [theme.breakpoints.up('lg')]: {},
    },

    filterToggle: {
      display: 'flex',
      fontWeight: 'bold',
      gap: 7,
      alignItems: 'center',
      cursor: 'pointer',
    },

    expandIcon: {
      width: 30,
      height: 30,
      margin: '0px 2px 0px 2px',
      transform: 'rotate(-180deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),

      [theme.breakpoints.up('lg')]: {
        width: 32,
        height: 32,
      },

      '&$open': {
        transform: 'rotate(0)',
      },
    },
    open: {},

    topBtn: {
      cursor: 'pointer',
      padding: '5px 0px 5px 0px',
      border: 'solid 1px #000',
      color: '#000',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 25,
      width: '100%',
      fontSize: 14,
      lineHeight: '18px',
      marginBottom: 12,
      '&:hover': {
        backgroundColor: '#000',
        color: '#fff',
      },
      '&:hover svg path': {
        fill: '#fff',
      },
    },

    upArrow: {
      width: 33,
      marginRight: 6,
      '& rect': {
        stroke: 'rgb(142 142 142)',
      },
      '& path': {
        stroke: 'rgb(142 142 142)',
      },
    },

    partnerWrap: {
      borderBottom: '2px solid #43b02a',
      margin: '31px 0 12px 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        margin: '31px 0 20px 0',
      },
    },

    partner: {
      fontSize: 14,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
    },

    star: {
      width: 10,
      marginRight: 8,
    },

    carret: {
      width: 33,
    },

    results: {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '20px',
      borderBottom: '2px solid #000',
      width: '100%',
      marginTop: 35,
      marginBottom: 8,
    },

    itemsWrap: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      width: '100%',
    },
  }),
  { name: 'DocFinderSearchList' }
)
