import React, { useEffect, useState } from 'react'
import { Button, Typography, useTheme, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'

import { ContactFields } from '../../../@types/Section/Contact'

import Form from './components/Form'

import useStyles from './ContactForm.styles'

type Props = {
  data: ContactFields
}

const ContactForm: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  const theme = useTheme()

  const [activeTab, setActiveTab] = useState<string>('left')
  const isRightTabActive = Boolean(activeTab === 'right')
  const isLeftTabActive = Boolean(activeTab === 'left')
  const areBothActive = useMediaQuery(theme.breakpoints.up('lg'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    if (isDesktop) {
      setActiveTab('left')
    } else {
      setActiveTab('right')
    }
  }, [isDesktop])

  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        <Button
          variant="outlined"
          className={clsx(classes.tab, {
            [classes.activeTab]: !areBothActive && isLeftTabActive,
            [classes.leftTabWidth]: areBothActive,
          })}
          onClick={() => setActiveTab('left')}
          disableRipple={true}
        >
          <Typography
            component="h3"
            className={clsx(classes.label, { [classes.activeLabel]: areBothActive || isLeftTabActive })}
          >
            {data.info.title}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          className={clsx(classes.tab, {
            [classes.activeTab]: !areBothActive && isRightTabActive,
            [classes.rightTabWidth]: areBothActive,
          })}
          onClick={() => setActiveTab('right')}
          disableRipple={true}
        >
          <Typography
            component="h3"
            className={clsx(classes.label, { [classes.activeLabel]: areBothActive || isRightTabActive })}
          >
            {data.form.title}
          </Typography>
        </Button>
      </div>

      <div className={classes.content}>
        {(areBothActive || isLeftTabActive) && (
          <div className={clsx(classes.leftTabContent, { [classes.leftTabWidth]: areBothActive })}>
            <div className={classes.copy} dangerouslySetInnerHTML={{ __html: data.info.copy }} />
          </div>
        )}

        {(areBothActive || isRightTabActive) && (
          <div className={clsx(classes.rightTabContent, { [classes.rightTabWidth]: areBothActive })}>
            <Form data={data.form} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactForm
