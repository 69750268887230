import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    outerContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      flex: 1,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    innerContainer: {
      backgroundColor: 'white',
      maxWidth: 715,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      margin: '0 auto',
      padding: '22px 18px',
      [theme.breakpoints.up('sm')]: {
        padding: '55px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 50,
      },
    },
    btnWrap: {
      backgroundColor: '#fff',
      padding: '10px 0 47px 0',
      width: '100%',
      maxWidth: 300,
      [theme.breakpoints.up('sm')]: {
        padding: '28px 0 28px 0',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '16px 0 28px 0',
      },
    },

    btn: {
      width: '100%',
    },

    description: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    checkboxWrap: {
      width: 230,
    },
  }),
  { name: 'Consent' }
)
