import React from 'react'

import clsx from 'clsx'

import useStyles from './EditorText.styles'

type Props = {
  content: string
  anchor_id?: string
  className?: string
  invert?: boolean
}

const EditorText: React.FC<Props> = ({ anchor_id, className, content, invert }) => {
  const classes = useStyles()
  return (
    <div
      id={anchor_id}
      dangerouslySetInnerHTML={{ __html: content }}
      className={clsx(classes.content, className, { [classes.invert]: invert })}
    />
  )
}

export default EditorText
