import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
}

export default makeStyles<Theme, Props>(
  (theme) => ({
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: 40,
      marginBottom: 20,
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        columnGap: 14,
        marginBottom: 30,
      },

      [theme.breakpoints.up('lg')]: {
        columnGap: 37,
        marginBottom: 40,
      },
    },

    relatedTopicsRoot: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 23,
        paddingRight: 23,
      },
    },
  }),
  { name: 'RelatedArticles' }
)
