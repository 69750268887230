import { Typography } from '@material-ui/core'
import HeaderDescription from './HeaderDescription'
import useStyles from './HeaderInfo.styles'

type Props = {
  hideDescription?: boolean
}

const HeaderInfo: React.FC<Props> = ({ hideDescription }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleWrap}>
      <Typography variant="h1" className={classes.title}>
        Arzt-Suche
      </Typography>

      {!hideDescription ? <HeaderDescription /> : null}
    </div>
  )
}

export default HeaderInfo
