import { makeStyles } from '@material-ui/core'

const sliderHeightXS = 540
const sliderHeightSM = 640
const sliderHeightLG = 760

export default makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      width: '100%',
      height: sliderHeightXS,
      marginBottom: 60,
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
        marginBottom: 80,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
        marginBottom: 100,
      },

      '&.embedded': {
        marginBottom: 0,
        [theme.breakpoints.up('lg')]: {
          height: 717,
        },

        '& picture': {
          objectFit: 'contain',
          objectPosition: 'top center',
        },
      },
    },
    h1: {
      position: 'absolute',
      color: '#fff',
      opacity: 0,
      zIndex: 0,
      left: '-5000px',
      top: '-5000px',
    },
    slider: {
      width: '100%',
      overflow: 'hidden',
    },

    slide: {
      position: 'relative',
      width: '100%',
    },

    slideImage: {
      height: sliderHeightXS,
      display: 'block',
      overflow: 'hidden',
      width: '100%',
      margin: '0 auto',
      objectFit: 'cover',
      objectPosition: 'center',
      imageRendering: '-webkit-optimize-contrast',
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
      },
    },

    slideVideo: {
      height: sliderHeightXS,
      display: 'block',
      overflow: 'hidden',
      width: '100%',
      margin: '0 auto',
      objectFit: 'cover',
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
      },
    },

    contentWrap: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
      width: 304,
      [theme.breakpoints.up('sm')]: {
        width: 455,
      },
      [theme.breakpoints.up('lg')]: {
        width: 690,
      },
    },

    slideTitle: {
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: '2px',
      color: '#fff',
      textAlign: 'center',
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'column',
      margin: '45px 0 19px 0',
      padding: '0 17px',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        fontSize: 42,
        lineHeight: '42px',
        margin: '29px 0 16px 0',
        letterSpacing: '2px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '64px',
        margin: '52px 0 29px 0',
        letterSpacing: '4.8px',
      },

      '& *': {
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },
    },

    slideDescription: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '0.5px',
      color: '#fff',
      textAlign: 'center',
      margin: '0 0 16px',
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.5px',
      },
    },

    bg: {
      backgroundColor: theme.palette.backgroundBlack.main,
    },
    blurContent: {
      filter: 'blur(4px)',
    },

    sliderControlsContainer: {
      position: 'absolute',
      bottom: 0,
      marginBottom: 16,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 25,
        padding: '0 40px',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 36,
        padding: '0 83px',
      },

      '&.embedded': {
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 99%)',
        margin: 0,
        padding: '0 40px 16px 40px',
        [theme.breakpoints.up('sm')]: {
          padding: '0 40px 25px 40px',
        },
        [theme.breakpoints.up('lg')]: {
          padding: '76px 83px 36px 83px',
        },
      },
    },

    bgWrap: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: sliderHeightXS,
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
      },

      '&.embedded': {
        height: 'initial',

        [theme.breakpoints.up('lg')]: {
          maxHeight: 717,
        },
      },
    },
  }),
  { name: 'HomeStage' }
)
