import React from 'react'
import { Typography } from '@material-ui/core'

import clsx from 'clsx'

import useStyles from './Details.styles'
import { ComparsionItem } from '../../../../../@types/Section/Comparsion'

type Props = {
  data: ComparsionItem
}

const Details: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  const hasAge = data.copy_age !== '--' && !isNaN(parseInt(data.copy_age, 10))

  return (
    <div className={classes.container}>
      {hasAge && (
        <div
          className={clsx(classes.detail, {
            [classes.column]: true,
            [classes.row]: true,
          })}
        >
          <Typography className={classes.label}>{data.left.title}</Typography>
          <Typography className={classes.content}>{data.copy_age}</Typography>
        </div>
      )}
      <div
        className={clsx(classes.detail, {
          [classes.column]: hasAge,
          [classes.row]: true,
        })}
      >
        <Typography className={classes.label}>{data.right.title}</Typography>
        <Typography className={classes.content}>{data.copy_indication}</Typography>
      </div>
      {/* {data.map((detail, index) => {
        let widthPercent = parseInt(detail?.width.replace('%', ''), 10)
        widthPercent = isNaN(widthPercent) ? 100 : widthPercent

        const isColumn = widthPercent <= 50
        const isRow = widthPercent > 50

        return (
          <div
            key={index}
            className={clsx(classes.detail, {
              [classes.column]: isColumn,
              [classes.row]: isRow,
            })}
          >
            <Typography className={classes.label}>{detail.label}</Typography>
            <Typography className={classes.content}>{detail.content}</Typography>
          </div>
        )
      })} */}
    </div>
  )
}

export default Details
