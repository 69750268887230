import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: 60,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 30,
      },
    },
    mainSearchBarArea: {
      display: 'none',
      [theme.breakpoints.between('sm', 'md')]: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40,
      },
    },
    sidebarWithMapArea: {
      display: 'flex',
      flexDirection: 'row',
    },
    sidebarArea: {
      display: 'none',
      height: '80vh',
      overflowY: 'scroll',
      [theme.breakpoints.up('sm')]: {
        display: 'initial',
        width: '279px',
        paddingRight: 38,
      },
      [theme.breakpoints.up('lg')]: {
        width: '329px',
      },
    },
    sidebarSearchArea: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'initial',
      },
    },
    resultsSlidingWrapper: {
      transition: 'all 0.5s',
      overflowX: 'hidden',
      zIndex: 1,
      boxShadow: '20px 0 20px -20px #333;',
      backgroundColor: '#121212',
    },
    mapArea: {
      flex: 1,
      height: '80vh',
    },
  }),
  { name: 'DocFinderLayout' }
)
