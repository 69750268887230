import React from 'react'
import { Grid, Typography, Container } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './Module.styles'

type Props = {
  children: React.ReactNode
  id?: string
  title?: string
  label?: string
  fullWidthChildren?: boolean
  invert?: boolean
  classNames?: { title?: string }
  containerForHeading?: boolean
  disableHeading?: boolean
  transparentBG?: boolean
}

const ModuleContainer: React.FC<Props> = ({
  children,
  id,
  label,
  title,
  fullWidthChildren = false,
  invert,
  classNames,
  containerForHeading = false,
  disableHeading = false,
  transparentBG = false,
}) => {
  const classes = useStyles()

  const heading = !disableHeading && (
    <>
      <Grid item xs={12} lg={4}>
        {label && <Typography className={clsx(classes.heading, { [classes.invert]: invert })}>{label}</Typography>}
      </Grid>
      <>
        {title && title != '' && (
          <Grid item xs={12} lg={8}>
            <Typography
              component="h2"
              className={clsx(classes.title, { [classes.invert]: invert }, classNames?.title)}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
        )}
      </>
    </>
  )

  return (
    <Grid
      id={id}
      container
      className={clsx(classes.root, {
        'no-label': label === '',
        [classes.invert]: invert,
        [classes.transparentBG]: transparentBG,
      })}
    >
      {containerForHeading ? (
        <Container maxWidth="lg" style={{ overflow: 'hidden' }}>
          {heading}
        </Container>
      ) : (
        heading
      )}

      {!fullWidthChildren && (
        <>
          {title && title != '' && <Grid item xs={12} lg={4}></Grid>}

          <Grid item xs={12} lg={8}>
            {children}
          </Grid>
        </>
      )}

      {fullWidthChildren && children}
    </Grid>
  )
}

export default ModuleContainer
