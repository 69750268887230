import React, { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

import useStyles from './ZoomImage.styles'
import { ZoomImageFields } from '../../../@types/Section/ZoomImage'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import clsx from 'clsx'

type Props = {
  data: ZoomImageFields
  invert?: boolean
}

const ZoomImage: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const image = data[isDesktop ? 'image_desktop' : 'image_mobile']
  const imageId = `parallax__image_${image?.alt?.toLowerCase().split(' ').join('')}`

  const zoomVelocsity = 1200
  const containerHeight = isMobile ? 400 : isDesktop ? 600 : 460

  const parallax = () => {
    const img = document.getElementById(imageId)

    if (img) {
      const windowTop = window?.scrollY
      const windowHeight = window?.innerHeight
      const windowBottom = windowTop + windowHeight

      const imageTop = img?.offsetTop
      const imageHeight = img?.offsetHeight
      const imageBottom = imageTop + imageHeight

      const sub = imageTop - windowTop
      const top = Math.abs((sub / zoomVelocsity) * (containerHeight / 2))

      if (!(imageTop > windowBottom || imageBottom < windowTop)) {
        if (sub >= 0) {
          img.style.transform = `scale(${sub / zoomVelocsity + 1})`
          img.style.top = `${top}px`
        } else {
          img.style.transform = `scale(1) translateY(${-sub / 2}px)`
          img.style.top = `${top}px`
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', parallax)
    window.addEventListener('resize', parallax)

    return () => {
      window.removeEventListener('scroll', parallax)
      window.removeEventListener('resize', parallax)
    }
  })

  return (
    <div className={clsx(classes.root, { height: containerHeight })} id={data?.anchor_id}>
      <img id={imageId} src={image?.url} alt={image?.alt} className={classes.image} />
    </div>
  )
}

export default ZoomImage
