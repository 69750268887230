import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      paddingLeft: 36,
      paddingRight: 36,
      left: -1000,

      transition: 'width 0.5s, min-height 0.5s, max-height 0.5s, left 0.5s linear',

      '&$visible': {
        left: 0,
      },

      [theme.breakpoints.up('lg')]: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.beige.main}`,
        position: 'fixed',
        zIndex: 2,
        top: '50%',
        justifyContent: 'unset',
        width: 265,
        overflow: 'hidden',
        maxHeight: 60,
        paddingLeft: 0,
        paddingRight: 0,

        '&$open': {
          width: 373,
          maxHeight: '100%',
          '& $list': {
            opacity: 1,
          },

          '& svg': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },

    container: {
      width: '100%',
      border: `1px solid ${theme.palette.beige.main}`,
      padding: '25px 19px 27px 18px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      backgroundColor: '#fff',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 455,
      },
      [theme.breakpoints.up('lg')]: {
        rowGap: 'inherit',
        border: 'none',
        padding: '20px 19px 20px 62px',
        position: 'relative',
      },
    },

    title: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 600,
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        '& svg': {
          position: 'absolute',
          top: 14,
          right: 10,
        },
      },
    },

    link: {
      color: '#000',
      fontSize: 14,
      letterSpacing: 'normal',
      maxWidth: 249,
      textDecoration: 'underline',
      fontFamily: 'ProximaNova-Regular',
      lineHeight: 1.43,
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'unset',
      },

      [theme.breakpoints.up('lg')]: {
        opacity: 0,
        visibility: 'hidden',
        maxHeight: 0,
        transition: 'all 0.5s',

        '&.visible': {
          opacity: 1,
          visibility: 'visible',
          maxHeight: '100%',
        },
      },
    },

    toggleIcon: {
      display: 'none',

      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      position: 'relative',

      [theme.breakpoints.up('lg')]: {
        rowGap: 16,
        marginTop: 16,
      },
    },

    visible: {},

    open: {},

    progress: {
      display: 'none',
      width: 4,
      height: '0%',
      backgroundColor: '#E2E1DD',
      top: 0,
      left: -12,
      position: 'absolute',
      transition: 'height 0.2s',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
  }),
  { name: 'AnchorNavigation' }
)
