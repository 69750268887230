import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    button: {
      borderRadius: 0,
      borderWidth: 0,
      padding: 0,
      minWidth: 'auto',
      width: 30,
      height: 53,
      position: 'relative',
      overflow: 'visible',
      backgroundColor: 'transparent',
      alignItems: 'flex-end',
      top: -36,
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg:nth-child(1)': {
          opacity: 0,
          transition: 'opacity .2s ease-out',
        },
        '& div': {
          opacity: 1,
          transition: 'opacity .2s ease-in .2s',
          '& svg:nth-child(1)': {
            opacity: 1,
            transition: 'opacity .2s ease-out .2s',
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 56,
        top: -38,
      },

      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 80,
        top: -52,
      },
    },

    readMoreIcon: {
      width: 30,
      height: 53,
      cursor: 'pointer',
      opacity: 1,
      transition: 'opacity .2s ease-in .2s',

      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 56,
      },

      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 80,
      },
    },

    hoverReadMoreIcon: {
      transform: 'rotate(90deg)',
      width: 20,
      height: 20,
      [theme.breakpoints.up('sm')]: {
        width: 24,
        height: 24,
      },
      [theme.breakpoints.up('lg')]: {
        width: 30,
        height: 30,
      },
    },

    square: {
      backgroundColor: theme.palette.beige.main,
      display: 'flex',
      opacity: 0,
      width: 30,
      height: 30,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      bottom: 0,
      left: 0,
      transition: 'opacity .2s ease-out',
      borderRadius: 20,
      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },
    },
  }),
  { name: 'AnkerLink' }
)
