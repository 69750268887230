import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme, { color?: string }>(
  (theme) => ({
    accordion: {
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      border: 0,
      borderBottomWidth: '1px',
      borderColor: (props) => (props?.color ? props.color : '#ccc'),
      borderStyle: 'solid',
    },

    accordionExpanded: {
      margin: '0 !important',
    },

    accordionRounded: {
      borderRadius: '0 !important',
    },

    accordionSummary: {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 9,
        paddingBottom: 9,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },

    accordionContent: {
      '&$expanded': {
        margin: theme.spacing(1.5, 0),
      },
    },

    accordionDetails: {
      padding: 0,
      paddingBottom: 31,
      maxWidth: '90%',
    },

    title: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: 1.67,
      },
    },

    titleBold: {
      fontFamily: 'ProximaNova-Semibold',
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: 1.67,
      },
    },

    icon: {
      fill: theme.palette.common.black,
      width: 30,
      height: 30,
      margin: '0px 2px 0px 2px',
      transform: 'rotate(-180deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),

      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },

      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },
    },

    expanded: {},
  }),
  { name: 'Accordion' }
)
