import React from 'react'
import { Button } from '@material-ui/core'

import ReadMoreArrow from '../../../public/icons/button-anker-black-s.svg'
import IconArrowDown from '../../../public/icons/icon-arrow.svg'

import useStyles from './AnkerLink.styles'

type Props = {
  onClick: () => void
}

const AnkerLink: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button onClick={onClick} className={classes.button} disableRipple={true}>
      <ReadMoreArrow className={classes.readMoreIcon} />
      <div className={classes.square}>
        <IconArrowDown className={classes.hoverReadMoreIcon} />
      </div>
    </Button>
  )
}

export default AnkerLink
