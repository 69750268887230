import React from 'react'

import { Typography, Container } from '@material-ui/core'

import Disclaimer, { Props as DisclaimerProps } from '../Disclaimer'

import useStyles from './ModuleWrap.styles'
import clsx from 'clsx'

type Props = {
  id?: string
  label?: string
  heading?: string
  copySidebar?: React.ReactNode
  copy?: string
  disclaimer?: DisclaimerProps
  main?: React.ReactNode
  secondarySidebar?: React.ReactNode
  secondary?: React.ReactNode
}

const ModuleWrap: React.FC<Props> = ({
  id,
  label,
  heading,
  copySidebar,
  copy,
  disclaimer,
  main,
  secondarySidebar,
  secondary,
}) => {
  const classes = useStyles()

  return (
    <Container id={id} maxWidth="lg">
      <div className={clsx(classes.root, { 'no-label': !label })}>
        <div className={classes.label}>{label && <Typography className={classes.labelText}>{label}</Typography>}</div>
        <div className={classes.heading}>
          {heading && (
            <Typography className={classes.headingText} align="left">
              {heading}
            </Typography>
          )}
        </div>
        <div className={classes.copySidebar}>{copySidebar}</div>
        <div className={classes.copy}>
          <Typography component="span" className={classes.copyText} dangerouslySetInnerHTML={{ __html: copy || '' }} />
        </div>
        <div className={classes.disclaimer}>{disclaimer && <Disclaimer {...disclaimer} />}</div>
        <div className={classes.main}>{main}</div>
        <div className={classes.secondarySidebar}>{secondarySidebar}</div>
        <div className={classes.secondary}>{secondary}</div>
      </div>
    </Container>
  )
}

export default ModuleWrap
