import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme>(
  (theme) => ({
    root: {
      marginTop: theme.spacing(1),
      display: 'grid',

      gridTemplateAreas: `
        "icon content"
      `,

      gridAwutoFlow: 'row',

      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: `
        "icon"
        "title"
        "content"
      `,
      },
    },
    icon: {
      gridArea: 'icon',
      marginRight: theme.spacing(1),
      width: 16,
      height: 16,

      [theme.breakpoints.up('sm')]: {
        width: 20,
        height: 20,
      },

      [theme.breakpoints.up('lg')]: {
        width: 24,
        height: 24,
        marginBottom: theme.spacing(1),
      },
    },
    title: {
      gridArea: 'title',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 'normal',

      [theme.breakpoints.down('md')]: {
        display: 'none',
        visibility: 'hidden',
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },
    content: {
      gridArea: 'content',
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: 'normal',
      position: 'relative',
      top: 2,

      [theme.breakpoints.up('sm')]: {
        top: 0,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&:before': {
        content: '"Disclaimer:"',
        fontWeight: 600,
        marginRight: 4,

        [theme.breakpoints.up('lg')]: {
          content: '"" !important',
          marginRight: 0,
        },
      },
    },
  }),
  {
    name: 'ComparisonDisclamer',
  }
)
