import React from 'react'
import { Button, MenuItem, Menu } from '@material-ui/core'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import { ComparsionItem } from '../../../@types/Section/Comparsion'

import IconExpand from '../../.././public/icons/icon-expand.svg'

import useStyles from './BaseDropdown.styles'
import { ColorOverwrite } from '../../../@types/Fields'

type Props = {
  options: ComparsionItem[]
  setCurrent: (index: number) => void
  current: number
  colorOverwrite?: ColorOverwrite
}

const BaseDropdown: React.FC<Props> = ({ setCurrent, current, options, colorOverwrite }) => {
  const classes = useStyles({ color: colorOverwrite?.color })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMenuItem = (index: number) => {
    return () => {
      // const target = event.target as HTMLElement
      setAnchorEl(null)
      setCurrent(index)
    }
  }

  return (
    <>
      <Button
        aria-controls="base-dropdown"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple={true}
        disableElevation
        className={classes.button}
        endIcon={<IconExpand className={clsx(classes.arrowIcon, { [classes.rotatedArrowIcon]: open })} />}
      >
        {options[current].title}
      </Button>
      <Menu
        id="base-dropdown"
        MenuListProps={{
          'aria-labelledby': 'base-dropdown',
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} onClick={handleClickMenuItem(index)} disableRipple>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default dynamic(() => Promise.resolve(BaseDropdown), { ssr: false })
