import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme>(
  (theme) => ({
    root: {},
    title: {
      paddingBottom: 0,

      [theme.breakpoints.up('lg')]: {
        paddingBottom: 24,
      },
    },
  }),
  {
    name: 'DownloadList',
  }
)
