import React from 'react'

import icons from './icons'

type Props = {
  name: string
  className: string
}

const Icon: React.FC<Props> = ({ name, className }) => {
  const Component = icons[name]

  if (!Component) return null

  return <Component className={className} />
}

export default Icon
