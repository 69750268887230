import React from 'react'

import { ImageGalleryFields } from '../../../../../@types/Section/ImageGallery'

import GalleryImage from '../GalleryImage'

type Props = {
  data: ImageGalleryFields
  invert?: boolean
}

const GalleryDesktop: React.FC<Props> = ({ data, invert }) => {
  return (
    <>
      {data?.image_1 && <GalleryImage id="image_1" data={data.image_1} invert={invert} />}

      {data?.image_2 && <GalleryImage id="image_2" data={data.image_2} invert={invert} />}

      {data?.image_3 && <GalleryImage id="image_3" data={data.image_3} invert={invert} />}

      {data?.image_4 && <GalleryImage id="image_4" data={data.image_4} invert={invert} />}

      {data?.image_5 && <GalleryImage id="image_5" data={data.image_5} invert={invert} />}

      {data?.image_6 && <GalleryImage id="image_6" data={data.image_6} invert={invert} />}

      {data?.image_7 && <GalleryImage id="image_7" data={data.image_7} invert={invert} />}
    </>
  )
}

export default GalleryDesktop
