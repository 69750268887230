import React from 'react'

import { RelatedTopicsFields } from '../../../@types/Section/RelatedTopics'

import BlogTile from '../BlogTile'

import useStyles from './RelatedArticles.styles'

type Props = {
  data: RelatedTopicsFields
  invert?: boolean
}

const RelatedArticles: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles({ color: data?.color_overwrite?.color })

  if (!data) return null

  return (
    <div className={classes.grid}>
      {data?.articles?.map(
        (article, index) =>
          article && (
            <BlogTile
              key={index}
              classes={{ root: classes.relatedTopicsRoot }}
              related
              data={{
                ...article.article,
                color_overwrite: {
                  ...data?.color_overwrite,
                },
              }}
              invert={invert}
            />
          )
      )}
    </div>
  )
}

export default RelatedArticles
