import React from 'react'

import { Typography, Button } from '@material-ui/core'
import Link from 'next/link'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import { CtaTeaser01Fields } from '../../../@types/Section/CtaTeaser01'

import useStyles from './CTATeaser.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data: CtaTeaser01Fields
}

const CTATeaser: React.FC<Props> = ({ data }) => {
  const classes = useStyles({
    color: data?.color_overwrite?.color,
    invertModuleColor: data?.color_overwrite?.invert_module_color,
    textColor: data?.color_overwrite?.text_color,
  })

  const [trackedLinkClick] = useMatomoLinkTracking()

  const isReversed = data.image_position === 'right'

  const isWhiteBg = data.background_color === 'white'
  const isBeigeBG = data.background_color === 'beige'

  return (
    <div className={clsx(classes.container, { [classes.reversed]: isReversed })}>
      <div className={classes.imageColumn}>
        <Link href={data?.button?.target || '#'} passHref>
          <a
            onClick={trackedLinkClick({
              label: data?.button?.label || '',
              href: data?.button?.target || '',
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <ResponsiveImage className={classes.image} image={data.image} />
          </a>
        </Link>
      </div>
      <div className={clsx(classes.textColumn, { [classes.isWhiteBg]: isWhiteBg, [classes.isBeigeBG]: isBeigeBG })}>
        <div className={clsx(classes.wrapper, { [classes.isWhiteBg]: isWhiteBg, [classes.isBeigeBG]: isBeigeBG })}>
          <div className={classes.box}>
            <Typography className={classes.label}>{data?.label}</Typography>
            <Typography component="h2" className={classes.headline}>
              {data?.title}
            </Typography>
            <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: data.copy }}></Typography>
            {data?.has_button && data.button && (
              <Link href={data?.button?.target} passHref>
                <Button
                  variant="outlined"
                  className={classes.button}
                  href={data?.button.target}
                  target={data?.button.type === 'external' ? '_blank' : undefined}
                  disableRipple={true}
                  onClick={trackedLinkClick({
                    label: data?.button?.label || '',
                    href: data?.button?.target || '',
                    position: MotomoLinkTrackingPosition.BODY,
                    isCta: true,
                  })}
                >
                  <Typography className={classes.buttonLabel}>{data?.button.label}</Typography>
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default dynamic(() => Promise.resolve(CTATeaser), { ssr: false })
