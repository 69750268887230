import React from 'react'
import { Container } from '@material-ui/core'
import dynamic from 'next/dynamic'

import { RelatedTopicsFields } from '../../../../../@types/Section/RelatedTopics'

import Module from '../../../Module'
import RelatedArticles from '../../../RelatedArticles'

type Props = {
  fields: RelatedTopicsFields
  invert?: boolean
}

const RelatedTopicsModule: React.FC<Props> = ({ fields, invert }) => {
  return (
    <Container id={fields?.anchor_id || undefined} maxWidth="lg">
      <Module title={fields.headline} label={fields.label} invert={invert}>
        <RelatedArticles data={fields} invert={invert} />
      </Module>
    </Container>
  )
}

export default dynamic(() => Promise.resolve(RelatedTopicsModule), { ssr: false })
