import { ImageField } from '../Fields'
import { Section } from '../Page'

export interface TextImageSection extends Section {
  fields: TextImageFields
}

export enum TextImageMode {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export interface TextImageFields {
  mode: string
  caption_title?: string
  caption_copy?: string
  copy?: string
  label?: string
  image: ImageField
  anchor_id?: string
}
