import React, { useMemo, useEffect, useState, useRef } from 'react'

import { Typography, Link } from '@material-ui/core'

import clsx from 'clsx'

import ArrowIcon from '../../../public/icons/icon-closed-black.svg'

import { AnchorNav } from '../../../@types/Section/AnchorNav'

import useStyles from './AnchorNavigation.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data?: AnchorNav
}

const AnchorNavigation: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  const [visible, setVisible] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(true)

  const itemsRefs = useRef<(HTMLElement | null)[]>([])
  const listContainerRef = useRef<HTMLDivElement | null>(null)
  const rootElementRef = useRef<HTMLDivElement | null>(null)
  const progressElRef = useRef<HTMLSpanElement | null>(null)

  const titlePositions = useMemo(() => {
    if (typeof document !== 'undefined') {
      return data?.anchor_links
        .map((anchor_link) => {
          const el = document.getElementById(anchor_link.target_id)

          return el?.offsetTop
        })
        .filter((item) => item) // Filter out undefined values
    }
  }, [data?.anchor_links])

  useEffect(() => {
    if (open) showListItems()
  }, [])

  useEffect(() => {
    indicateProgress()
  }, [open])

  const showListItems = () => {
    itemsRefs.current.map((item, index) => {
      setTimeout(() => {
        item?.classList?.add('visible')
      }, 75 * index)
    })
  }

  const hideListItems = () =>
    Promise.all(
      itemsRefs.current
        .slice(0)
        .reverse()
        .map(
          (item, index) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve(item?.classList?.remove('visible'))
              }, 0 * index)
            })
        )
    )

  const getReadingProgress = (): [boolean[], boolean] => {
    const positionY = document.body.scrollTop || document.documentElement.scrollTop

    const screenHeight = (typeof window !== 'undefined' && window.innerHeight) || 0

    if (typeof document !== 'undefined') {
      const progress =
        titlePositions?.map?.((titlePosition) => {
          const threshold = screenHeight * 0.2 + ((titlePosition || 0) - screenHeight)

          return positionY > threshold
        }) || []

      const lastElementPosition = [...(titlePositions || [])]?.pop()

      const doneReading = positionY > screenHeight * 1.4 + ((lastElementPosition || 0) - screenHeight)

      return [progress, doneReading]
    }

    return [[], false]
  }

  const indicateProgress = () => {
    const [redingProgress, doneReading] = getReadingProgress()

    const hasVisible = Boolean(redingProgress?.includes(true))

    const visibleCount = [...redingProgress].reduce((prev, current) => (current ? prev + 1 : prev), 0)

    const progress = (visibleCount / redingProgress.length) * 100

    if (progress > 0 && progressElRef.current) {
      progressElRef.current.style.height = `${progress}%`
    }

    if (doneReading) return setVisible(false)

    setVisible(hasVisible)
  }

  const listenToScroll = () => {
    indicateProgress()
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)

    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const handleClick = async () => {
    if (!open) {
      setOpen(!open)

      setTimeout(() => {
        showListItems()
      }, 150)
    }

    await hideListItems()

    setOpen(!open)
  }

  const handleAnchorClick = (targetId: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const targetElement = document.getElementById(targetId)

    if (targetElement) {
      const topOffset = 150

      const targetElementYPosition = (targetElement?.offsetTop || 0) - topOffset

      requestAnimationFrame(() => {
        window.scrollTo(0, targetElementYPosition)
      })
    }
  }

  return (
    <div
      ref={rootElementRef}
      onClick={handleClick}
      className={clsx(classes.root, { [classes.visible]: visible, [classes.open]: open })}
    >
      <div className={classes.container}>
        <Typography component="p" className={classes.title}>
          {data?.title}
          <ArrowIcon className={classes.toggleIcon} width={32} height={32} />
        </Typography>

        <div className={classes.list} ref={listContainerRef}>
          {open && <span ref={progressElRef} className={classes.progress} />}
          {data?.anchor_links?.map((link, index) => (
            <Link
              key={link.target_id}
              ref={(el) => (itemsRefs.current[index] = el)}
              href={`#${link.target_id}`}
              className={classes.link}
              onClick={trackedLinkClick({
                label: link.label,
                href: `#${link.target_id}`,
                position: MotomoLinkTrackingPosition.BODY,
                clickHandler: handleAnchorClick(link.target_id),
              })}
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AnchorNavigation
