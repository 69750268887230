import React from 'react'
import clsx from 'clsx'
import { Typography, Button, IconButton } from '@material-ui/core'

import { HotspotImageSubEntry } from '../../../@types/Section/HotspotImage'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import SlideArrow from '../../../public/icons/icon-control-arrow.svg'

import useStyles from './ApplicationModal.styles'
import TrackedNextLink from '../TrackedNextLink'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  itemId: string
  data: HotspotImageSubEntry | null
  area: Record<string, HotspotImageSubEntry> | null
  onChange?: (id: string) => void
}

const ApplicationModal: React.FC<Props> = ({ itemId, data, area, onChange = () => false }) => {
  if (!data) return null

  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  const areaKeys = (area && Object.keys(area)) ?? []
  const areaLength = areaKeys?.length
  const showControls = Boolean(areaLength > 1)

  const prevStepKey = area ? areaKeys[areaKeys.indexOf(itemId) - 1] || areaKeys[areaLength - 1] : ''
  const nextStepKey = area ? areaKeys[areaKeys.indexOf(itemId) + 1] || areaKeys[0] : ''

  const prevStep = (area && area[prevStepKey]) ?? null
  const nextStep = (area && area[nextStepKey]) ?? null

  const handleBack = () => onChange(prevStepKey)

  const handleNext = () => onChange(nextStepKey)

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.titleSection}>
          <Typography className={classes.label} align="left">
            {data?.area}
          </Typography>

          <Typography className={classes.title} align="left">
            {data.title}
          </Typography>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.imageSection}>
          <div className={classes.imageBox}>
            <ResponsiveImage className={classes.image} image={data.image} />
          </div>

          <Typography
            component="span"
            className={classes.description}
            align="left"
            dangerouslySetInnerHTML={{ __html: data.copy }}
          />
        </div>

        <div className={classes.infoSection}>
          <Typography className={classes.infoTitle} align="left">
            {data.cta_title}
          </Typography>

          <div className={classes.ctaCopy} dangerouslySetInnerHTML={{ __html: data.cta_copy || '' }} />

          {data.button && (
            <TrackedNextLink href={data?.button?.target} passHref>
              <Button
                variant="outlined"
                className={classes.button}
                href={data?.button.target}
                target={data?.button.type === 'external' ? '_blank' : undefined}
                onClick={trackedLinkClick({
                  label: data?.button.label,
                  href: data?.button.target || '',
                  position: MotomoLinkTrackingPosition.BODY,
                  isCta: true,
                })}
              >
                {data?.button.label}
              </Button>
            </TrackedNextLink>
          )}
        </div>
      </div>

      {showControls && (
        <div className={classes.buttonSection}>
          <div className={classes.controlContainer}>
            <div className={classes.btnWrapLeft}>
              {prevStep && (
                <>
                  <IconButton onClick={handleBack} className={clsx(classes.controlButton, classes.controlLeftButton)}>
                    <SlideArrow className={classes.arrow} />
                  </IconButton>
                  <Typography className={classes.arrowText}>{prevStep.title}</Typography>
                </>
              )}
            </div>

            <div className={classes.btnWrapRight}>
              {nextStep && (
                <>
                  <Typography className={classes.arrowText}>{nextStep.title}</Typography>
                  <IconButton onClick={handleNext} className={classes.controlButton}>
                    <SlideArrow className={classes.arrow} />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ApplicationModal
