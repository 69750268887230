import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      marginTop: 30,
      marginBottom: 70,
      position: 'relative',

      '&$noImage': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },

      '&:hover': {
        '& $overlay': {
          display: 'flex',
        },
      },
    },

    noImage: {},

    invert: {},

    fallbackText: {
      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    overlay: {
      position: 'absolute',
      backgroundColor: 'rgba(18, 18, 18, 0.56)',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '0px 15.834%',
      display: 'none',
      alignItems: 'flex-end',
    },

    overlayContent: {
      width: '100%',
      borderTop: `1px solid ${theme.palette.beige.main}`,
      padding: theme.spacing(1, 2, 4, 2),
    },

    captionTitle: {
      color: theme.palette.beige.main,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },

    captionCopy: {
      fontSize: 24,
      color: theme.palette.common.white,
      letterSpacing: 1,
      fontFamily: 'ProximaNova-Semibold',
      textAlign: 'center',
      lineHeight: '34px',
    },
  }),
  { name: 'GalleryImage' }
)
