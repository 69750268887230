import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },

    tabs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      border: 0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: '#cccccc',
      [theme.breakpoints.up('sm')]: {
        // paddingTop: 8,
        // paddingBottom: 13,
      },
      [theme.breakpoints.up('lg')]: {
        borderBottomWidth: 0,
      },
    },

    tab: {
      flex: 1,
      border: 0,
      borderRadius: 0,
      paddingBottom: 6,
      paddingRight: 1,
      paddingLeft: 1,
      paddingTop: 8,
      borderBottomColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: 4,
      height: 62,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:nth-child(1)': {
        [theme.breakpoints.up('sm')]: {
          marginRight: 32,
        },
        [theme.breakpoints.up('lg')]: {
          marginRight: 0,
        },
      },
      [theme.breakpoints.up('sm')]: {
        flex: 'none',
        borderBottomWidth: 4,
        paddingBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        borderBottomWidth: 0,
        justifyContent: 'flex-start',
        paddingTop: 16,
      },
    },

    activeTab: {
      borderBottomColor: theme.palette.beige.main,
      borderStyle: 'solid',
    },

    label: {
      fontFamily: 'ProximaNova-SemiBold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.22,
      fontSize: 18,
      letterSpacing: 1,
      textAlign: 'center',
      color: theme.palette.common.black,
      opacity: 0.4,
      textTransform: 'none',
      [theme.breakpoints.up('sm')]: {
        lineHeight: 1.4,
        fontSize: 20,
      },
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.42,
        fontSize: 24,
      },
    },

    activeLabel: {
      opacity: 1,
    },

    content: {
      paddingTop: 25,
      paddingBottom: 75,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 62,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 47,
        paddingBottom: 100,
      },
    },

    rightTabWidth: {
      maxWidth: 'calc(100% - 438px)',
      width: '100%',
    },

    leftTabWidth: {
      maxWidth: '438px',
      width: '100%',
    },

    rightTabContent: {
      display: 'flex',
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        flex: 'none',
        display: 'block',
      },
    },

    leftTabContent: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        '& > div:first-child': {
          paddingRight: 45,
        },
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'column',
      },
    },

    copy: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: 147,
      },

      '& p, & ol': {
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 'initial',
        margin: theme.spacing(0, 0, 3, 0),

        [theme.breakpoints.up('lg')]: {
          fontSize: 14,
          lineHeight: 1.43,
          margin: theme.spacing(0, 0, 5, 0),
        },
      },

      '& p': {
        margin: theme.spacing(0, 0, 3, 0),

        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(0, 0, 5, 0),
        },
      },

      '& ol': {
        paddingLeft: theme.spacing(3),

        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(2),
        },
      },

      '& strong': {
        fontFamily: 'ProximaNova-Semibold',
        fontWeight: 'initial',
      },
    },

    paragraph: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      paddingBottom: 24,
      color: theme.palette.common.black,
      '& > span': {
        display: 'block',
        fontFamily: 'ProximaNova-Semibold',
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 20,
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    orderedList: {
      paddingLeft: 12,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
      },
    },

    pb: {
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 41,
      },
    },
  }),
  { name: 'ContactForm' }
)
