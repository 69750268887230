import { makeStyles, styled } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({}))

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      padding: '0',
      [theme.breakpoints.up('sm')]: {
        padding: '0',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 20,
      },
    },

    quickLinksWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 15,
    },

    quickParagraph: {
      color: '#868686',
      textTransform: 'uppercase',
      fontSize: 12,
      marginTop: 15,
      letterSpacing: 0,
    },

    linksWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 5,
    },

    link: {
      cursor: 'pointer',
      color: '#000',
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: 0,
      marginRight: 10,

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    accordion: {
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      border: 0,
      borderBottomWidth: '2px',
      borderColor: '#cccccc',
      borderStyle: 'solid',
    },

    accordionExpanded: {
      margin: '0 !important',
    },

    accordionRounded: {
      borderRadius: '0 !important',
    },

    accordionSummary: {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 9,
        paddingBottom: 9,
        minHeight: 52,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingBottom: 1,
      },

      '& .MuiIconButton-edgeEnd': {
        margin: '0 !important',
        padding: '0 !important',
      },
    },

    accordionContent: {
      '&$expanded': {
        margin: theme.spacing(1.5, 0),
      },
    },

    expanded: {},

    twoColumnCheckboxWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '10px',
    },

    icon: {
      fill: theme.palette.common.black,
      width: 30,
      height: 30,
      margin: '0 !important',
      padding: '0 !important',
      transform: 'rotate(-180deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),

      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },

      [theme.breakpoints.up('lg')]: {
        width: 32,
        height: 32,
      },
    },

    iconInfo: {
      marginLeft: 6,
      width: 14,
      height: 14,
    },

    title: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
      },
    },

    accordionDetails: {
      padding: '0 0 31px 0',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      rowGap: 10,
    },

    popover: {
      width: 194,
      padding: '7px 10px',
    },

    details: {
      fontSize: '10px',
      lineHeight: '14px',
      letterSpacing: 0,
      color: '#868686',
    },

    btnWrap: {
      width: '100%',
      backgroundColor: '#fff',
      padding: '10px 0 47px 0',
      [theme.breakpoints.down('xs')]: {
        padding: '30px 35px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '28px 0 28px 0',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '16px 0 28px 0',
      },
    },

    btn: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 303,
      },
    },
  }),
  { name: 'DocFinderFilters' }
)
