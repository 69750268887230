import axios from 'axios'

import { DocSearchQuery, DocSearchResult } from '../@types/DocSearch'
import { ContactFormQuery, ContactFormResult } from '../@types/ContactForm'
import { PageData } from '../@types/Page'
import { PageSettings } from '../@types/PageSettings'

const docFinderClient = axios.create({
  baseURL: 'https://arzt-suche.merz-aesthetics.info',
  // baseURL: 'https://dev-arzt-suche.nostack.de',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function getAstralURL(path: string): string {
  return `${process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://cms.merz-aesthetics.info'}${path}`
}

// Helper to make GET requests to Astral API
export async function fetchAPI(path: string, options: any = {}): Promise<any> {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  }
  const requestUrl = getAstralURL(path)
  const response = await fetch(requestUrl, mergedOptions)

  if (!response.ok) {
    console.error(response.statusText)
    throw new Error(`An error occured please try again`)
  }

  const data = await response.json()

  return data
}

/**
 *
 * @param {string} slug The router slug
 */
export async function getPageData(slug: Array<string>): Promise<PageData> {
  const result = await fetchAPI(`/${slug.join('/')}`)
  const pagesData: PageData = result.data
  return pagesData
}

// Get site data from Astral (metadata, navbar, footer...)
export async function getPageSettings(): Promise<PageSettings> {
  const global: PageSettings = await fetchAPI(`/?settings=true`)
  return global
}

// Get doc search data from Astral
export async function docSearch(query: DocSearchQuery): Promise<DocSearchResult> {
  let queryURL = query.zipCode ? `/?docsearch=${query.zipCode}` : '/?docsearch'
  if (query.radius) {
    queryURL += `&distance=${query.radius}`
  }

  if (query.products && query.products.length > 0) {
    queryURL += `&products=${query.products.join('%2C')}`
  }
  if (query.treatments && query.treatments.length > 0) {
    queryURL += `&treatments=${query.treatments.join('%2C')}`
  }
  if (query.areas && query.areas.length > 0) {
    queryURL += `&areas=${query.areas.join('%2C')}`
  }
  if (query.category && query.category.length > 0) {
    queryURL += `&category=${query.category.join('%2C')}`
  }
  if (query.features && query.features.length > 0) {
    queryURL += `&features=${query.features.join('%2C')}`
  }
  if (query.specialty && query.specialty.length > 0) {
    queryURL += `&specialty=${query.specialty.join('%2C')}`
  }

  const { data } = await docFinderClient.get(queryURL)

  return data
}

// Send Contact Email
export async function sendMail(query: ContactFormQuery): Promise<ContactFormResult> {
  const queryURL = `/?contact=true`
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  }

  return (await fetchAPI(queryURL, options)) as ContactFormResult
}

// Get website routes
export const getRoutes = async (): Promise<{ routes: string[] }> => {
  const routes: { routes: string[] } = await fetchAPI('/?routes=true')

  return routes
}
