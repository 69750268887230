import Asvg from '../../../public/images/typography-outline/A.svg'
import Bsvg from '../../../public/images/typography-outline/B.svg'
import Csvg from '../../../public/images/typography-outline/C.svg'
import Dsvg from '../../../public/images/typography-outline/D.svg'
import Esvg from '../../../public/images/typography-outline/E.svg'
import Fsvg from '../../../public/images/typography-outline/F.svg'
import Gsvg from '../../../public/images/typography-outline/G.svg'
import Hsvg from '../../../public/images/typography-outline/H.svg'
import Isvg from '../../../public/images/typography-outline/I.svg'
import Jsvg from '../../../public/images/typography-outline/J.svg'
import Ksvg from '../../../public/images/typography-outline/K.svg'
import Lsvg from '../../../public/images/typography-outline/L.svg'
import Msvg from '../../../public/images/typography-outline/M.svg'
import Nsvg from '../../../public/images/typography-outline/N.svg'
import Osvg from '../../../public/images/typography-outline/O.svg'
import Psvg from '../../../public/images/typography-outline/P.svg'
import Qsvg from '../../../public/images/typography-outline/Q.svg'
import Rsvg from '../../../public/images/typography-outline/R.svg'
import Ssvg from '../../../public/images/typography-outline/S.svg'
import Tsvg from '../../../public/images/typography-outline/T.svg'
import Usvg from '../../../public/images/typography-outline/U.svg'
import Vsvg from '../../../public/images/typography-outline/V.svg'
import Wsvg from '../../../public/images/typography-outline/W.svg'
import Xsvg from '../../../public/images/typography-outline/X.svg'
import Ysvg from '../../../public/images/typography-outline/Y.svg'
import Zsvg from '../../../public/images/typography-outline/Z.svg'

const icons: Record<string, any> = {
  a: Asvg,
  b: Bsvg,
  c: Csvg,
  d: Dsvg,
  e: Esvg,
  f: Fsvg,
  g: Gsvg,
  h: Hsvg,
  i: Isvg,
  j: Jsvg,
  k: Ksvg,
  l: Lsvg,
  m: Msvg,
  n: Nsvg,
  o: Osvg,
  p: Psvg,
  q: Qsvg,
  r: Rsvg,
  s: Ssvg,
  t: Tsvg,
  u: Usvg,
  v: Vsvg,
  w: Wsvg,
  x: Xsvg,
  y: Ysvg,
  z: Zsvg,
}

export default icons
