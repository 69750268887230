import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './ImageSectionLandscape.styles'
import { TextImageFields } from '../../../@types/Section/TextImage'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

type Props = {
  data: TextImageFields
  invert?: boolean
}

const TextImageLandscape: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()
  return (
    <div className={clsx(data.label ? classes.rootHasLabel : classes.root, { [classes.invert]: invert })}>
      {data.label && (
        <Typography className={clsx(classes.label, { [classes.invert]: invert })}>{data.label}</Typography>
      )}
      <Grid container className={classes.container}>
        <Grid className={classes.leftRow} item xs={12} lg={4}>
          <Typography className={clsx(classes.caption, { [classes.invert]: invert })}>{data.caption_title}</Typography>
          <Typography className={clsx(classes.description, { [classes.invert]: invert })}>
            {data.caption_copy}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <ResponsiveImage className={data.label ? classes.image : classes.imageNoLabel} image={data.image} />
        </Grid>
      </Grid>
    </div>
  )
}

export default TextImageLandscape
