import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      justifyContent: 'center',
      alignItems: 'center',
      objectFit: 'cover',
      display: 'flex',
      '&.is-hidden': {
        display: 'none',
      },
    },

    consentText: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.1,
      letterSpacing: 1,
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        fontSize: '100%',
        lineHeight: 1.41,
      },
    },

    content: {
      width: '80%',
      zIndex: 1,
    },

    previewImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    consentButton: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0.5,
      textAlign: 'center',
      color: theme.palette.common.black,
      textTransform: 'none',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: theme.palette.common.white,
      borderRadius: 0,
      marginRight: 12,
      marginLeft: 12,
      boxShadow: 'none !important',
      '&:hover': {
        backgroundColor: theme.palette.beige.main,
        borderColor: theme.palette.beige.main,
      },
    },
    pt: {
      paddingTop: 20,
      [theme.breakpoints.up('lg')]: {
        paddingTop: 40,
      },
    },
    buttonsGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),
  { name: 'VideoConsentOverlay' }
)
