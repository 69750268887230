import React from 'react'

import { Typography, Button } from '@material-ui/core'
import clsx from 'clsx'

import { ImageField } from '../../../@types/Fields'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import useStyles from './VideoConsentOverlay.styles'

type Props = {
  previewImage?: ImageField
  disclaimer?: string
  buttonText?: string
}

const VideoConsentOverlay: React.FC<Props> = ({
  previewImage,
  disclaimer = 'Um dich zum YouTube Video weiterleiten zu dürfen, benötigen wir deine Zustimmung.',
  buttonText = 'Cookie-Einstellungen',
}) => {
  const styles = useStyles()

  const win: any =
    typeof window !== 'undefined'
      ? window
      : { OneTrust: { InsertHtml: () => {}, OnConsentChanged: () => {}, ToggleInfoDisplay: () => {} } }

  const toggleConsentBanner = () => {
    win.OneTrust.ToggleInfoDisplay()
  }

  return (
    <div className={styles.overlay}>
      {previewImage && (
        <div className={styles.previewImage}>
          <ResponsiveImage image={previewImage} />
        </div>
      )}

      <div className={styles.content}>
        {disclaimer && <Typography className={styles.consentText}>{disclaimer}</Typography>}

        <div className={clsx(styles.buttonsGroup, styles.pt)}>
          <Button
            onClick={() => toggleConsentBanner()}
            variant="contained"
            className={styles.consentButton}
            disableRipple={true}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VideoConsentOverlay
