import React from 'react'
import { Typography, useMediaQuery, useTheme, Container } from '@material-ui/core'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { SectionWithCopyBlockFields } from '../../../@types/Section/SectionWithCopyBlock'

import HomeStage from '../HomeStage'

import BaseButton from '../BaseButton'

import useStyles from './SliderWithCopyBlock.styles'

type Props = {
  data: SectionWithCopyBlockFields
}

const SliderWithCopyBlock: React.FC<Props> = ({ data }) => {
  const classes = useStyles({
    color: data?.color_overwrite?.color,
    invertModuleColor: data?.color_overwrite?.invert_module_color,
    textColor: data?.color_overwrite?.text_color,
  })

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const button = data?.copy_block?.button

  const content = (
    <div className={classes.root}>
      <div className={classes.slider}>
        <HomeStage
          embedded
          data={{
            autoplay: false,
            h1: '',
            slides: data.slides,
          }}
        />
      </div>

      <div className={classes.descriptionWrap}>
        <Typography component="h2" className={classes.title}>
          {data.copy_block.headline}
        </Typography>

        <Typography component="p" className={classes.description}>
          <span dangerouslySetInnerHTML={{ __html: data.copy_block.copy || '' }}></span>
        </Typography>

        <div className={classes.btnWrap}>
          <Link href={button?.anchor ? `#${button?.anchor}` : button?.target}>
            <BaseButton
              href={button?.anchor ? `#${button?.anchor}` : button?.target}
              label="Vorher-Nachher Bilder entdecken"
              target={button.type === 'external' ? '_blank' : undefined}
              classNames={classes.btn}
              disableRipple={true}
              fullWidth
            />
          </Link>
        </div>
      </div>
    </div>
  )

  if (isDesktop) return <Container maxWidth="lg">{content}</Container>

  return content
}

export default dynamic(() => Promise.resolve(SliderWithCopyBlock), { ssr: false })
