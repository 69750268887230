type InputValue = {
  doctor_name: string
  outboundTarget: string
  type: 'url' | 'phone' | 'email' | 'address' | 'book_appointment' | string
  clickHandler?: any
}

const useMatomoDocSearchOutboundLinkClick = (): {
  trackDocSearchOutboundLinkClick: (input: InputValue) => (event: any) => void
} => {
  const trackDocSearchOutboundLinkClick = (input: InputValue) => (event: React.MouseEvent<React.ReactElement>) => {
    if (window) {
      window._mtm = window._mtm || []
      window._mtm.push({
        event: 'outbound_link_click',
        category: 'link_click_doc_surgery',
        action: `${input.doctor_name} | ${input.outboundTarget}`,
        label: input.type,
      })
    }

    input.clickHandler(event)
  }

  return { trackDocSearchOutboundLinkClick }
}

export default useMatomoDocSearchOutboundLinkClick
