import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: 40,

      [theme.breakpoints.up('sm')]: {
        rowGap: 60,
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: 38.8,
      },
    },

    loadMoreWrap: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: 40,

      [theme.breakpoints.up('sm')]: {
        marginTop: 60,
      },
    },

    button: {
      display: 'flex',
      margin: 'auto',
      borderRadius: 0,
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingRight: '20px',
      paddingLeft: '20px',
      textTransform: 'initial',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },

    buttonLabel: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      textAlign: 'center',
      color: theme.palette.common.black,
    },
  }),
  { name: 'BlogGrid' }
)
