import React, { useState, useEffect } from 'react'

import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, SlideRenderProps, SlideRendererCallback } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'
import clsx from 'clsx'

import { ImageSliderSlide } from '../../../@types/Section/ImageSlider'

import IconArrowLeft from '../../../public/icons/icon-left-arrow.svg'
import IconArrowRight from '../../../public/icons/icon-arrow.svg'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import SlideControlls from '../SlideControlls'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

import ImageModal from './ImageModal'
import ImageSliderDesktop from './ImageSliderDesktop'

import useStyles from './ImageSlider.styles'

type Props = {
  data: ImageSliderSlide[]
  invert?: boolean
}

const ImageSlider: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const [activeStep, setActiveStep] = useState<number>(0)
  const [autoplay, setAutoplay] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const slidesCount = data.length

  const slidesMaxIndex = slidesCount - 1

  const activeSlideIndex = mod(activeStep, slidesCount)

  const activeSlideData = data[activeSlideIndex]

  useEffect(() => {
    let interval: any

    if (autoplay) {
      interval = setInterval(() => {
        nextSlide()
      }, 5000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [autoplay, activeStep, slidesMaxIndex])

  const nextSlide = () => {
    setActiveStep(activeStep + 1)
  }

  const handleIndexChange = (index: number) => {
    setAutoplay(false)

    setActiveStep(index)
  }

  const handleImageClick = (index?: number) => () => {
    setAutoplay(false)

    setOpenModal(true)

    if (index !== undefined) {
      setActiveStep(index)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleNext = () => {
    setAutoplay(false)
    setActiveStep(activeStep + 1)
  }

  const handlePrev = () => {
    setAutoplay(false)
    setActiveStep(activeStep - 1)
  }

  const slideRenderer: SlideRendererCallback = ({ key, index }: SlideRenderProps): React.ReactNode => {
    const slideIndex = mod(index, slidesCount)

    const slide = data[slideIndex]

    const portraitImage = slide.format === 'portrait' && slide.image_p
    const landscapeImage = slide.format === 'landscape' && slide.image_l

    const image = portraitImage || landscapeImage

    return (
      <React.Fragment key={key}>
        <div className={classes.slideContentWrap}>
          <div className={classes.slideImage} onClick={handleImageClick()}>
            {image ? (
              <ResponsiveImage image={image} />
            ) : (
              <div className={classes.imageFallback}>
                <Typography
                  className={clsx(classes.fallbackText, { [classes.invert]: invert })}
                  dangerouslySetInnerHTML={{ __html: slide?.caption?.copy || '' }}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      {isDesktop && <ImageSliderDesktop data={data} onImageClick={handleImageClick} />}
      {!isDesktop && (
        <>
          <div className={classes.root}>
            <div className={clsx(classes.sliderControlWrap, classes.sliderControlWrapLeft)}>
              <div className={classes.sliderControlIcon} onClick={handleNext}>
                <IconArrowLeft />
              </div>
            </div>
            <VirtualizeSwipeableViews
              index={activeStep}
              slideRenderer={slideRenderer}
              slideClassName={classes.slide}
              slideStyle={{ overflow: 'hidden', height: '100%', width: '100%' }}
              onChangeIndex={handleIndexChange}
              enableMouseEvents
              className={classes.slides}
              overscanSlideAfter={1}
              overscanSlideBefore={1}
            />
            <div className={clsx(classes.sliderControlWrap, classes.sliderControlWrapRight)}>
              <div className={classes.sliderControlIcon} onClick={handlePrev}>
                <IconArrowRight />
              </div>
            </div>
          </div>

          <div className={classes.controlls}>
            <SlideControlls slidesCount={slidesCount} activeSlideIndex={activeSlideIndex} />
          </div>
        </>
      )}

      <ImageModal
        data={activeSlideData}
        open={openModal}
        onClose={handleCloseModal}
        onNextImage={handleNext}
        onPrevImage={handlePrev}
      />
    </>
  )
}

export default ImageSlider
