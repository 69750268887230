import React, { useEffect, useState } from 'react'

import { Typography, useTheme, Container, useMediaQuery } from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views'
import { virtualize, SlideRenderProps, SlideRendererCallback } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import SlideControlls from '../SlideControlls'

import clsx from 'clsx'

import { Slider } from '../../../@types/Slider'

import LinkButton from '../LinkButton'

import useStyles from './HomeStage.styles'
import stripHtml from '../../helpers/stripHtml'

type Props = {
  data: Slider
  blur?: boolean
  embedded?: boolean
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const HomeStage: React.FC<Props> = ({ data, blur = false, embedded = false }) => {
  const classes = useStyles()

  const theme = useTheme()

  const [activeStep, setActiveStep] = useState<number>(0)

  const [autoplay, setAutoplay] = useState<boolean>(data?.autoplay || false)

  const slides = data?.slides || []

  const slidesCount = slides.length

  const slidesMaxIndex = slidesCount - 1

  useEffect(() => {
    let interval: any

    if (autoplay) {
      interval = setInterval(() => {
        nextSlide()
      }, 5000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [autoplay, activeStep, slidesMaxIndex])

  const nextSlide = () => {
    setActiveStep(activeStep + 1)
  }

  const prevSlide = () => {
    setActiveStep(activeStep - 1)
  }

  const handleNext = () => {
    setAutoplay(false)

    nextSlide()
  }

  const handleBack = () => {
    setAutoplay(false)

    prevSlide()
  }

  const handleIndexChange = (index: number) => {
    setActiveStep(index)
  }

  const activeSlideIndex = mod(activeStep, slidesCount)

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const slideRenderer: SlideRendererCallback = ({ key, index }: SlideRenderProps): React.ReactNode => {
    const slide = slides[mod(index, slidesCount)]
    const mediaType = slide?.type

    const bgImage = slide?.image
    const bgImageMobile = slide?.image_mobile

    const bgImageSrc = bgImage && bgImage?.url
    const videoSrc = slide?.video && slide.video

    const image = bgImageSrc && (
      <ResponsiveImage
        className={classes.slideImage}
        image={isDesktop ? bgImage : bgImageMobile}
        pClassName=""
        desktopImageCovered={slide.desktop_image_covered}
      />
    )

    const videoFileName = (videoSrc || '').split('/').pop() || ''

    const [videoName] = videoFileName.split('.')

    // const matomoTitle = slide?.title ? stripHtml(slide?.title) : videoName
    const matomoTitle = videoName

    const video = videoSrc && (
      <video
        src={videoSrc}
        poster={bgImage?.url}
        data-matomo-title={matomoTitle}
        className={classes.slideVideo}
        muted
        autoPlay
        loop
        webkit-playsinline="true"
        playsInline
      ></video>
    )

    const linkLabel = slide?.button?.label
    let linkTarget =
      slide?.button?.target ||
      slide?.button?.target_internal ||
      slide?.button?.target_external ||
      slide?.button?.target_email ||
      slide?.button?.target_download ||
      slide?.button?.target_anchor
    const linkType = slide?.button?.link_type || slide?.button?.type
    if (linkType === 'external') {
      linkTarget = slide?.button?.target_external
    }

    return (
      <React.Fragment key={key}>
        <div className={clsx(classes.bgWrap, { embedded })}>
          {mediaType === 'image' && image}
          {mediaType === 'video' && video}
        </div>
        <div className={classes.contentWrap}>
          <div className={classes.content}>
            <Typography component="h2" variant="h1" className={classes.slideTitle}>
              <span dangerouslySetInnerHTML={{ __html: slide?.title || '' }}></span>
            </Typography>

            {slide?.subtitle && (
              <Typography variant="body1" className={classes.slideDescription}>
                {slide.subtitle}
              </Typography>
            )}

            {slide?.has_button && (
              <LinkButton label={linkLabel} target={linkTarget} linkType={linkType} trackingOptions={{ isCta: true }} />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div
      id={data.anchor_id || undefined}
      style={{ backgroundColor: '#121212' }}
      className={clsx(classes.bg, { [classes.blurContent]: blur })}
    >
      {data?.h1 && <h1 className={classes.h1} dangerouslySetInnerHTML={{ __html: data.h1 }}></h1>}
      <div id="home_stage_content" className={clsx(classes.root, { embedded })}>
        <VirtualizeSwipeableViews
          index={activeStep}
          className={classes.slider}
          slideClassName={classes.slide}
          slideStyle={{ overflow: 'hidden', height: '100%', width: '100%' }}
          slideRenderer={slideRenderer}
          onChangeIndex={handleIndexChange}
          enableMouseEvents
        />
        <Container maxWidth="lg" className={clsx(classes.sliderControlsContainer, { embedded })}>
          <SlideControlls
            onBack={handleBack}
            onNext={handleNext}
            slidesCount={slidesCount}
            activeSlideIndex={activeSlideIndex}
          />
        </Container>
      </div>
    </div>
  )
}

export default HomeStage
