import React from 'react'
import { Button } from '@material-ui/core'
import clsx from 'clsx'

import IconRightArrow from '../../../../../public/icons/icon-right-arrow.svg'
import IconLeftArrow from '../../../../../public/icons/icon-left-arrow.svg'

import BaseDropdown from '../../../BaseDropdown'

import useStyles from './Controls.styles'
import { ComparsionItem } from '../../../../../@types/Section/Comparsion'
import { ColorOverwrite } from '../../../../../@types/Fields'

type Props = {
  comparsions: ComparsionItem[]
  current: number
  total: number
  setCurrent: (index: number) => void
  onNext: () => void
  onBack: () => void
  colorOverwrite?: ColorOverwrite
}

const Controls: React.FC<Props> = ({ setCurrent, current, total, comparsions, onNext, onBack, colorOverwrite }) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <BaseDropdown setCurrent={setCurrent} current={current} options={comparsions} colorOverwrite={colorOverwrite} />

      <div className={styles.buttonsGroup}>
        <Button className={clsx(styles.button, { active: current === total - 1, colorOverwrite })} onClick={onBack}>
          <IconLeftArrow className={clsx(styles.arrowIcon, { colorOverwrite })} />
        </Button>
        <Button className={clsx(styles.button, { active: current === 0, colorOverwrite })} onClick={onNext}>
          <IconRightArrow className={clsx(styles.arrowIcon, { colorOverwrite })} />
        </Button>
      </div>
    </div>
  )
}

export default Controls
