import React, { useRef, useEffect } from 'react'
import { Typography, useTheme, useMediaQuery, Container } from '@material-ui/core'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import { TabFilterFilter } from '../../../@types/Page'

import useStyles from './TabFilter.styles'
import { ColorOverwrite } from '../../../@types/Fields'

type Props = {
  items: TabFilterFilter[]
  selected: number
  onClick: (title: string, id?: string) => void
  colorOverwrite?: ColorOverwrite
  asNav?: boolean
}

const TabFilter: React.FC<Props> = ({ items = [], onClick, selected = 0, colorOverwrite, asNav }) => {
  const activeRef = useRef<HTMLDivElement | null>(null)
  const indicatorRef = useRef<HTMLDivElement | null>(null)
  const tabFilterRef = useRef<HTMLDivElement | null>(null)
  const classes = useStyles({ color: colorOverwrite?.color })

  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const indicateActiveElement = () => {
    const element = activeRef.current
    const indicatorElement = indicatorRef.current
    const parentElement = activeRef.current?.parentElement

    if (element && indicatorElement) {
      indicatorElement.style.width = `${element?.offsetWidth}px`

      if (asNav) {
        const translateLeft = isDesktop ? element?.offsetLeft - (parentElement?.offsetLeft || 0) : element?.offsetLeft

        return (indicatorElement.style.transform = `translate(${translateLeft}px, 0px)`)
      }

      indicatorElement.style.transform = `translate(${element?.offsetLeft}px, 0px)`
    }
  }

  const observer = (() => {
    if (typeof window !== 'undefined' && typeof MutationObserver !== 'undefined') {
      return new MutationObserver((mutations, mut) => {
        const targetElement = mutations[0].target as HTMLElement

        const isMenuVisible = targetElement?.classList?.contains?.('menuVisible')

        if (isMenuVisible) {
          tabFilterRef.current?.classList?.add('push')
        } else {
          tabFilterRef.current?.classList?.remove('push')
        }
      })
    }

    return {
      observe: () => {},
      disconnect: () => {},
    }
  })()

  useEffect(() => {
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
    })
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    indicateActiveElement()

    if (activeRef.current && !asNav) activeRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
  }, [activeRef, indicatorRef, selected])

  useEffect(() => {
    setTimeout(() => {
      indicateActiveElement()
    }, 250)
  })

  const handleClick = (title: string, id: string) => () => {
    if (asNav) {
      return onClick(title, id)
    }
    onClick(title)
  }

  const itemFixedSize = (items.length < 10 && isDesktop) || (items.length < 4 && isSmallDesktop)

  const itemWidth = itemFixedSize ? `${100 / items.length}%` : 'auto'

  const content = (
    <>
      {items.map((item, index) => (
        <div
          ref={index === selected ? activeRef : undefined}
          key={`filter_${item.title}`}
          onClick={handleClick(item.title, item.id)}
          className={classes.item}
          data-id={encodeURIComponent(item.id)}
          style={{ width: itemWidth }}
        >
          <Typography className={clsx(classes.itemLabel, { selected: index === selected })}>{item.title}</Typography>
        </div>
      ))}
      <div ref={indicatorRef} className={classes.activeIndicator} />
    </>
  )

  if (asNav) {
    return (
      <div ref={tabFilterRef} className={clsx(classes.root)} id="alle">
        <div id="tab_filter_content" className={clsx(classes.content, { [classes.asNav]: asNav })}>
          {content}
        </div>
      </div>
    )
  }

  return (
    <div ref={tabFilterRef} className={clsx(classes.root)} id="alle">
      <div className={clsx(classes.content)}>{content}</div>
    </div>
  )
}

export default dynamic(() => Promise.resolve(TabFilter), { ssr: false })
