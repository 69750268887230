import { makeStyles } from '@material-ui/core'

const sliderHeightXS = 540
const sliderHeightSM = 640
const sliderHeightLG = 760

export default makeStyles(
  (theme) => ({
    root: {
      overflow: 'hidden',
      width: '100%',
      height: sliderHeightXS,
      marginBottom: 60,
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
        marginBottom: 80,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
        marginBottom: 100,
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },

    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 304,
      position: 'absolute',
      height: sliderHeightXS,
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
        width: 455,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 50,
        height: sliderHeightLG,
        width: 619,
      },
    },

    title: {
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '42px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '64px',
        letterSpacing: 4,
      },

      '& strong': {
        color: 'rgba(0, 0, 0, 0)',
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    titleSecondary: {
      fontSize: 36,
      lineHeight: '42px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '50px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '80px',
        letterSpacing: 4,
      },
    },

    descriptionWrap: {
      margin: '20px 0 0 0',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        margin: '24px 0 12px 0',
        width: 455,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '30px 0 38px 0',
        width: 619,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    scrollDownWrap: {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      justifyContent: 'center',
    },

    readMore: {
      width: 30,
      height: 53,
      cursor: 'pointer',
      position: 'relative',
      top: -39,
      '&:hover path': {
        stroke: theme.palette.beige.main,
      },

      [theme.breakpoints.up('sm')]: {
        top: -41,
        width: 32,
        height: 56,
      },

      [theme.breakpoints.up('lg')]: {
        top: -53,
        width: 40,
        height: 80,
      },

      '&$invert': {
        stroke: theme.palette.common.white,

        '& path': {
          stroke: theme.palette.common.white,
        },

        '&:hover': {
          stroke: theme.palette.beige.main,

          '& path': {
            stroke: theme.palette.beige.main,
          },
        },
      },
    },

    contentWrap: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& video': {
        height: sliderHeightXS,
        [theme.breakpoints.up('sm')]: {
          height: sliderHeightSM,
        },
        [theme.breakpoints.up('md')]: {
          width: '100%',
          height: '100%',
        },
      },
    },

    img: {
      height: sliderHeightXS,
      display: 'block',
      overflow: 'hidden',
      margin: '0 auto',
      objectFit: 'cover',
      objectPosition: 'center',
      imageRendering: '-webkit-optimize-contrast',
      [theme.breakpoints.up('sm')]: {
        height: sliderHeightSM,
      },
      [theme.breakpoints.up('lg')]: {
        height: sliderHeightLG,
      },
      // maxHeight: 440,

      // [theme.breakpoints.up('sm')]: {
      //   maxHeight: 340,
      // },

      // [theme.breakpoints.up('lg')]: {
      //   maxHeight: 580,
      // },
    },

    invert: {},
  }),
  { name: 'CampaignStage' }
)
