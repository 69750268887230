import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      minHeight: 558,
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    overlayArea: {
      position: 'absolute',
      top: 0,
      left: 0,
      // display: 'none',
    },
    hotSpot: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      '&:hover': {
        '& > g': {
          fill: theme.palette.common.black,
        },
        '& circle': {
          stroke: theme.palette.common.black,
        },
      },
    },
    area: {
      '& object > document': {
        display: 'none',
      },
    },
  }),
  { name: 'HotspotImage' }
)
