import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      '&$topMargin': {
        marginTop: 65,
        [theme.breakpoints.up('sm')]: {
          marginTop: 71,
        },

        [theme.breakpoints.up('lg')]: {
          marginTop: 136,
        },
      },
    },
    topMargin: {},
  }),
  { name: 'Content' }
)
