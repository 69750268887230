import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useStyles from './HeaderInfo.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../../../../../hooks/useMatomoLinkTracking'

const HeaderDescription: React.FC = () => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  return (
    <div className={classes.descriptionWrap}>
      <Typography component="p" className={classes.description}>
        Hier findest du deine speziell geschulten Expert:innen für die Behandlung mit unseren hochwertigen Merz
        Aesthetics<sup>®</sup> Produkten.
      </Typography>
    </div>
  )
}

export default HeaderDescription
