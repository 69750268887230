import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    content: {
      maxWidth: 728,
      '& h1,  h2': {
        fontFamily: 'ProximaNova-SemiBold',
        fontSize: 20,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 1,
        color: theme.palette.common.black,
        marginTop: 0,
        marginBottom: 25,
        [theme.breakpoints.up('sm')]: {
          fontSize: 24,
          lineHeight: 1.25,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 28,
          lineHeight: 1.29,
          marginBottom: 33,
        },
      },

      '&$invert h1, &$invert h2': {
        color: theme.palette.common.white,
      },

      '& h3': {
        fontFamily: 'ProximaNova-SemiBold',
        fontSize: 18,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.44,
        letterSpacing: 1,
        color: theme.palette.common.black,
        marginTop: 0,
        marginBottom: 15,
        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          lineHeight: 1.4,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 24,
          lineHeight: 1.42,
          marginBottom: 23,
        },
      },

      '& $invert h3': {
        color: theme.palette.common.white,
      },

      '& h4': {
        fontFamily: 'ProximaNova-SemiBold',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: theme.palette.common.black,
        marginTop: 0,
        marginBottom: 9,
        [theme.breakpoints.up('lg')]: {
          fontSize: 18,
          lineHeight: 1.44,
          marginBottom: 17,
        },
      },

      '& $invert h4': {
        color: theme.palette.common.white,
      },

      '&.no-top-margin': {
        '& p': {
          marginTop: '0 !important',
        },
      },

      '& p': {
        fontFamily: 'ProximaNova-Regular',
        fontSize: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: theme.palette.common.black,
        minHeight: 24,
        marginBottom: 32,
        marginTop: 32,
        [theme.breakpoints.up('lg')]: {
          fontSize: 18,
          lineHeight: 1.67,
          minHeight: 30,
          marginBottom: 40,
          marginTop: 40,
        },
        '& b': {
          fontFamily: 'ProximaNova-SemiBold',
          fontWeight: 'bold',
        },
        '& i': {
          fontFamily: 'ProximaNova-RegularIt',
          fontWeight: 300,
        },
        '& sup, sub': {
          fontFamily: 'inherit',
          fontSize: 'smaller',
          fontWeight: 'inherit',
          fontStretch: 'inherit',
          fontStyle: 'inherit',
          lineHeight: '1',
          letterSpacing: 'inherit',
          color: 'inherit',
        },
        '& a': {
          fontFamily: 'inherit !important',
          fontSize: 'inherit !important',
          fontWeight: 'inherit !important',
          fontStretch: 'inherit !important',
          fontStyle: 'inherit !important',
          lineHeight: 'inherit !important',
          letterSpacing: 'inherit !important',
          color: 'inherit !important',
          textDecoration: 'none !important',
          borderBottomColor: theme.palette.common.black,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          paddingRight: '0 !important',
          '&::after': {
            display: 'none !important',
          },
          '&[target="_blank"]:not(.primary-btn):not(.secondary-btn)': {
            ontFamily: 'inherit !important',
            fontSize: 'inherit !important',
            fontWeight: 'inherit !important',
            fontStretch: 'inherit !important',
            fontStyle: 'inherit !important',
            lineHeight: 'inherit !important',
            letterSpacing: 'inherit !important',
            color: 'inherit !important',
            textDecoration: 'none !important',
            display: 'inline-block',
            paddingRight: '40px !important',
            position: 'relative',
            overflow: 'hidden',
            verticalAlign: 'top',
            borderBottomColor: theme.palette.common.black,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            '&::after': {
              display: 'inline-block !important',
              content: 'url("/icons/icon-external-link-black.svg") !important',
              width: '32px !important',
              height: '32px !important',
              position: 'absolute',
              right: 0,
              top: '0px !important',
            },
          },

          '&.primary-btn, &.secondary-btn': {
            cursor: 'pointer !important',
            position: 'relative !important',
            border: '1px solid #000 !important',
            marginRight: '8px !important',
            width: 'auto !important',
            minWidth: '140px !important',
            height: '42px !important',
            padding: '4px 20px !important',
            borderRadius: '100px !important',
            boxSizing: 'border-box !important',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n                border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
            fontFamily: 'ProximaNova-Regular,Open Sans,Gill Sans MT,Gill Sans,Corbel,Arial,sans-serif !important',
            fontSize: '14px !important',
            fontStyle: 'normal !important',
            fontWeight: 'normal !important',
            lineHeight: '18px !important',
            fontStretch: 'normal !important',
            letterSpacing: '.5px !important',
            textTransform: 'none !important',
            textDecoration: 'none !important',
            display: 'inline-flex !important',
            justifyContent: 'center !important',
            alignItems: 'center !important',
            userSelect: 'none !important',
          },

          '&.primary-btn': {
            borderColor: '#000 !important',
            backgroundColor: '#000 !important',
            color: '#fff !important',
            '&:hover': {
              backgroundColor: `${theme.palette.beige.main} !important`,
              borderColor: `${theme.palette.beige.main} !important`,
              color: `${theme.palette.common.black} !important`,
            },
          },
          '&.secondary-btn': {
            borderColor: '#000 !important',
            backgroundColor: '#fff !important',
            color: '#000 !important',
            '&:hover': {
              backgroundColor: `${theme.palette.beige.main} !important`,
              borderColor: `${theme.palette.beige.main} !important`,
              color: `${theme.palette.common.black} !important`,
            },
          },
        },

        '& span': {
          '&.footnote': {
            fontFamily: 'ProximaNova-Regular, Arial, sans-serif !important',
            color: '#868686 !important',
            fontSize: '14px !important',
            fontWeight: 'normal !important',
            fontStretch: 'normal !important',
            fontStyle: 'normal !important',
            lineHeight: '1.43 !important',
            letterSpacing: 'normal !important',
          },
        },
      },

      '& ul': {
        paddingLeft: 30,
        '& li': {
          paddingLeft: 8,
          fontFamily: 'ProximaNova-Regular',
          fontSize: 16,
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.5,
          letterSpacing: 0.5,
          color: theme.palette.common.black,
          marginBottom: 20,
          '& b': {
            fontFamily: 'ProximaNova-SemiBold',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            lineHeight: 1.67,
            marginBottom: 30,
          },
          '& a': {
            fontFamily: 'inherit !important',
            fontSize: 'inherit !important',
            fontWeight: 'inherit !important',
            fontStretch: 'inherit !important',
            fontStyle: 'inherit !important',
            lineHeight: 'inherit !important',
            letterSpacing: 'inherit !important',
            color: 'inherit !important',
            textDecoration: 'none !important',
            borderBottomColor: theme.palette.common.black,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            paddingRight: '0 !important',
            '&::after': {
              display: 'none !important',
            },
          },
        },
      },

      '& sup': {
        '& a': {
          paddingRight: 0,
          '&::after': {
            display: 'none !important',
          },
        },
      },

      '& ol': {
        paddingLeft: 30,
        '& li': {
          paddingLeft: 8,
          fontFamily: 'ProximaNova-Regular',
          fontSize: 16,
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.5,
          letterSpacing: 0.5,
          color: theme.palette.common.black,
          marginBottom: 20,
          '& b': {
            fontWeight: 'bold',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            lineHeight: 1.67,
            marginBottom: 30,
          },
        },
      },

      '& a': {
        display: 'inline',
        verticalAlign: 'middle',
        fontFamily: 'ProximaNova-Regular',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.29,
        letterSpacing: 0.5,
        color: theme.palette.common.black,
        textDecoration: 'none',
        position: 'relative',
        paddingRight: 28,
        '&::after': {
          content: 'url("/icons/icon-arrow-white.svg")',
          width: 20,
          height: 20,
          position: 'absolute',
          right: 0,
          top: -2,
        },

        '&[target="_blank"]:not(.primary-btn):not(.secondary-btn)': {
          ontFamily: 'inherit !important',
          fontSize: 'inherit !important',
          fontWeight: 'inherit !important',
          fontStretch: 'inherit !important',
          fontStyle: 'inherit !important',
          lineHeight: 'inherit !important',
          letterSpacing: 'inherit !important',
          color: 'inherit !important',
          textDecoration: 'none !important',
          display: 'inline-block',
          paddingRight: '40px !important',
          position: 'relative',
          overflow: 'hidden',
          verticalAlign: 'top',
          borderBottomColor: theme.palette.common.black,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          '&::after': {
            display: 'inline-block !important',
            content: 'url("/icons/icon-external-link-black.svg") !important',
            width: '40px !important',
            height: '40px !important',
            position: 'absolute',
            right: 0,
            top: '-7px !important',
          },
        },
      },

      '& small': {
        display: 'inline-block',
        fontFamily: 'ProximaNova-Regular',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: theme.palette.common.black,
        marginBottom: 8,
        marginTop: 8,
        '& sup, sub': {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontStretch: 'inherit',
          fontStyle: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          color: 'inherit',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 14,
          lineHeight: 1.43,
        },
      },

      '&$invert p': {
        color: theme.palette.common.white,

        '& a': {
          color: theme.palette.common.white,
          borderBottomColor: theme.palette.common.white,
          '&[target="_blank"]': {
            borderBottomColor: theme.palette.common.white,
          },
        },

        '&.primary-btn, &.secondary-btn': {
          border: '1px solid #fff !important',
          borderRadius: 100,
        },

        '&.primary-btn': {
          borderColor: '#fff !important',
          backgroundColor: '#fff !important',
          color: '#000 !important',
          borderRadius: 100,
          '&:hover': {
            backgroundColor: `${theme.palette.common.black} !important`,
            borderColor: `${theme.palette.common.black} !important`,
            color: `${theme.palette.beige.main} !important`,
          },
        },

        '&.secondary-btn': {
          borderColor: '#fff !important',
          backgroundColor: '#000 !important',
          color: '#fff !important',
          '&:hover': {
            backgroundColor: `${theme.palette.common.black} !important`,
            borderColor: `${theme.palette.common.black} !important`,
            color: `${theme.palette.beige.main} !important`,
          },
        },

        '& span': {
          '&.footnote': {
            color: `${theme.palette.beige.main} !important`,
          },
        },

        '& ul': {
          '& li': {
            color: theme.palette.common.white,
            '& a': {
              borderBottomColor: theme.palette.common.white,
            },
          },
        },
        '& ol': {
          '& li': {
            color: theme.palette.common.white,
          },
        },
        '& small': {
          color: theme.palette.common.white,
        },
      },
    },
    invert: {},
  }),
  { name: 'EditorText' }
)
