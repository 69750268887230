import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      '& $item + $item': {
        marginTop: 16,

        [theme.breakpoints.up('sm')]: {
          marginTop: 15,
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: 24,
        },
      },
    },

    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '9px 0 25px 0',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0px 0 24px 0',
      },
    },

    image: {
      position: 'relative',
      width: 129,
      height: 171,
      marginLeft: 0,
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: 162,
        height: 215,
      },
      [theme.breakpoints.up('lg')]: {
        width: 182.3,
        height: 243,
      },

      '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        position: 'absolute',
      },
    },

    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 0 23px 0',
      [theme.breakpoints.up('sm')]: {
        padding: '0 0 0 15px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 0 0 36px',
      },
    },

    title: {
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: 1.2,
      fontWeight: 600,
      marginBottom: 18,

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: '34px',
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      marginBottom: 23,
      '& ul': {
        margin: 0,
        padding: '0 0 0 26px',
      },
      '& ul li': {
        paddingLeft: 10,
      },
      '& ul li::marker': {
        fontSize: 12,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    actions: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'unset',
        columnGap: 0,
      },
    },

    button: {
      paddingBottom: 4,
      paddingTop: 4,
      paddingLeft: 4,
      paddingRight: 4,
      alignSelf: 'flex-start',
      borderRadius: 100,
      width: '100%',
      height: 42,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 0.5,
      textTransform: 'none',
      [theme.breakpoints.up('sm')]: {
        minWidth: 140,
        width: 'auto',
      },
      '&:hover': {
        backgroundColor: `${theme.palette.beige.main} !important`,
        borderColor: `${theme.palette.beige.main} !important`,
        color: theme.palette.common.black,
      },
    },

    downloadButton: {
      marginLeft: theme.spacing(1),
      borderColor: theme.palette.common.black,
      color: theme.palette.common.black,
    },

    detailsButton: {
      marginRight: theme.spacing(1),
      borderColor: theme.palette.common.black,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },

    downloadIcon: {
      width: 32,
      height: 32,
      margin: 0,
    },
  }),
  { name: 'Download' }
)
