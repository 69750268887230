import * as yup from 'yup'

const schema = yup.object().shape({
  firstName: yup.string().required('Bitte Pflichtfeld ausfüllen.'),
  lastName: yup.string().required('Bitte Pflichtfeld ausfüllen.'),
  email: yup.string().email('Bitte gültige E-Mail-Adresse eingeben.').required('Bitte Pflichtfeld ausfüllen.'),
  subject: yup.string().required('Bitte Pflichtfeld ausfüllen.'),
  message: yup.string().required('Bitte Pflichtfeld ausfüllen.'),
})

export default schema
