import React from 'react'
import { Typography, Link } from '@material-ui/core'
import clsx from 'clsx'
import NextLink from 'next/link'

import { ArticleTarget } from '../../../@types/Fields'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import useStyles from './BlogTile.styles'

import IconArrow from '../../../public/icons/icon-arrow.svg'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data: ArticleTarget
  related?: boolean
  classes?: {
    root?: string
  }
  invert?: boolean
  color?: string
}

const BlogTile: React.FC<Props> = ({ data, classes, related, invert }) => {
  const styles = useStyles({ color: data?.color_overwrite?.color })

  if (!data) return null

  const [trackedLinkClick] = useMatomoLinkTracking()

  const image = data?.image

  const linkLabel = 'Mehr erfahren'

  return (
    <div className={clsx(styles.root, classes?.root, { [styles.invert]: invert })}>
      <NextLink href={data.target} passHref>
        <a
          onClick={trackedLinkClick({
            label: data.label,
            href: data.target,
            position: MotomoLinkTrackingPosition.BODY,
          })}
        >
          <ResponsiveImage className={clsx(styles.image, { [styles.related]: related })} image={image} />
        </a>
      </NextLink>

      <div className={styles.content}>
        <div>
          <div className={styles.row}>
            <Typography className={clsx(styles.category, { [styles.invert]: invert })}>{data.category}</Typography>
            {data.date && <div className={clsx(styles.dash, { [styles.invert]: invert })} />}
            {data.date && (
              <Typography className={clsx(styles.date, { [styles.invert]: invert })}>{data.date}</Typography>
            )}
          </div>
          <Typography component="h3" className={clsx(styles.label, { [styles.labelInverted]: invert })}>
            {data.label}
          </Typography>
        </div>

        <NextLink href={data.target} passHref>
          <Link
            className={clsx(styles.link, { [styles.linkInverted]: invert })}
            onClick={trackedLinkClick({
              label: linkLabel,
              href: data.target,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <Typography className={clsx(styles.linkLabel, { [styles.linkLabelInverted]: invert })}>
              {linkLabel}
            </Typography>
            <IconArrow className={clsx(styles.arrowIcon, { [styles.arrowIconInverted]: invert })} />
          </Link>
        </NextLink>
      </div>
    </div>
  )
}

export default BlogTile
