import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'inline-block',
      width: '100%',
      position: 'relative',
      marginTop: 15,
      marginBottom: 20,

      [theme.breakpoints.up('sm')]: {
        marginTop: 25,
        marginBottom: 30,
      },

      [theme.breakpoints.up('md')]: {
        marginTop: 34,
        marginBottom: 40,
      },
    },
    wrapper: {
      display: 'grid',
      gridTemplateAreas: `
        "image2 image1"
        "image2 image3"
        "image4 image4"
        "image5 image6"
        "image7 image7"
      `,
      gridTemplateColumns: '41.621% 1fr',

      '& > div:last-child': {
        marginBottom: 0,
      },

      '& #image_1': {
        gridArea: 'image1',
        maxWidth: 728,
        height: 485,
        marginLeft: 'auto',
      },

      '& #image_2': {
        gridArea: 'image2',
        maxWidth: 400,
        height: 533,
        marginTop: 355,
      },

      '& #image_3': {
        gridArea: 'image3',
        maxWidth: 510,
        height: 341,
        marginTop: 70,
        justifySelf: 'end',
      },

      '& #image_4': {
        gridArea: 'image4',
        maxWidth: 728,
        height: 485,
        justifySelf: 'center',
      },

      '& #image_5': {
        gridArea: 'image5',
        maxWidth: 400,
        height: 533,
      },

      '& #image_6': {
        gridArea: 'image6',
        maxWidth: 510,
        height: 681,
        justifySelf: 'end',
        marginTop: 170,
      },

      '& #image_7': {
        gridArea: 'image7',
        maxWidth: 838,
        height: 471,
        justifySelf: 'center',
      },
    },

    column: {},

    columnLeft: {},

    columnRight: {},

    invert: {},

    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    mobileOnly: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  }),
  { name: 'ImageGallery' }
)
