import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import BaseButton from '../../../../../../BaseButton'
import Checkbox from '../../../DocFinderFilters/components/Checkbox'

import useStyles from './Consent.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../../../../../../hooks/useMatomoLinkTracking'

type Props = {
  handleCookieConsent: (withCookie: boolean) => void
}

const Consent: React.FC<Props> = ({ handleCookieConsent }) => {
  const [checked, setChecked] = React.useState(false)

  const [trackedLinkClick] = useMatomoLinkTracking()

  const classes = useStyles()
  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <Typography component="p" className={classes.description}>
          Mit dem Laden der Karte akzeptieren Sie die Datenschutzerklärung von Google.
        </Typography>
        <Link
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          onClick={trackedLinkClick({
            label: 'Mehr erfahren',
            href: 'https://policies.google.com/privacy?hl=de',
            position: MotomoLinkTrackingPosition.BODY,
          })}
        >
          Mehr erfahren
        </Link>
        <div className={classes.btnWrap}>
          <BaseButton classNames={classes.btn} label="Karte laden" onClick={() => handleCookieConsent(checked)} />
        </div>
        <div className={classes.checkboxWrap}>
          <Checkbox onChange={() => setChecked((prev) => !prev)} checked={checked} value={'abc'}>
            Google Maps immer entsperren
          </Checkbox>
        </div>
      </div>
    </div>
  )
}

export default Consent
