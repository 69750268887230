import React, { useState, useEffect } from 'react'

import { CircularProgress, Typography } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, SlideRenderProps, SlideRendererCallback } from 'react-swipeable-views-utils'
import { mod } from 'react-swipeable-views-core'
import clsx from 'clsx'

import { ImageGalleryFields } from '../../../../../@types/Section/ImageGallery'

import ResponsiveImage from '../../../ResponsiveImage/ResponsiveImage'
import SlideControlls from '../../../SlideControlls'

import useStyles from './GalleryMobile.style'

type Props = {
  data: ImageGalleryFields
  invert?: boolean
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const GalleryMobile: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [autoplay, setAutoplay] = useState<boolean>(true)

  const slides = [data.image_1, data.image_2, data.image_3, data.image_4, data.image_5, data.image_6, data.image_7]

  const slidesCount = slides.length

  const slidesMaxIndex = slidesCount - 1

  const activeSlideIndex = mod(activeStep, slidesCount)

  useEffect(() => {
    let interval: any

    if (autoplay) {
      interval = setInterval(() => {
        nextSlide()
      }, 5000)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [autoplay, activeStep, slidesMaxIndex])

  const nextSlide = () => {
    setActiveStep(activeStep + 1)
  }

  const handleIndexChange = (index: number) => {
    setAutoplay(false)

    setActiveStep(index)
  }

  const slideRenderer: SlideRendererCallback = ({ key, index }: SlideRenderProps): React.ReactNode => {
    const slide = slides[mod(index, slidesCount)]

    return (
      <React.Fragment key={key}>
        <div className={classes.slideCaption}>
          <div className={classes.slideContentWrap}>
            <Typography className={clsx(classes.captionTitle, { [classes.invert]: invert })}>
              {slide?.caption_title || ''}
            </Typography>
            <div className={classes.copyWrap}>
              <Typography
                className={clsx(classes.captionCopy, { [classes.invert]: invert })}
                dangerouslySetInnerHTML={{ __html: slide?.caption_copy || '' }}
              />
            </div>
          </div>
        </div>
        <div className={classes.slideContentWrap}>
          <div className={classes.slideImage}>
            {slide.image_mobile ? (
              <ResponsiveImage image={slide.image_mobile} />
            ) : (
              <div className={classes.imageFallback}>
                <Typography
                  className={clsx(classes.fallbackText, { [classes.invert]: invert })}
                  dangerouslySetInnerHTML={{ __html: slide?.caption_copy || '' }}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <>
      <div className={classes.separator} />
      <VirtualizeSwipeableViews
        index={activeStep}
        slideRenderer={slideRenderer}
        slideClassName={classes.slide}
        slideStyle={{ overflow: 'hidden', height: '100%', width: '100%' }}
        onChangeIndex={handleIndexChange}
        enableMouseEvents
        className={classes.slides}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
      />
      <div className={classes.controlls}>
        <SlideControlls slidesCount={slidesCount} activeSlideIndex={activeSlideIndex} />
      </div>
    </>
  )
}

export default GalleryMobile
