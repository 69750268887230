import React from 'react'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'

import { ImageSliderFields } from '../../../../../@types/Section/ImageSlider'

import ImageSlider from '../../../ImageSlider'
import Module from '../../../Module'

type Props = {
  data: ImageSliderFields
  invert?: boolean
}

const ImageSliderModule: React.FC<Props> = ({ data, invert }) => {
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  if (isDesktop) {
    return (
      <Container maxWidth="lg" style={{ overflow: 'hidden' }}>
        <Module id={data.anchor_id} label={data.label} title={data.headline} fullWidthChildren>
          <ImageSlider data={data.slider} invert={invert} />
        </Module>
      </Container>
    )
  }

  return (
    <Module id={data.anchor_id} label={data.label} title={data.headline} fullWidthChildren containerForHeading>
      <ImageSlider data={data.slider} invert={invert} />
    </Module>
  )
}

export default ImageSliderModule
