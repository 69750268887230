import React from 'react'

import { DocSearchResultItem } from '../../../../../../../../@types/DocSearch'
import IconLocationWhite from '/public/icons/icon-location-white.svg'
import IconLocationBlack from '/public/icons/icon-location-black.svg'

import useStyles from './Pin.styles'
import clsx from 'clsx'

type Props = {
  location: Partial<DocSearchResultItem>
  onHoverPin: (slug: string) => void
  onClickPin: (slug: string) => void
  clickedPinSlug: string
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  hoveredPinSlug: string
  center?: number[]
  lat: any
  lng: any
}

const Pin: React.FC<Props> = ({
  location,
  onHoverPin,
  clickedPinSlug,
  onClickPin,
  setIsDrawerOpen,
  hoveredPinSlug,
}) => {
  const classes = useStyles()

  const isHovered = Boolean(hoveredPinSlug.toString() === location?.slug)
  const isClicked = Boolean(clickedPinSlug.toString() === location?.slug)

  const _onMouseEnter = () => onHoverPin(location?.slug || '')

  const _onMouseLeave = () => onHoverPin('')

  const _onClick = () => {
    onClickPin(location?.slug || '')
    setIsDrawerOpen(true)
  }

  const pinStyles = isHovered || isClicked ? classes.largePininContainer : classes.pinContainer

  return (
    <div
      className={clsx(classes.container, pinStyles)}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      onClick={() => {
        _onClick()
      }}
    >
      {isHovered || isClicked ? (
        <IconLocationWhite width={40} height={40} />
      ) : (
        <IconLocationBlack width={28} height={28} />
      )}
    </div>
  )
}

export default Pin
