import Link from 'next/link'

import useStyles from './LinkButton.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  label?: string
  target?: string
  linkType?: string
  trackingOptions?: {
    isCta?: boolean
  }
}

const LinkButton: React.FC<Props> = ({
  label,
  target = '#',
  linkType = 'internal',
  trackingOptions = { isCta: false },
}) => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  return (
    <Link href={target} passHref>
      <a
        className={classes.root}
        target={linkType === 'external' ? '_blank' : undefined}
        onClick={trackedLinkClick({
          label: label || '',
          href: target,
          position: MotomoLinkTrackingPosition.BODY,
          isCta: trackingOptions?.isCta,
        })}
      >
        {label}
      </a>
    </Link>
  )
}

export default LinkButton
