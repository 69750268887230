import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
}

const getBackgroundColor = (props: Props, theme: Theme) =>
  props?.color ? theme.palette.common.white : theme.palette.beige.main

const getTextColor = (props: Props, theme: Theme) => (props?.color ? props.color : theme.palette.common.black)

export default makeStyles<Theme, Props>(
  (theme) => ({
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'transparent',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      width: '100%',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 1,
      color: theme.palette.common.black,
      marginBottom: 12,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        marginBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.42,
      },
    },

    rotatedArrowIcon: {
      transform: 'rotate(0deg) !important',
    },

    arrowIcon: {
      transform: 'rotate(-180deg)',
      width: 32,
      zIndex: 4,
      height: 32,
      transition: 'transform 0.2s linear',
    },

    paper: {
      zIndex: 3,
      width: 'calc(100% - 72px)',
      left: 36,
      marginTop: 51,
      border: 0,
      boxShadow: 'none !important',
      borderRadius: 0,
      borderColor: '#868686',
      borderTopWidth: 1,
      borderStyle: 'solid',
      '& ul': {
        paddingTop: 0,
        paddingBottom: 0,
        '& li': {
          marginTop: -1,
          border: 0,
          borderColor: '#868686',
          borderWidth: 1,
          borderStyle: 'solid',
          fontFamily: 'ProximaNova-Regular',
          fontSize: 14,
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.43,
          letterSpacing: 'normal',
          color: '#868686',
          paddingTop: 13,
          paddingBottom: 13,
          '&:hover': {
            backgroundColor: (props) => getBackgroundColor(props, theme),
            color: (props) => getTextColor(props, theme),
          },
          '&[aria-selected="true"]': {
            backgroundColor: theme.palette.beige.main,
            color: theme.palette.common.black,
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: 243,
        marginTop: 45,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 57,
      },
    },
  }),
  { name: 'BaseDropdown' }
)
