import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
  invertModuleColor?: boolean
}

const shouldInvertColors = (props: Props) => props?.invertModuleColor && props?.color

const getBorderColor = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) {
    return theme.palette.common.black
  }

  return props?.color ? props.color : theme.palette.common.black
}

const getButtonBackground = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) return theme.palette.common.black

  if (props?.color) return props?.color

  return undefined
}

const getButtonTextColor = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) return theme.palette.common.white

  return theme.palette.common.black
}

const getSVGStrokeColor = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) return theme.palette.common.white
  if (props?.color) return props?.color

  return theme.palette.common.black
}

const getInvertedHoverStyles = (props: Props, theme: Theme): any => {
  if (props?.invertModuleColor) {
    return {
      backgroundColor: `${theme.palette.beige.main} !important`,
      borderColor: `${theme.palette.common.black} !important`,
      color: `${theme.palette.common.black} !important`,
    }
  }

  return {
    backgroundColor: `${theme.palette.beige.main} !important`,
    borderColor: `${theme.palette.beige.main} !important`,
    color: `${theme.palette.common.black} !important`,
  }
}

export default makeStyles<Theme, Props>(
  (theme) => ({
    container: {
      backgroundColor: (props) => (shouldInvertColors(props) ? props?.color : undefined),
      marginTop: 60,
      marginBottom: 60,
      maxWidth: 1440,
      paddingTop: (props) => (props?.invertModuleColor ? 60 : undefined),
      paddingBottom: (props) => (props?.invertModuleColor ? 60 : undefined),
      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
        paddingTop: (props) => (props?.invertModuleColor ? 80 : undefined),
        paddingBottom: (props) => (props?.invertModuleColor ? 80 : undefined),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: (props) => (props?.invertModuleColor ? 100 : undefined),
        paddingBottom: (props) => (props?.invertModuleColor ? 100 : undefined),
      },

      '&.condense': {
        margin: 0,
      },
    },

    headline: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: 1,
      color: 'black',
      wordBreak: 'break-word',
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        fontSize: 34,
        marginBottom: theme.spacing(2),
      },
    },

    content: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flex: 1,
      minHeight: 200,
      [theme.breakpoints.up('sm')]: {
        minHeight: 245,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 320,
      },
    },

    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '70%',
      position: 'relative',
      left: '30.33%',
      marginTop: 40,
      [theme.breakpoints.up('sm')]: {
        left: '19.33%',
        width: '80%',
      },
      [theme.breakpoints.up('lg')]: {
        left: '34.33%',
        width: '58%',
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '18px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.44,
      letterSpacing: '1px',
      color: theme.palette.common.black,
      paddingBottom: 36,
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: 1.4,
        paddingBottom: 30,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
        lineHeight: 1.5,
      },
    },
    descriptionInverted: {
      color: theme.palette.common.white,
    },

    letter: {
      width: '100%',
      maxWidth: 225,
      height: 200,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 280,
        height: 245,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 217,
        maxWidth: 363,
        height: 320,
      },
    },

    svg: {
      width: 225,
      height: 200,
      [theme.breakpoints.up('sm')]: {
        width: 280,
        height: 245,
      },
      [theme.breakpoints.up('lg')]: {
        width: 363,
        height: 320,
      },

      '& path': {
        stroke: (props) => getSVGStrokeColor(props, theme),
      },
    },
    svgInverted: {
      '& path': {
        stroke: theme.palette.common.white,
      },
    },

    button: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 20,
      paddingRight: 20,
      alignSelf: 'flex-start',
      borderRadius: 100,
      minWidth: 158,
      borderColor: (props) => getBorderColor(props, theme),
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      color: (props) => getButtonTextColor(props, theme),
      textTransform: 'none',
      backgroundColor: (props) => getButtonBackground(props, theme),
      '&:hover': (props) => ({
        ...(getInvertedHoverStyles(props, theme) || {}),
      }),
    },
  }),
  { name: 'TextHighlight' }
)
