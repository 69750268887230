import { Button, Typography } from '@material-ui/core'
import React from 'react'

import Link from 'next/link'

import clsx from 'clsx'

import dynamic from 'next/dynamic'

import stripHtml from '../../helpers/stripHtml'

import { TextHighlightFields } from '../../../@types/Section/TextHighlight'

import Module from '../Module'

import Icon from '../Icon'

import useStyles from './TextHighlight.styles'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data: TextHighlightFields
  invert?: boolean
}

const TextHighlight: React.FC<Props> = ({ data, invert }) => {
  const styles = useStyles({
    color: data?.color_overwrite?.color,
    invertModuleColor: data?.color_overwrite?.invert_module_color,
  })

  const [trackedLinkClick] = useMatomoLinkTracking()

  const copy = stripHtml(data?.copy || '')

  const letter = copy?.slice(0, 1).toLowerCase()

  const content = (
    <div className={clsx(styles.container, { condense: Boolean(data?.sidelabel) })}>
      <div className={styles.content}>
        <div className={styles.letter}>
          <Icon name={letter} className={clsx(styles.svg, { [styles.svgInverted]: invert })} />
        </div>
        <div className={styles.wrapper}>
          {data?.headline && (
            <Typography className={styles.headline} component="h3">
              {data.headline}
            </Typography>
          )}
          <div
            className={clsx(styles.description, { [styles.descriptionInverted]: invert })}
            dangerouslySetInnerHTML={{ __html: data?.copy }}
          />
          {data?.button && (
            <Link href={data.button.target} passHref>
              <Button
                variant="outlined"
                className={styles.button}
                href={data.button.target}
                target={data.button.type === 'external' ? '_blank' : undefined}
                onClick={trackedLinkClick({
                  label: data?.button.label,
                  href: data?.button.target || '',
                  position: MotomoLinkTrackingPosition.BODY,
                  isCta: true,
                })}
              >
                {data?.button?.label}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )

  if (data?.sidelabel) {
    return (
      <Module label={data?.sidelabel} fullWidthChildren transparentBG>
        {content}
      </Module>
    )
  }

  return content
}

export default dynamic(() => Promise.resolve(TextHighlight), { ssr: false })
