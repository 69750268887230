import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        height: 740,
        maxWidth: 837,
      },
    },

    headerContainer: {
      padding: '0 36px 0 36px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 98px 0 98px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '20px 32px 0 32px',
      },
    },

    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
      padding: '0 36px 0 36px',
      [theme.breakpoints.up('sm')]: {
        padding: '4px 98px 0 98px',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: '1px 0 0 0',
      },
    },

    titleSection: {
      marginBottom: 20,
    },

    label: {
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0,
      color: '#868686',
      fontStyle: 'italic',
      fontWeight: 300,
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },

    title: {
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 1.3,
      color: '#000',
      fontWeight: 600,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '30px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 28,
        lineHeight: '36px',
      },
    },

    imageSection: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #ccc',
      [theme.breakpoints.up('md')]: {
        maxWidth: 419,
        width: '100%',
        borderBottom: 'none',
        borderRight: '1px solid #ccc',
      },
    },

    imageBox: {
      borderColor: '#cccccc',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: 'solid',
      overflow: 'hidden',
      height: 'auto',
      [theme.breakpoints.up('md')]: {
        height: 279,
      },
    },

    image: {
      height: 'auto',
      width: '100%',
      backgroundColor: '#cccc',
      marginBottom: 16,
      objectFit: 'contain',
      [theme.breakpoints.up('md')]: {
        height: 279,
        marginBottom: 0,
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.57,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      paddingBottom: 24,
      paddingTop: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: 1.5,
        paddingBottom: 32,
        paddingTop: 24,
      },
      [theme.breakpoints.up('md')]: {
        padding: 32,
      },
    },

    infoSection: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingBottom: 24,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 31,
        paddingRight: 31,
        borderTop: '1px solid #ccc',
        maxWidth: 418,
        width: '50%',
      },
    },

    infoTitle: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.44,
      letterSpacing: 1,
      color: theme.palette.common.black,
      paddingTop: 16,
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
        lineHeight: 1.42,
        paddingBottom: 8,
        borderColor: '#cccccc',
        border: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
      },
    },

    ctaCopy: {
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 8,
        paddingTop: 24,
      },
      '& p': {
        fontFamily: 'ProximaNova-Regular',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        fontSize: 12,
        letterSpacing: 'normal',
        color: theme.palette.gray.dark,
        marginTop: 8,
        marginBottom: 8,
        [theme.breakpoints.up('md')]: {
          lineHeight: 1.43,
          fontSize: 14,
        },
      },
      '& ul': {
        margin: 0,
        marginBottom: 8,
        [theme.breakpoints.up('md')]: {
          marginBottom: 16,
        },
        '& li': {
          fontFamily: 'ProximaNova-Regular',
          fontSize: 12,
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.5,
          letterSpacing: 'normal',
          color: theme.palette.common.black,
          '& b': {
            fontFamily: 'ProximaNova-Semibold',
            fontWeight: 600,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 14,
            lineHeight: 1.43,
          },
        },
      },
    },

    button: {
      backgroundColor: '#121212',
      borderRadius: 100,
      padding: '12px 0 11px 0',
      width: '100%',
      margin: '6px 0 24px 0',
      color: theme.palette.common.white,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      fontWeight: 300,
      textTransform: 'none',

      '&:hover': {
        backgroundColor: `${theme.palette.beige.main} !important`,
        borderColor: `${theme.palette.beige.main} !important`,
        color: theme.palette.common.black,
      },

      [theme.breakpoints.up('sm')]: {
        margin: '6px 0 32px 0',
      },
      [theme.breakpoints.up('md')]: {
        margin: 0,
        padding: '10px 0 11px 0',
      },
    },

    buttonSection: {
      width: '100%',
      height: 80,
      backgroundColor: '#e8e8e8',
      [theme.breakpoints.up('sm')]: {
        height: 52,
      },
      [theme.breakpoints.up('sm')]: {
        height: 60,
      },
    },

    controlContainer: {
      padding: '0 36px 0 36px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 'inherit',
      [theme.breakpoints.up('sm')]: {
        padding: '0 98px 0 98px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 38px 0 40px',
      },
    },

    btnWrapLeft: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: 5,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 16,
      },
    },
    btnWrapRight: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
      rowGap: 5,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 16,
      },
    },

    controlBtnRight: {
      width: 32,
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        opacity: 1,
      },
    },

    controlBtnLeft: {
      width: 32,
      padding: 0,
      display: 'flex',
      justifyContent: 'end',
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        opacity: 1,
      },
    },

    leftArrow: {
      transform: 'rotate(180deg)',
      width: 32,
    },

    rightArrow: {
      width: 32,
    },

    arrowText: {
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0,
      color: '#000',
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },

    controlButton: {
      width: 30,
      height: 30,
      borderRadius: 100,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
      borderWidth: 1,
      transition: theme.transitions.create('opacity'),
      backgroundColor: 'transparent',
      opacity: 1,
      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },
      [theme.breakpoints.up('md')]: {
        width: 40,
        height: 40,
      },

      '&[disabled=""]': {
        opacity: 0.4,
      },

      '&:hover': {
        [theme.breakpoints.up('md')]: {
          backgroundColor: '#E3E0DB',
          borderColor: '#E3E0DB',
        },
      },

      '& svg path': {
        fill: theme.palette.common.black,
      },
    },

    controlLeftButton: {
      transform: 'rotate(180deg)',
    },

    arrow: {
      width: 20,
      height: 20,
      [theme.breakpoints.up('sm')]: {
        width: 22,
        height: 22,
      },
      [theme.breakpoints.up('md')]: {
        width: 30,
        height: 30,
      },
    },
  }),
  { name: 'ApplicationModal' }
)
