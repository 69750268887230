import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      border: 0,
      borderTopWidth: '1px',
      borderColor: '#cccccc',
      borderStyle: 'solid',
      paddingTop: 16,
      backgroundColor: theme.palette.common.white,
      marginTop: 60,
      marginBottom: 60,
      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 24,
        marginTop: 100,
        marginBottom: 100,
      },
      '&.no-label': {
        border: 'none',
      },

      '&$invert': {
        backgroundColor: 'initial',
      },

      '&$transparentBG': {
        backgroundColor: 'initial',
      },
    },

    heading: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: 'normal',
      color: theme.palette.gray.dark,
      textTransform: 'uppercase',
      paddingBottom: 15,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 22,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.beige.main,
      },
    },

    title: {
      fontFamily: 'ProximaNova-Semibold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 20,
      lineHeight: '32px',
      letterSpacing: '1px',
      color: theme.palette.common.black,
      paddingBottom: 24,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '34px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 28,
        lineHeight: 1,
        paddingBottom: 41,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    invert: {},

    transparentBG: {},
  }),
  { name: 'Module' }
)
