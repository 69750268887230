import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    rootHasLabel: {
      borderTopWidth: 1,
      borderTopColor: '#cccccc',
      borderTopStyle: 'solid',
      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.gray.main,
      textTransform: 'uppercase',
      paddingBottom: 24,
      paddingTop: 16,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
        paddingBottom: 40,
        paddingTop: 24,
        position: 'absolute',
      },

      '&$invert': {
        color: theme.palette.beige.main,
      },
    },

    container: {},

    content: {
      marginTop: 28,
      [theme.breakpoints.up('md')]: {
        marginTop: 48,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: -2,
      },
      '& > *:first-child': {
        marginTop: 0,
        paddingTop: 0,
      },
      '&.has-label > *:first-child': {
        marginTop: 12,
        paddingTop: 0,
        [theme.breakpoints.up('xs')]: {
          marginTop: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '22px',
        },
      },
      '& > *:last-child': {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },

    image: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginTop: -6,
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        marginTop: 77,
        marginBottom: 28,
      },
    },

    imageNoLabel: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginTop: 0,
      marginBottom: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 320,
      [theme.breakpoints.up('lg')]: {
        marginLeft: 'initial',
        marginRight: 'initial',
        maxWidth: 'initial',
        marginBottom: 28,
      },
    },

    caption: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,

      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    invert: {},
  }),
  { name: 'TextImage' }
)
