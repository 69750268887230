import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginBottom: 100,
      },
    },
    grid: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "back back"
        "main main"
        "image image"
        "category date"
        "socials socials"
      `,

      [theme.breakpoints.up('sm')]: {
        gridTemplateAreas: `
        "back back back"
        "main main main"
        "image image image"
        "category date socials"
      `,
        gridTemplateColumns: '1fr 1fr 1fr',
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: `
        "back back back image"
        "main main main image"
        "category date socials image"
      `,
        gridTemplateColumns: 'auto auto auto fit-content(510px)',
      },
    },
    back: {
      gridArea: 'back',
      marginBottom: 38,
      marginTop: 17,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 47,
        marginTop: 25,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 47,
        marginTop: 40,
      },
    },

    backBtn: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 7,
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        columnGap: 8,
      },
      '& p': {
        fontSize: 12,
        color: '#838383',
        textTransform: 'uppercase',
        letterSpacing: 0,
        [theme.breakpoints.up('lg')]: {
          fontSize: 14,
        },
      },
    },

    main: {
      gridArea: 'main',
      marginBottom: 47,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 58,
      },
      [theme.breakpoints.up('lg')]: {
        alignItems: 'baseline',
        marginBottom: 32,
      },
    },

    image: {
      gridArea: 'image',
      position: 'relative',
      height: 200,
      [theme.breakpoints.up('sm')]: {
        height: 380,
      },
      [theme.breakpoints.up('lg')]: {
        height: 680,
      },
      '& button': {
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      '& picture, & img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    category: {
      gridArea: 'category',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 6,
      [theme.breakpoints.up('sm')]: {
        borderBottom: '1px solid #E2E1DD',
        paddingTop: 9,
        // width: 218,
      },
      [theme.breakpoints.up('lg')]: {
        borderTop: '1px solid #E2E1DD',
        width: 'unset',
        alignSelf: 'flex-end',
        padding: '8px 15px 7px 0',
      },
    },
    date: {
      gridArea: 'date',
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 0 3px 8px',
      borderLeft: '1px solid #E2E1DD',
      [theme.breakpoints.up('sm')]: {
        borderBottom: '1px solid #E2E1DD',
        padding: '9px 0 7px 15px',
        // minWidth: 219,
      },
      [theme.breakpoints.up('lg')]: {
        borderTop: '1px solid #E2E1DD',
        width: 'unset',
        alignSelf: 'flex-end',
        padding: '8px 2px 7px 36px',
      },
    },
    socials: {
      gridArea: 'socials',
      borderTop: '1px solid #E2E1DD',
      borderBottom: '1px solid #E2E1DD',
      padding: '6px 0 6px 0',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        borderLeft: '1px solid #E2E1DD',
        borderTop: 'none',
        padding: '11px 0 6px 14px',
      },
      [theme.breakpoints.up('lg')]: {
        borderTop: '1px solid #E2E1DD',
        alignSelf: 'flex-end',
        padding: '11px 44px 12px 38px',
      },

      '& a': {
        lineHeight: 1,
        padding: 0,
        margin: 0,
        display: 'inline',
        height: 32,
      },
    },

    title: {
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: theme.palette.common.black,
      textAlign: 'center',
      marginBottom: 19,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '42px',
        marginBottom: 24,
        maxWidth: 564,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '64px',
        letterSpacing: 4,
        textAlign: 'left',
        marginBottom: 30,
        maxWidth: 656,
      },

      '& sup': {
        fontSize: 22,
        position: 'relative',
        top: 5,
        [theme.breakpoints.up('sm')]: {
          fontSize: 26,
        },
        [theme.breakpoints.up('lg')]: {
          top: 0,
          fontSize: '32px !important',
        },
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 564,
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'unset',
        fontSize: 16,
        lineHeight: '24px',
        maxWidth: 510,
      },
    },

    infoText: {
      fontSize: 12,
      lineHeight: '18px',
      color: theme.palette.common.black,
      letterSpacing: 0,
      '&:nth-child(1)': {
        textTransform: 'uppercase',
        [theme.breakpoints.up('lg')]: {
          textTransform: 'initial',
        },
      },
      '& .span': {
        fontStyle: 'italic',
      },
      '& i': {
        fontFamily: 'ProximaNova-LightIt',
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },

    scrollDownWrap: {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      justifyContent: 'center',
    },
  }),
  { name: 'BlogStage' }
)
