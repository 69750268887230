import * as React from 'react'
import { Global } from '@emotion/react'
import { SwipeableDrawer, useMediaQuery } from '@material-ui/core'

import useStyles from './DocFinderDrawer.V2.styles'
import theme from '../../../../../theme'
import HeaderInfo from '../DocFinderHeader/components/HeaderIntro'
import HeaderDescription from '../DocFinderHeader/components/HeaderDescription'
import { useSwipeable } from 'react-swipeable'

type Props = {
  children: React.ReactNode
  clickedPinSlug: string
  container: HTMLDivElement | null
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  shorterDrawer: boolean
}

const SwipeableEdgeDrawer: React.FC<Props> = ({
  children,
  clickedPinSlug,
  container,
  isDrawerOpen,
  setIsDrawerOpen,
  shorterDrawer,
}) => {
  const [mobileDrawerExtended, setMobileDrawerExtended] = React.useState(false)

  const classes = useStyles()

  const showHeaderInfo = !shorterDrawer
  const drawerBleeding = showHeaderInfo ? 85 : 50

  const showsResultDetail = !!clickedPinSlug

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const defaultDrawerSize = 95

  const mobileDrawerSize = isMobile && (mobileDrawerExtended ? 85 : 50)

  const shortDrawerSize = !mobileDrawerExtended && shorterDrawer && 35

  const openedDetail = showsResultDetail && 50

  const drawerSize = openedDetail || shortDrawerSize || mobileDrawerSize || defaultDrawerSize

  const handlers = useSwipeable({
    onSwipedUp: () => setMobileDrawerExtended(true),
    onSwipedDown: () => setMobileDrawerExtended(false),
  })

  return isMobile || (isTablet && showsResultDetail) ? (
    <div className={classes.root}>
      <Global
        styles={{
          body: {
            overflow: !isMobile ? 'auto !important' : 'undefined',
          },
          '.MuiAppBar-root': { zIndex: 10000 },
          '.MuiDrawer-root': { position: 'initial !important' as 'initial' },
          '.MuiDrawer-root > .MuiPaper-root': {
            overflow: 'visible', // To see the pullerWrapper
            height: `calc(${drawerSize}% - ${drawerBleeding}px)`,
            [theme.breakpoints.between('sm', 'md')]: {
              maxWidth: 375,
              right: '10%',
              left: '45%',
            },
            [theme.breakpoints.up('lg')]: {
              display: 'none',
            },
          },
        }}
      />
      <SwipeableDrawer
        disableEnforceFocus
        container={container}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => {
          setIsDrawerOpen(true)
        }}
        swipeAreaWidth={drawerBleeding}
        ModalProps={{
          keepMounted: true,
          BackdropProps: { invisible: true },
        }}
        SwipeAreaProps={{ backdrop: { invisible: false } }}
        disableBackdropTransition={false}
        disableSwipeToOpen={false}
      >
        <div
          {...handlers}
          className={classes.pullerWrapper}
          style={{
            backgroundColor: showsResultDetail ? '#121212' : '#fff',
            top: -drawerBleeding + 1,
            height: drawerBleeding,
          }}
        >
          <div className={classes.puller} />
          {showHeaderInfo && <HeaderInfo hideDescription={true} />}
        </div>
        <div
          className={classes.content}
          style={{
            backgroundColor: showsResultDetail ? '#121212' : '#fff',
          }}
          id="drawer-content"
        >
          {showHeaderInfo && <HeaderDescription />}
          {children}
        </div>
      </SwipeableDrawer>
    </div>
  ) : null
}

export default SwipeableEdgeDrawer
