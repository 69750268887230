import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      maxWidth: 1440,
      width: '100%',
      height: 400,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        height: 460,
      },
      [theme.breakpoints.up('lg')]: {
        height: 560,
      },
    },

    handle: {
      width: 36,
      height: '100%',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
    },

    line: {
      width: 2,
      height: '100%',
      backgroundColor: theme.palette.common.white,
    },

    square: {
      position: 'absolute',
      width: 20,
      height: 20,
      backgroundColor: theme.palette.common.white,
      transform: 'rotate(45deg)',
      [theme.breakpoints.up('sm')]: {
        width: 24,
        height: 24,
      },
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineSeight: 1.5,
      letterSpacing: 'normal',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      position: 'absolute',
      top: 0,
      backgroundColor: theme.palette.common.black,
      width: 80,
      height: 24,
      zIndex: 2,
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineSeight: 1.43,
        width: 112,
        height: 32,
      },
      [theme.breakpoints.up('lg')]: {
        textTransform: 'none',
      },
    },

    leftLabel: {
      left: 0,
    },

    rightLabel: {
      right: 0,
    },
  }),
  { name: 'ComparisonSlider' }
)
