import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    iframewrapper: {
      position: 'relative',
      width: '100%',
      height: 195,
      // paddingBottom: 42,
      // marginBottom: 25,
      backgroundColor: '#f3f3f3',
      zIndex: 100,
      [theme.breakpoints.up('sm')]: {
        height: 420,
        // marginBottom: 30,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 850,
        height: 510,
        // marginBottom: 42,
      },

      '& iframe': {
        position: 'absolute',
        display: 'none',
        width: '100%',
        height: '100%',
        border: 'none',
        '&[src]': {
          display: 'block',
          zIndex: 10,
        },
      },
    },

    buttonsGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    player: {
      display: 'none',
      paddingBottom: 42,
      width: '100%',
      height: 195,
      marginBottom: 25,
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        height: 420,
        marginBottom: 30,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 837,
        height: 510,
        marginBottom: 42,
      },
    },

    pt: {
      paddingTop: 20,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 30,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 40,
      },
    },

    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      justifyContent: 'center',
      alignItems: 'center',
      objectFit: 'cover',
      display: 'flex',
      '&.is-hidden': {
        display: 'none',
      },
    },

    consentText: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 18,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.1,
      letterSpacing: 1,
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: 1.41,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 34,
        lineHeight: 1.41,
      },
    },

    content: {
      width: '80%',
      zIndex: 1,
    },

    previewImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    consentButton: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0.5,
      textAlign: 'center',
      color: theme.palette.common.black,
      textTransform: 'none',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: theme.palette.common.white,
      borderRadius: 0,
      marginRight: 12,
      marginLeft: 12,
      boxShadow: 'none !important',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 20,
        paddingRight: 20,
      },
      '&:hover': {
        backgroundColor: theme.palette.beige.main,
        borderColor: theme.palette.beige.main,
      },
    },

    image: {
      position: 'relative',
      width: '100%',
      height: 196,
      display: 'block',
      marginTop: -6,
      marginBottom: 10,
      zIndex: 100,
      [theme.breakpoints.up('sm')]: {
        height: 419,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 28,
        marginBottom: 0,
        height: 510,
      },
      '& iframe': {
        position: 'absolute',
        display: 'none',
        width: '100%',
        height: '100%',
        border: 'none',
        '&[src]': {
          display: 'block',
          zIndex: 10,
        },
      },
      '& video': {
        objectFit: 'cover',
      },
    },

    imageNoLabel: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginTop: 0,
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },

    caption: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,

      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    invert: {},
  }),
  { name: 'VideoPlayer' }
)
