import { Button } from '@material-ui/core'
import React from 'react'

import useStyles from './CloseButton.styles'

import IconClose from '../../../../../public/icons/icon-modal-close.svg'

type Props = {
  onClick: () => void
}

const CloseButton: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button onClick={onClick} className={classes.button}>
      <IconClose width={40} height={40} className={classes.icon} />
    </Button>
  )
}

export default CloseButton
