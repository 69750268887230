import React from 'react'
import { Modal, Fade } from '@material-ui/core'

import CloseButton from './components/CloseButton'

import useStyles from './BaseModal.styles'

type Props = {
  open: boolean
  onClose: () => void
  children: React.ReactElement
}

const BaseModal: React.FC<Props> = ({ open, onClose, children }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={onClose}
      className={classes.content}
      BackdropProps={{
        timeout: 500,
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Fade in={open}>
        <>
          <CloseButton onClick={onClose} />
          {children}
        </>
      </Fade>
    </Modal>
  )
}
export default BaseModal
