import { makeStyles } from '@material-ui/core'

// const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      margin: '-15px 0 -25px 0',
      '& .MuiSlider-root': {
        top: -5,
      },
      '& .MuiSlider-rail': {
        backgroundColor: '#e8e8e8',
        height: 4,
      },
      '& .MuiSlider-thumb': {
        height: 13,
        width: 13,
        backgroundColor: '#121212',
        borderRadius: 0,
        '&:focus, &:hover, &.Mui-active': {
          boxShadow: '0 0px 0px rgba(0,0,0,0.1),0 0px 0px rgba(0,0,0,0.3),0 0 0 0px rgba(0,0,0,0.02)',
          // Reset on touch devices, it doesn't add specificity
        },
      },

      '& .MuiSlider-valueLabel': {
        fontSize: 10,
        fontWeight: 'normal',
        top: 16,
        left: -5,
        backgroundColor: 'unset',

        color: theme.palette.text.primary,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: theme.palette.type === 'dark' ? '#fff' : '#121212',
          width: 45,
        },
      },
      '& .MuiSlider-track': {
        border: 'none',
        height: 4,
        backgroundColor: '#121212',
      },
    },

    captionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    captionsText: {
      fontSize: 10,
    },
  }),
  { name: 'DistanceSlider' }
)
