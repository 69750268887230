import { useEffect, useState } from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

import HotspotImage, { Props } from './HotspotImage'

const HotspotImageComponent: React.FC<Props> = ({ variant, config, onHotspotClick, bgColor }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    setVisible(true)
  }, [])

  if (!visible) return <></>

  return isDesktop ? (
    <HotspotImage bgColor={bgColor} config={config} variant={variant} onHotspotClick={onHotspotClick} />
  ) : (
    <HotspotImage bgColor={bgColor} config={config} variant={variant} onHotspotClick={onHotspotClick} mobile />
  )
}

export default HotspotImageComponent
