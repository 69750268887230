import React, { useRef } from 'react'

import { Typography, useMediaQuery, useTheme } from '@material-ui/core'

import clsx from 'clsx'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import { CampaignStageFields } from '../../../@types/Section/CampaignStage'

import useStyles from './CampaignStage.styles'

type Props = {
  data: CampaignStageFields
  onScrollToContent?: () => void
  invert?: boolean
}

const CampaignStage: React.FC<Props> = ({ data, invert }) => {
  const { page_title, page_subtitle, video, image } = data

  const theme = useTheme()

  const container = useRef<HTMLDivElement | null>(null)

  const classes = useStyles()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const mainImage = isDesktop ? data?.image : data?.image_mobile

  const videoFileName = (video || '').split('/').pop() || ''

  const [videoName] = videoFileName.split('.')

  return (
    <div id={data?.anchor_id || ''} className={classes.root} ref={container}>
      <div className={classes.content}>
        <div className={classes.contentWrap}>
          {video ? (
            <video
              src={video}
              poster={image.url}
              muted
              autoPlay
              loop
              webkit-playsinline="true"
              data-matomo-title={videoName}
              playsInline
            ></video>
          ) : (
            mainImage && <ResponsiveImage image={mainImage} className={classes.img} />
          )}

          <div className={classes.titleWrap}>
            <Typography
              variant="h1"
              className={clsx(classes.title, { [classes.invert]: invert })}
              dangerouslySetInnerHTML={{ __html: page_title }}
            />

            <div className={classes.descriptionWrap}>
              <Typography component="p" className={clsx(classes.description, { [classes.invert]: invert })}>
                {page_subtitle}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignStage
