import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(50% - 7.5px)`,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(50% - 7.5px)`,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(33% - 18.9px)`,
    },
  },

  thumbnail: {
    width: '100%',
    height: 165,
    backgroundColor: '#c3c3c3',
    '& div:first-child': {
      maxHeight: 165,
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 188,
      '& div:first-child': {
        maxHeight: 188,
      },
    },
    [theme.breakpoints.up('lg')]: {
      height: 222,
      '& div:first-child': {
        maxHeight: 222,
        marginTop: 0,
      },
    },
  },

  infoWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // maxWidth: 272,
    marginTop: 8,
    rowGap: 15,
    [theme.breakpoints.up('md')]: {
      // maxWidth: 308,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 20,
      // maxWidth: 365,
    },
  },

  dateWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  date: {
    fontFamily: 'ProximaNova-RegularIt',
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '0px',
    color: '#868686',
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: 24,
      height: 1,
      background: '#E2E1DD',
      margin: '0 16px 0 27px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },

  title: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  },

  description: {
    fontFamily: 'ProximaNova-Regular',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.5px',
    fontWeight: 'normal',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
}))
