import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'
import ReactPlayer from 'react-player'
import clsx from 'clsx'

import useStyles from './Video.styles'

import { VideoFields } from '../../../@types/Section/Video'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

type Props = {
  data: VideoFields
  invert?: boolean
}

const Video: React.FC<Props> = ({ data, invert }) => {
  const styles = useStyles()

  const win: any =
    typeof window !== 'undefined'
      ? window
      : { OneTrust: { InsertHtml: () => {}, OnConsentChanged: () => {}, ToggleInfoDisplay: () => {} } }

  const toggleConsentBanner = () => {
    win.OneTrust.ToggleInfoDisplay()
  }

  const videoFileName = (data?.video_file || '').split('/').pop() || ''

  const [videoName] = videoFileName.split('.')

  const matomoTitle = data?.label || videoName

  const ConsentOverlay = () => (
    <div className={styles.overlay}>
      <div className={styles.previewImage}>
        <ResponsiveImage image={data.preview_image} />
      </div>
      <div className={styles.content}>
        <Typography className={styles.consentText}>{data.video_consent.disclaimer}</Typography>
        <div className={clsx(styles.buttonsGroup, styles.pt)}>
          <Button
            onClick={() => toggleConsentBanner()}
            variant="contained"
            className={styles.consentButton}
            disableRipple={true}
          >
            {data.video_consent.button_text}
          </Button>
        </div>
      </div>
    </div>
  )

  const CustomPlayer = () => (
    <div className={styles.player + ' ' + data.label ? styles.image : styles.imageNoLabel}>
      <ReactPlayer
        controls={true}
        playsinline={true}
        url={data.video_file}
        config={{
          file: {
            attributes: {
              poster: data.preview_image.sizes.xl.url,
              'data-matomo-title': matomoTitle,
            },
            forceVideo: false,
          },
        }}
        width="100%"
        height="100%"
      />
    </div>
  )

  const YoutubeIframe = () => (
    <div id="iframewrapper" className={data.label ? styles.image : styles.imageNoLabel}>
      <iframe
        allowFullScreen
        frameBorder="0"
        src={`https://www.youtube-nocookie.com/embed/${data.youtube_id}?enablejsapi=1`}
      ></iframe>
    </div>
  )

  return (
    <div className={clsx(data.label ? styles.rootHasLabel : styles.root, { [styles.invert]: invert })}>
      {data.label && <Typography className={styles.label}>{data.label}</Typography>}
      <Grid container className={clsx(styles.container, { [styles.invert]: invert })}>
        <Grid className={styles.leftRow} item xs={12} lg={4}>
          <Typography className={clsx(styles.caption, { [styles.invert]: invert })}>{data.caption_headline}</Typography>
          <Typography className={clsx(styles.description, { [styles.invert]: invert })}>{data.caption_copy}</Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          {data.type === 'file' && <CustomPlayer />}
          {data.type === 'youtube' && <YoutubeIframe />}
        </Grid>
      </Grid>
    </div>
  )
}

export default Video
