import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import { ImageField } from '../../../../../@types/Fields'

import useStyles from './Comparison.styles'

type Props = {
  image_left: ImageField
  image_right: ImageField
  label_left: string
  label_right: string
  onPositionChange?: (position: number) => void
}

const ComparisonSlider: React.FC<Props> = ({ image_left, image_right, onPositionChange }) => {
  const styles = useStyles()

  const imageLeftSrc = `${image_left.url}`
  const imageRightSrc = `${image_right.url}`

  return (
    <div className={styles.container}>
      <div className={clsx(styles.label, styles.leftLabel)}>Vorher</div>
      <div className={clsx(styles.label, styles.rightLabel)}>Nachher</div>

      <ReactCompareSlider
        onPositionChange={onPositionChange}
        itemOne={<ReactCompareSliderImage src={imageLeftSrc} srcSet={imageLeftSrc} alt={image_left.alt} />}
        itemTwo={<ReactCompareSliderImage src={imageRightSrc} srcSet={imageRightSrc} alt={image_right.alt} />}
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
        onlyHandleDraggable
        handle={
          <div className={styles.handle}>
            <div className={styles.line} />
            <div className={styles.square} />
          </div>
        }
      />
    </div>
  )
}

export default dynamic(() => Promise.resolve(ComparisonSlider), { ssr: false })
