import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    sliderControlsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    sliderProgresWrap: {
      display: 'flex',
      alignItems: 'center',
    },

    sliderProgres: {
      position: 'relative',
      backgroundColor: theme.palette.beige.main,
      width: 80,
      height: 2,
      margin: '0 22px',

      [theme.breakpoints.up('sm')]: {
        margin: '0 26px',
      },

      [theme.breakpoints.up('lg')]: {
        width: 122,
        margin: '0 18px',
      },
    },

    sliderProgresIndicator: {
      backgroundColor: theme.palette.beige.main,
      position: 'absolute',
      zIndex: 2,
      height: 4,
      transition: 'width 0.4s linear',
      top: -1,
    },

    control: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    controlButton: {
      width: 30,
      height: 30,
      borderRadius: 40,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
      borderWidth: 1,
      transition: theme.transitions.create('opacity'),
      backgroundColor: 'transparent',
      opacity: 1,
      [theme.breakpoints.up('sm')]: {
        width: 32,
        height: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
      },

      '&[disabled=""]': {
        opacity: 0.4,
      },

      '&:hover': {
        backgroundColor: '#E3E0DB',
        borderColor: '#E3E0DB',
      },

      '& svg path': {
        fill: theme.palette.common.white,
      },

      '&:hover svg path': {
        fill: theme.palette.common.black,
      },
    },

    controlLeftButton: {
      transform: 'rotate(180deg)',
    },

    arrow: {
      width: 20,
      height: 20,
      [theme.breakpoints.up('sm')]: {
        width: 22,
        height: 22,
      },
      [theme.breakpoints.up('lg')]: {
        width: 30,
        height: 30,
      },
    },

    leftCounter: {
      fontSize: 12,
      color: theme.palette.beige.main,
      fontFamily: 'ProximaNova-RegularIt',
      fontWeight: 300,
    },

    rightCounter: {
      fontSize: 12,
      color: theme.palette.beige.main,
      fontFamily: 'ProximaNova-RegularIt',
      fontWeight: 300,
    },
  }),
  { name: 'SlideControlls' }
)
