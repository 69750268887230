import useStyles from './FilterLabel.styles'
import { Typography } from '@material-ui/core'

type Props = {
  label: string
  labelcolor: string | undefined
}

const FilterLabel: React.FC<Props> = ({ label, labelcolor }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography
        component="p"
        style={{ color: labelcolor || '#868686', fontSize: 12, lineHeight: '18px', letterSpacing: 0 }}
      >
        {label}
      </Typography>
    </div>
  )
}

export default FilterLabel
