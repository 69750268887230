import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  slides: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
    '& .react-swipeable-view-container': {
      '& [aria-hidden="true"]': {
        opacity: '0.5',
        zIndex: -1,
        position: 'relative',
        left: '51vw',

        '& $captionTitle, $captionCopy': {
          opacity: 0,
        },
        '& $slideImage': {
          transform: 'scale(0.8)',
          left: 150,
        },
        '& $slideCaption': {
          border: 'none',
        },
      },

      '& [aria-hidden="false"] + [aria-hidden="true"]': {
        left: '-51vw',
      },
    },
  },

  slide: {
    width: '100%',
    height: '100%',
    zIndex: 1,

    '& img': {
      width: '100%',
      height: '100%',
      userDrag: 'none',
      userSelect: 'none',
      // maxWidth: 320,
    },
  },

  slideContentWrap: {
    maxWidth: 306,
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 336.75,
    },
  },

  slideImage: {
    width: '100%',
    display: 'inline-block',
    transition: theme.transitions.create('all'),
  },

  slideCaption: {
    width: '100%',
    padding: theme.spacing(1, 0, 0, 0),
  },

  captionTitle: {
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '18px',
    textAlign: 'center',
    transition: theme.transitions.create('all'),

    '&$invert': {
      color: theme.palette.beige.main,
    },
  },

  copyWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 96,
  },

  captionCopy: {
    fontSize: 20,
    letterSpacing: 1,
    fontFamily: 'ProximaNova-Semibold',
    textAlign: 'center',
    lineHeight: '28px',
    transition: theme.transitions.create('all'),

    '&$invert': {
      color: theme.palette.common.white,
    },
  },

  invert: {},

  imageFallback: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  fallbackText: {
    '&$invert': {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
  },

  controlls: {
    marginTop: theme.spacing(0.5),
  },
  separator: {
    borderTop: `1px solid ${theme.palette.beige.main}`,
    margin: theme.spacing(0, 4.5),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 8),
    },

    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
}))
