import React from 'react'
import clsx from 'clsx'

import { useMediaQuery, useTheme } from '@material-ui/core'

import { ImageGalleryFields } from '../../../@types/Section/ImageGallery'

import GalleryDesktop from './components/GalleryDesktop'
import GalleryMobile from './components/GalleryMobile'

import useStyles from './ImageGallery.styles'

type Props = {
  data: ImageGalleryFields
  invert?: boolean
}

const ImageGallery: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()

  const theme = useTheme()

  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={clsx(classes.root, { [classes.invert]: invert })}>
      <div className={clsx(classes.wrapper, classes.desktopOnly)}>
        {isMediumUp && <GalleryDesktop data={data} invert={invert} />}
      </div>
      <div className={clsx(classes.mobileOnly)}>{isMediumDown && <GalleryMobile data={data} invert={invert} />}</div>
    </div>
  )
}

export default ImageGallery
