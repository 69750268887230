import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
  textColor?: string
  invertModuleColor?: boolean
}

const getBackgroundColor = (props: Props) =>
  props?.invertModuleColor && props?.color ? `${props?.color} !important` : '#fff'

const getBorderColor = (props: Props) => (props?.invertModuleColor && props?.color && `#fff !important`) || undefined

const getTextColor = (props: Props, theme: Theme) =>
  props?.invertModuleColor && props?.color ? '#fff' : theme.palette.common.black

const getLabelColor = (props: Props, theme: Theme) =>
  props?.invertModuleColor && props?.color ? '#fff' : theme.palette.gray.main

const getButtonBorderColor = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) {
    return theme.palette.common.black
  }

  return props?.color ? props.color : theme.palette.common.black
}

const getButtonBackground = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) return theme.palette.common.black

  if (props?.color) return props?.color

  return undefined
}

const getButtonTextColor = (props: Props, theme: Theme) => {
  if (props?.invertModuleColor) return theme.palette.common.white

  if (props?.textColor) return props?.textColor

  return theme.palette.common.black
}

const getInvertedHoverStyles = (props: Props, theme: Theme): any => {
  if (props?.invertModuleColor) {
    return {
      backgroundColor: `${theme.palette.beige.main} !important`,
      borderColor: `${theme.palette.common.black} !important`,
      color: `${theme.palette.common.black} !important`,
    }
  }

  return {
    backgroundColor: `${theme.palette.beige.main} !important`,
    borderColor: `${theme.palette.beige.main} !important`,
    color: `${theme.palette.common.black} !important`,
  }
}

export default makeStyles<Theme, Props>(
  (theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'column',
      marginTop: 60,
      marginBottom: 60,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 80,
        marginTop: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginBottom: 100,
        marginTop: 100,

        flexDirection: 'row',
        alignItems: 'stretch',

        '&$reversed': {
          flexDirection: 'row-reverse',
        },
      },
    },

    imageColumn: {
      display: 'flex',
      maxHeight: 305,
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        maxHeight: 388,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
        maxHeight: 619,
      },
    },

    image: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },

    textColumn: {
      backgroundColor: (props) => getBackgroundColor(props),
      paddingTop: 24,
      paddingBottom: 22,
      paddingLeft: 25,
      paddingRight: 25,
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 59,
        paddingRight: 59,
        paddingTop: 32,
        paddingBottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 80,
        paddingBottom: 80,
        paddingLeft: 109,
        paddingRight: 109,
      },

      '&$isBeigeBG': {
        backgroundColor: theme.palette.beige.main,
      },

      '&$isWhiteBg': {
        backgroundColor: theme.palette.common.white,
      },
    },

    wrapper: {
      display: 'flex',
      border: 0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      alignSelf: 'stretch',
      flex: 1,
      paddingTop: 14,
      paddingBottom: 31,
      borderColor: (props) => getBorderColor(props),

      [theme.breakpoints.up('sm')]: {
        paddingTop: 17,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 24,
      },

      '&$isBeigeBG': {
        borderColor: theme.palette.gray.main,
      },

      '&$isWhiteBg': {
        borderColor: '#cccccc',
      },
    },

    box: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'flex-start',
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: (props) => getLabelColor(props, theme),
      paddingBottom: 24,
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
        paddingBottom: 39,
      },
    },

    headline: {
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.4,
      letterSpacing: '1px',
      color: (props) => getTextColor(props, theme),
      paddingBottom: 24,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: 1.25,
        paddingBottom: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '28px',
        lineHeight: 1.29,
        paddingBottom: 34,
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.57,
      letterSpacing: '0.5px',
      color: (props) => getTextColor(props, theme),
      paddingBottom: 32,
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: 1.5,
        paddingBottom: 39,
      },
    },

    button: {
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 20,
      paddingRight: 20,
      alignSelf: 'flex-start',
      borderRadius: 100,
      minWidth: 158,
      borderColor: (props) => getButtonBorderColor(props, theme),
      backgroundColor: (props) => getButtonBackground(props, theme),
      '&:hover': (props) => ({
        ...(getInvertedHoverStyles(props, theme) || {}),
      }),
    },

    buttonLabel: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      color: (props) => getButtonTextColor(props, theme),
      textTransform: 'none',
    },
    reversed: {},
    isBeigeBG: {},
    isWhiteBg: {},
  }),
  { name: 'CTATeaser' }
)
