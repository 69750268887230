import React, { useRef, useState, useMemo, useEffect } from 'react'

import { HotspotImageEntry, HotspotImageSubEntry } from '../../../@types/Section/HotspotImage'

import isTouchDevice from '../../helpers/isTouchDevice'
import preloadImage from '../../helpers/preloadImage'

import useStyles from './HotspotImage.styles'

import IconHotSpot from '../../../public/icons/icon-hot-spot.svg'

import faceDataDesktop from './face.json'
import handDataDesktop from './hand.json'
import decolleteDataDesktop from './decollete.json'

import faceDataMobile from './face_portrait.json'
import handDataMobile from './hand_portrait.json'
import decolleteDataMobile from './decollete_portrait.json'

type AreaType = {
  src: string
  center: boolean
  id: string
}

const hotSpotIconSize = 20
const hotSpotIconSizeMobile = 32

const Area: React.FC<{
  data: AreaType
  mobile?: boolean
  visible?: boolean
  onClick: (id: string) => void
  imageRef: React.MutableRefObject<HTMLImageElement | null>
}> = ({ data, mobile = false, visible = false, onClick, imageRef }) => {
  if (!visible) return null

  const { src = '', center = false } = data
  const classes = useStyles()
  const ref = useRef<HTMLObjectElement | null>(null)

  const [positioning, setPositioning] = useState<{
    topPosition?: number
    leftPosition?: number
    rectTop?: number
    rectLeft?: number
    appliedLeftCorrection?: number
    iconSize?: number
    leftPositionCentered?: number
    topPositionCentered?: number
  } | null>(null)

  const handleOnLoad = () => {
    setTimeout(() => {
      const svg = ref.current?.getSVGDocument()

      const pathElements = svg?.getElementsByTagName('path')

      const pathIndex = 0 // Math.floor(Math.random() * (pathElements?.length || 0))

      const path = pathElements?.[pathIndex]

      const rect = path?.getBoundingClientRect() || null

      const rectTop = rect?.top
      const rectLeft = rect?.left
      const rectWidth = rect?.width
      const rectHeight = rect?.height

      const iconSize = mobile ? hotSpotIconSizeMobile : hotSpotIconSize

      const topPosition = rectTop && rectHeight ? rectTop + rectHeight / 2 : undefined
      const leftPosition = rectLeft && rectWidth ? rectLeft + rectWidth / 2 : undefined

      const topPositionCentered = topPosition ? topPosition - iconSize / 2 : undefined
      const leftPositionCentered = leftPosition ? leftPosition - iconSize / 2 : undefined

      const imageWidth = imageRef.current?.width || 0
      const leftCorection = imageWidth - ((leftPositionCentered || 0) + iconSize)
      const appliedLeftCorrection = leftCorection > 0 ? 0 : leftCorection

      /**
       * We delay a bit to give some time for svgs to load
       */

      setPositioning({
        topPosition,
        leftPosition,
        rectTop,
        rectLeft,
        appliedLeftCorrection,
        iconSize,
        leftPositionCentered,
        topPositionCentered,
      })
    }, 500)
  }

  const handleMouseOver = () => {
    if (mobile || isTouchDevice()) return

    const styles = ref?.current?.style

    if (styles) {
      styles.visibility = 'visible'
    }
  }

  const handleMouseOut = () => {
    if (mobile || isTouchDevice()) return

    const styles = ref?.current?.style

    if (styles) {
      styles.visibility = 'hidden'
    }
  }

  const handleClick = () => {
    onClick(data.id)
  }

  return (
    <div className={classes.area}>
      <object
        ref={ref}
        className={classes.overlayArea}
        data={src}
        onLoad={handleOnLoad}
        style={{ visibility: 'hidden' }}
      />
      {positioning && (
        <IconHotSpot
          className={classes.hotSpot}
          style={{
            top: center ? positioning.topPositionCentered : positioning.rectTop,
            left: center
              ? (positioning.leftPositionCentered || 0) + (positioning?.appliedLeftCorrection || 0)
              : positioning.rectLeft,
            width: positioning.iconSize,
            height: positioning.iconSize,
            ...(!positioning.topPosition && !positioning.leftPosition ? { display: 'none' } : {}),
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleClick}
        />
      )}
    </div>
  )
}

type ImageData = {
  mainImage: string
  areas: AreaType[]
  bg?: {
    color?: string
    gradient?: string
  }
}

export interface Props {
  variant: 'hand' | 'face' | 'decollete'
  config: HotspotImageEntry
  onHotspotClick: (id: string) => void
  mobile?: boolean
  bgColor?: string
}

const HotspotImage: React.FC<Props> = ({ variant, config, onHotspotClick, mobile = false, bgColor }) => {
  const classes = useStyles()

  const imageRef = useRef<HTMLImageElement | null>(null)

  const faceData = mobile ? faceDataMobile : faceDataDesktop
  const handData = mobile ? handDataMobile : handDataDesktop
  const decolleteData = mobile ? decolleteDataMobile : decolleteDataDesktop

  const face = variant === 'face' && faceData
  const hand = variant === 'hand' && handData
  const decollete = variant === 'decollete' && decolleteData

  const data = (face || hand || decollete || { mainImage: '', areas: [] }) as ImageData

  useEffect(() => {
    /**
     * Preload all main images
     */
    preloadImage(faceDataDesktop.mainImage)
    preloadImage(handDataDesktop.mainImage)
    preloadImage(decolleteDataDesktop.mainImage)
    preloadImage(faceDataMobile.mainImage)
    preloadImage(handDataMobile.mainImage)
    preloadImage(decolleteDataMobile.mainImage)
  }, [])

  const hotspots: Record<string, HotspotImageSubEntry> = useMemo(() => {
    const result = config.hotspots.reduce((prev, current) => {
      const key = encodeURIComponent(current.title.toLowerCase())

      return { ...prev, [key]: current }
    }, {})

    return result
  }, [config.hotspots])

  const configuredHotspots = Object.keys(hotspots)

  const handleAreaClick = (id: string) => {
    onHotspotClick(id)
  }

  return (
    <div className={classes.root}>
      <img
        ref={imageRef}
        className={classes.image}
        src={data.mainImage}
        style={{
          backgroundImage: !bgColor ? `${data?.bg?.gradient}` : undefined,
          backgroundColor: bgColor || data?.bg?.color,
        }}
      />
      {data.areas.map((area: AreaType, index: number) => (
        <Area
          key={`${area.src}_${index}`}
          data={area}
          mobile={mobile}
          visible={configuredHotspots.includes(area.id)}
          onClick={handleAreaClick}
          imageRef={imageRef}
        />
      ))}
    </div>
  )
}

export default HotspotImage
