import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    accordion: {
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
      border: 0,
      borderBottomWidth: '1px',
      borderColor: '#cccccc',
      borderStyle: 'solid',
    },

    accordionExpanded: {
      margin: '0 !important',
    },

    accordionRounded: {
      borderRadius: '0 !important',
    },

    accordionSummary: {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 8,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },

    accordionContent: {
      '&$expanded': {
        margin: theme.spacing(1.5, 0),
      },
    },

    accordionDetails: {
      padding: 0,
      paddingBottom: 31,
      maxWidth: '90%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        columnGap: 25,
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        columnGap: 45,
      },

      '& ul': {
        margin: 0,
      },
    },

    expanded: {},

    title: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      maxWidth: 190,

      [theme.breakpoints.up('sm')]: {
        maxWidth: 450,
      },

      [theme.breakpoints.up('lg')]: {
        maxWidth: 550,
        fontSize: '18px',
        lineHeight: 1.67,
      },
    },

    titleBold: {
      fontFamily: 'ProximaNova-Semibold',
    },

    titleWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },

    iconContainer: {
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      columnGap: 30,

      '& a': {
        display: 'inline-flex',
        alignItems: 'center',
      },

      '& svg': {
        width: 32,
        height: 32,
      },

      '& svg.external': {
        width: 40,
        height: 40,
      },
    },

    image: {
      maxWidth: 128.3,
      height: 'auto',
      backgroundColor: '#3e3e3e',

      [theme.breakpoints.up('lg')]: {
        maxWidth: 'initial',
        width: 182,
        height: 243,
      },
    },

    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.5px',
      '& span': {
        marginRight: 15,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px',
        lineHeight: 1.5,
      },
    },
  }),
  { name: 'Accordion' }
)
