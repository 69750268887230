/**
 * Also know as Hotspot Image Module
 */
import React, { useState, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { HotspotImageFields, HotspotImageSubEntry } from '../../../../../@types/Section/HotspotImage'

import ModuleWrap from '../../../ModuleWrap'
import HotSpotImage from '../../../HotspotImage'
import Tabs from '../../../Tabs'
import BaseModal from '../../../BaseModal'
import ApplicationModal from '../../../ApplicationModal'
import BottomSwipeableDrawer from './components/BottomSwipeableDrawer'

type Props = {
  data: HotspotImageFields
}

const ApplicationModule: React.FC<Props> = ({ data }) => {
  const theme = useTheme()

  const [selectedHotspot, setSelectedHotspot] = useState<string>('')

  const closeModal = () => setSelectedHotspot('')

  const handleHotspotSelect = (id: string) => setSelectedHotspot(id)

  const hotspots: {
    areas: Record<string, Record<string, HotspotImageSubEntry>>
    all: Record<string, HotspotImageSubEntry>
  } = useMemo(() => {
    const areas: Record<string, Record<string, HotspotImageSubEntry>> = data.items.reduce((prev, item) => {
      const areaHotspots = item.hotspots.reduce((prevHotspots, hotspot) => {
        const key = encodeURIComponent(hotspot.title.toLowerCase())
        return {
          ...prevHotspots,
          [key]: { ...hotspot, area: item.title },
        }
      }, {})

      return {
        ...prev,
        [item.title]: areaHotspots,
      }
    }, {})

    const all = Object.values(areas).reduce((prev, area) => ({ ...prev, ...area }), {})

    return {
      areas,
      all,
    }
  }, [data.items])

  const modalData = hotspots.all[selectedHotspot] || null
  const modalArea = (modalData?.area && hotspots.areas[modalData?.area]) || null
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <ModuleWrap
        id={data?.anchor_id || undefined}
        label={data?.label?.trim()}
        heading={data?.headline?.trim()}
        copy={data.copy}
        disclaimer={data.disclaimer}
        main={
          <Tabs
            data={data.items}
            colorOverWrite={data?.color_overwrite}
            renderTabContent={(item: any) => {
              const title = encodeURIComponent(item.title.toLowerCase())

              const face = title === 'gesicht' && 'face'
              const hand = title === 'hand' && 'hand'
              const decollete = title === 'dekollet%C3%A9' && 'decollete'

              const variant = face || hand || decollete

              return (
                variant && (
                  <HotSpotImage
                    bgColor={item?.background_color}
                    config={item}
                    variant={variant}
                    onHotspotClick={handleHotspotSelect}
                  />
                )
              )
            }}
          />
        }
      />

      <BaseModal open={Boolean(isDesktop && modalData)} onClose={closeModal}>
        <ApplicationModal itemId={selectedHotspot} data={modalData} area={modalArea} onChange={handleHotspotSelect} />
      </BaseModal>

      <BottomSwipeableDrawer drawer={Boolean(!isDesktop && modalData)} toggleDrawer={closeModal}>
        <ApplicationModal itemId={selectedHotspot} data={modalData} area={modalArea} onChange={handleHotspotSelect} />
      </BottomSwipeableDrawer>
    </>
  )
}

export default ApplicationModule
