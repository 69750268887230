import React from 'react'
import clsx from 'clsx'

import { Typography } from '@material-ui/core'

import ResponsiveImage from '../../../ResponsiveImage/ResponsiveImage'

import { ImageGalleryElement } from '../../../../../@types/Section/ImageGallery'

import useStyles from './GalleryImage.styles'

type Props = {
  id: string
  data: ImageGalleryElement
  invert?: boolean
}

const GalleryImage: React.FC<Props> = ({ id, data, invert }) => {
  const classes = useStyles()

  const image = data?.image

  const noImage = !image

  return (
    <div
      id={id}
      className={clsx(classes.root, {
        [classes.noImage]: noImage,
      })}
    >
      {image ? (
        <ResponsiveImage image={image} factorOverwrite={1} />
      ) : (
        <Typography
          className={clsx(classes.fallbackText, { [classes.invert]: invert })}
          dangerouslySetInnerHTML={{ __html: data?.caption_copy || '' }}
        />
      )}
      <div className={classes.overlay}>
        <div className={classes.overlayContent}>
          <Typography className={classes.captionTitle}>{data?.caption_title || ''}</Typography>
          <Typography
            className={clsx(classes.captionCopy, { [classes.invert]: invert })}
            dangerouslySetInnerHTML={{ __html: data?.caption_copy || '' }}
          />
        </div>
      </div>
    </div>
  )
}

export default GalleryImage
