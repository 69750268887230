import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),
  { name: 'ImageGaleryModule' }
)
