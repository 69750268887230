import React from 'react'

import { Typography } from '@material-ui/core'

import { VideoListFields } from '../../../@types/Section/VideoList'

import Module from '../Module'

import VideoComponent from './components/VideoComponent'

import useStyles from './VideoList.styles'

type Props = {
  data: VideoListFields
}

const VideoList: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  return (
    <Module id={data.anchor_id} title={data.headline} label={data.label} fullWidthChildren>
      <div className={classes.root}>
        {data.list.map((item, index) => (
          <VideoComponent
            key={`video_list_item_${index}`}
            date={item.date}
            title={item.title}
            description={item.copy}
            data={item}
          />
        ))}
      </div>
    </Module>
  )
}
export default VideoList
