import React from 'react'

interface Props {
  children?: React.ReactNode
  index: any
  value: any
  idPrefix?: string
}

const TabPanel: React.FC<Props> = ({ children, index, value, idPrefix = 'tab-panel-' }) => (
  <div role="tabpanel" hidden={value !== index} id={`${idPrefix}${index}`} aria-labelledby={`${idPrefix}${index}`}>
    {value === index && children}
  </div>
)

export default TabPanel
