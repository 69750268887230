import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    formControl: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 220,
      },
      [theme.breakpoints.up('lg')]: {
        width: 291,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '0 !important',
      },
      '& .Mui-error': {
        '& fieldset': {
          borderWidth: 1,
          borderColor: `${theme.palette.error.main} !important`,
        },
        '& svg': {
          fill: theme.palette.error.main,
        },
      },
      '& label.Mui-error': {
        color: theme.palette.error.main,
      },
    },

    selectLabel: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.beige.light,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: 1.5,
      transform: 'translate(14px, 15px)',
      backgroundColor: theme.palette.common.white,
      paddingRight: 15,
      paddingLeft: 5,
      '&.Mui-focused': {
        color: '#868686 !important',
        backgroundColor: theme.palette.common.white,
      },
      '&.Mui-error': {
        color: `${theme.palette.error.main} !important`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    selectRoot: {
      width: '100%',
      height: 22,
      paddingTop: 13,
      paddingBottom: 13,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: `${theme.palette.common.white} !important`,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      display: 'flex',
      alignItems: 'center',
      '& ~ fieldset': {
        borderColor: '#cccccc !important',
        borderWidth: '1px !important',
      },
      '&[aria-expanded="true"] ~ fieldset': {
        borderColor: '#868686 !important',
        borderWidth: '1px !important',
      },
      '&:hover ~ fieldset': {
        borderColor: '#868686 !important',
        borderWidth: '1px !important',
      },
      '&[aria-expanded="true"]': {
        '& ~ svg': {
          transform: 'rotate(0deg)',
        },
      },
    },

    selectIcon: {
      transform: 'rotate(-180deg)',
      position: 'absolute',
      right: 9,
      width: 32,
      zIndex: 4,
      height: 32,
      transition: 'transform 0.2s linear',
      pointerEvents: 'none',
      cursor: 'pointer',
    },

    paper: {
      borderWidth: 0,
      borderStyle: 'solid',
      boxShadow: 'none !important',
      borderRadius: 0,
      marginTop: '48px !important',
    },

    list: {
      padding: 0,
      border: 0,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& li': {
        marginTop: -1,
        border: 0,
        borderColor: '#868686',
        borderWidth: 1,
        borderStyle: 'solid',
        fontFamily: 'ProximaNova-Regular',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        color: '#868686',
        paddingTop: 13,
        paddingBottom: 13,
        '&:hover': {
          backgroundColor: theme.palette.beige.main,
          color: theme.palette.common.black,
        },
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.beige.main,
          color: theme.palette.common.black,
        },
      },
    },
    select: {
      '&$noBorders': {
        '&.Mui-focused:after': {
          border: 'none',
        },
      },
      '&.Mui-focused:after': {
        borderColor: theme.palette.common.black,
      },
    },
    noBorders: {},
  }),
  { name: 'BaseSelect' }
)
