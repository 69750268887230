import React from 'react'

import { Container } from '@material-ui/core'

import { ImageGalleryFields } from '../../../../../@types/Section/ImageGallery'

import ImageGallery from '../../../ImageGallery'

import useStyles from './ImageGaleryModule.styles'

type Props = {
  fields: ImageGalleryFields
  invert?: boolean
}

const ImageGaleryModule: React.FC<Props> = ({ fields, invert }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="lg">
      <ImageGallery data={fields} invert={invert} />
    </Container>
  )
}

export default ImageGaleryModule
