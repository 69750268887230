import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    form: {
      width: '100%',
      '& fieldset': {
        borderRadius: 0,
      },
    },

    formControl: {
      '& label.Mui-focused': {
        color: '#868686 !important',
      },
      '& label.Mui-error': {
        color: `${theme.palette.error.main} !important`,
      },
    },

    category: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 16,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.44,
      },
    },

    pb1: {
      paddingBottom: 16,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 25,
      },
    },

    pb2: {
      paddingBottom: 24,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 32,
      },
    },

    inputLabel: {
      transform: 'translate(14px, 15px) scale(1)',
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#868686',
      fontSize: 12,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    inputRoot: {
      alignItems: 'center',
      borderRadius: 0,
      padding: 0,
      height: 48,
      '& fieldset': {
        borderWidth: '1px !important',
        borderColor: '#cccccc !important',
      },
      '&:hover fieldset': {
        borderWidth: '1px !important',
        borderColor: '#868686 !important',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px !important',
        borderColor: '#868686 !important',
      },
      '&.Mui-error fieldset': {
        borderWidth: '1px !important',
        borderColor: `${theme.palette.error.main} !important`,
      },
    },

    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: 24,

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        columnGap: 15,
        rowGap: 32,
      },

      [theme.breakpoints.up('lg')]: {
        columnGap: 37,
      },
    },

    input: {
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: 'transparent',
      borderRadius: 0,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },

      '& fieldset': {
        borderRadius: 0,
      },
    },

    radioGruop: {
      display: 'flex',
      flexDirection: 'row',
    },

    errorMessage: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      color: theme.palette.error.main,
    },

    section: {
      maxHeight: 'max-content',
      paddingBottom: 32,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 40,
      },
    },

    selectIcon: {
      transform: 'rotate(180deg)',
      position: 'absolute',
      right: 9,
      width: 32,
      zIndex: 4,
      height: 32,
    },

    textareaWrapper: {
      marginTop: 24,
      marginBottom: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
        marginBottom: 26,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 40,
      },
    },

    textarea: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#cccccc',
      borderRadius: 0,
      width: '100%',
      paddingTop: 16,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      minHeight: 'calc(256px - 32px)',
      outline: '0 !important',
      '&:hover': {
        borderColor: '#868686',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    textareaError: {
      borderColor: `${theme.palette.error.main} !important`,
      '&::placeholder': {
        color: `${theme.palette.error.main} !important`,
      },
    },

    hint: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      marginBottom: 8,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    radio: {
      justifyContent: 'center',
      padding: '0 !important',
      margin: 0,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },

    formControlLabel: {
      margin: 0,
      '&:nth-child(1)': {
        marginRight: 40,
      },
    },

    controlLabel: {
      fontSize: 12,
      lineHeight: 1.5,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },

    successMessage: {
      '& p, & strong': {
        fontFamily: 'ProximaNova-Regular',
        fontSize: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: theme.palette.common.black,
        margin: 0,

        [theme.breakpoints.up('lg')]: {
          fontSize: 18,
        },
      },

      '& strong': {
        fontFamily: 'ProximaNova-Semibold',
        display: 'inline-block',
        paddingBottom: 20,

        [theme.breakpoints.up('lg')]: {
          paddingBottom: 24,
        },
      },
    },
  }),
  { name: 'Form' }
)
