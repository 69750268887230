import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginTop: 15,
      marginBottom: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: 25,
        marginBottom: 30,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 34,
        marginBottom: 40,
      },
    },

    rootHasLabel: {
      marginTop: 40,
      marginBottom: 40,
      borderTopWidth: 1,
      borderTopColor: '#cccccc',
      borderTopStyle: 'solid',
    },

    content: {
      marginTop: -2,
      '& > *:first-child': {
        marginTop: 0,
        paddingTop: 0,
      },
      '&.has-label > *:first-child': {
        marginTop: 12,
        paddingTop: 0,
        [theme.breakpoints.up('xs')]: {
          marginTop: '20px',
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: '22px',
        },
      },
      '& > *:last-child': {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    label: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.gray.main,
      textTransform: 'uppercase',
      paddingBottom: 0,
      paddingTop: 16,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
        paddingBottom: 40,
        paddingTop: 24,
        position: 'absolute',
      },

      '& $invert': {
        color: theme.palette.beige.main,
      },
    },

    invert: {},
  }),
  { name: 'Text' }
)
