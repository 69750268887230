import React, { ComponentType, MouseEvent, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'

interface WithClickTrackingProps extends LinkProps {
  children: ReactNode
  label?: string
  position?: 'header' | 'body' | 'footer'
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

const withClickTracking = <P extends WithClickTrackingProps>(WrappedComponent: ComponentType<P>) => {
  const WithClickTracking: React.FC<P> = (props) => {
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      console.log('========')
      console.log('===meh==')
      console.log('========')
      if (window) {
        window._mtm = window._mtm || []
        window._mtm.push({
          event: 'link_click',
          category: `link_click_${props?.position || 'body'}`,
          action: props?.label,
          label: props.href,
        })
      }

      if (props?.onClick) {
        props?.onClick(event)
      }
    }

    return <WrappedComponent {...props} onClick={handleClick} />
  }

  return WithClickTracking
}

const TrackedNextLink = withClickTracking(Link)

export default TrackedNextLink
