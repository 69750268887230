import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
    },

    pinContainer: {
      width: 28,
      height: 28,
      left: -14,
      top: -28,
      position: 'relative',
      zIndex: 1,
    },

    largePininContainer: {
      width: 40,
      height: 40,
      left: -20,
      top: -40,
      position: 'relative',
      zIndex: 2,
    },

    locationContainer: {
      position: 'absolute',
      zIndex: 2,
    },

    positionOnRight: {
      top: -16,
      [theme.breakpoints.up('sm')]: {
        left: 62,
      },
      [theme.breakpoints.up('lg')]: {
        left: 70,
      },
    },

    positionOnLeft: {
      top: -16,
      [theme.breakpoints.up('sm')]: {
        left: -360,
      },
      [theme.breakpoints.up('lg')]: {
        left: -430,
      },
    },
  }),
  { name: 'Pin' }
)
