import useStyles from './SearchItem.styles'
import { Link, Typography } from '@material-ui/core'
import PhoneIcon from '../../../../../../../../public/icons/icon-phone-black.svg'
import SolidPhoneIcon from '../../../../../../../../public/icons/icon-phone-solid.svg'
import WebIcon from '../../../../../../../../public/icons/icon-website-black.svg'
import SolidWebIcon from '../../../../../../../../public/icons/icon-website-solid.svg'
import RouteIcon from '../../../../../../../../public/icons/icon-route-black.svg'
import SolidRouteIcon from '../../../../../../../../public/icons/icon-route-solid.svg'

import clsx from 'clsx'
import React, { useState } from 'react'
import { DocSearchResultItem } from '../../../../../../../../@types/DocSearch'
import useMatomoDocSearchOutboundLinkClick from '../../../../../../../hooks/useMatomoDocSearchOutboundLinkClick'

type Props = {
  active: boolean
  onHoverPin: (slug: string) => void
  onClickPin: (slug: string) => void
  result: Partial<DocSearchResultItem>
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchItem: React.FC<Props> = ({ active = false, onHoverPin, result, onClickPin, setIsDrawerOpen }) => {
  const classes = useStyles()

  const { trackDocSearchOutboundLinkClick } = useMatomoDocSearchOutboundLinkClick()

  const slug = result?.slug || ''

  const handleClick = () => {
    onClickPin(slug)
    setIsDrawerOpen(true)
  }

  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const [isHovering1, setIsHovered1] = useState(false)
  const onMouseEnter1 = () => setIsHovered1(true)
  const onMouseLeave1 = () => setIsHovered1(false)

  const [isHovering2, setIsHovered2] = useState(false)
  const onMouseEnter2 = () => setIsHovered2(true)
  const onMouseLeave2 = () => setIsHovered2(false)

  const openDirectionsLink = `http://maps.apple.com/?daddr=${result?.infos?.join(', ').replace(/ /g, '+')}` // Interestingly, http://maps.apple.com links will open directly in Apple Maps on an iOS device, or redirect to Google Maps otherwise (which is then intercepted on an Android device), so you can craft a careful URL that will do the right thing in both cases using an "Apple Maps" URL

  return (
    <div
      id={slug}
      className={clsx(classes.root, { [classes.active]: active })}
      onMouseEnter={() => onHoverPin(slug)}
      onMouseLeave={() => onHoverPin('')}
      onClick={handleClick}
    >
      <Typography component="p" className={classes.title}>
        {result?.title}
      </Typography>

      <Typography component="p" className={classes.subtitle}>
        {result?.infos?.map((item: string, index: number) => (
          <React.Fragment key={index}>
            {item} <br></br>
          </React.Fragment>
        ))}
      </Typography>

      <div className={classes.optionsWrap}>
        <div className={classes.iconWrap}>
          {result?.phone && (
            <Link
              href={result?.phoneLink ? result?.phoneLink : `tel:${result?.phone}`}
              target="_blank"
              rel="nofollow"
              className={classes.linkFlex}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={trackDocSearchOutboundLinkClick({
                doctor_name: result.title || '',
                outboundTarget: result?.phoneLink ? result?.phoneLink : `tel:${result?.phone}`,
                type: 'phone',
                clickHandler: (e: any) => e.stopPropagation(),
              })}
            >
              {isHovering ? <SolidPhoneIcon className={classes.icon} /> : <PhoneIcon className={classes.icon} />}
            </Link>
          )}

          {result?.website && (
            <Link
              href={result.website.toLowerCase()}
              target="_blank"
              rel="nofollow"
              className={classes.linkFlex}
              onMouseEnter={onMouseEnter1}
              onMouseLeave={onMouseLeave1}
              onClick={trackDocSearchOutboundLinkClick({
                doctor_name: result.title || '',
                outboundTarget: result.website.toLowerCase(),
                type: 'url',
                clickHandler: (e: any) => e.stopPropagation(),
              })}
            >
              {isHovering1 ? <SolidWebIcon className={classes.icon} /> : <WebIcon className={classes.icon} />}
            </Link>
          )}

          {result?.infos?.[0] ? (
            <Link
              href={openDirectionsLink}
              target="_blank"
              rel="nofollow"
              className={classes.linkFlex}
              onMouseEnter={onMouseEnter2}
              onMouseLeave={onMouseLeave2}
              onClick={trackDocSearchOutboundLinkClick({
                doctor_name: result.title || '',
                outboundTarget: openDirectionsLink,
                type: 'address',
                clickHandler: (e: any) => e.stopPropagation(),
              })}
            >
              {isHovering2 ? <SolidRouteIcon className={classes.icon} /> : <RouteIcon className={classes.icon} />}
            </Link>
          ) : null}
        </div>

        {result?.appointment_url ? (
          <Link
            href={result?.appointment_url}
            target="_blank"
            rel="nofollow"
            className={classes.appointment_url}
            onClick={trackDocSearchOutboundLinkClick({
              doctor_name: result.title || '',
              outboundTarget: result?.appointment_url,
              type: 'book_appointment',
              clickHandler: (e: any) => e.stopPropagation(),
            })}
          >
            <button className={classes.btn}>
              <Typography component="p" className={classes.btnText}>
                Termin buchen
              </Typography>
            </button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default SearchItem
