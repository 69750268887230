import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {},
    pullerWrapper: {
      position: 'absolute',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      visibility: 'visible',
      right: 0,
      left: 0,
      padding: '0 35px 0 35px',
      boxShadow: '0px -5px 5px 2px rgba(200, 200, 200, 0.3)',
      backgroundColor: 'white',
    },
    content: {
      padding: '5px 35px 20px 35px',
      height: '100%',
      overflowY: 'scroll',
    },
    puller: {
      width: 37,
      height: 5,
      backgroundColor: theme.palette.beige.main,
      borderRadius: 3,
      margin: '20px auto 30px auto',
    },
  }),
  { name: 'DocFinderDrawer' }
)
