import useStyles from './DocFinderSearchList.styles'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import UpArrow from '../../../../../../public/icons/button-scroll-up-footer.svg'
import SearchItem from './components/SearchItem'
import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { DocSearchResultItem } from '../../../../../../@types/DocSearch'
import DocFinderFilters from '../DocFinderFilters'
import { FilterProps } from '../DocFinderFilters/DocFinderFilters'
import IconExpand from '../../../../../../public/icons/icon-expand.svg'

type Props = {
  results: Partial<DocSearchResultItem>[]
  resetResults: () => void
  numberOfClinics: number
  hoveredPinSlug: string
  clickedPinSlug: string
  onHoverPin: (slug: string) => void
  onClickPin: (slug: string) => void
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  filterProps: FilterProps
}

const DocFinderSearchList: React.FC<Props> = ({
  results,
  resetResults,
  numberOfClinics,
  clickedPinSlug,
  hoveredPinSlug,
  onHoverPin,
  onClickPin,
  setIsDrawerOpen,
  filterProps: { searchFilters, handleFilterChange, radius, setRadius, setPostalCode, onSearch, isSearching },
}) => {
  const classes = useStyles()

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const hasSidebar = useMediaQuery(theme.breakpoints.up('sm'))

  const [showFilters, setShowFilters] = useState(false)

  // Scroll
  useEffect(() => {
    if (results?.length > 0) {
      // Scroll THE WHOLE WINDOW up so that the map is fully visible
      const scrollToElement = document.getElementById('scroll-to-map')
      if (scrollToElement) {
        requestAnimationFrame(() =>
          window.scroll({
            top: isTablet ? scrollToElement.offsetTop - 100 : 0,
            behavior: 'smooth',
          })
        )
      }
      window.scrollTo({ top: 0, left: 0 })
    }

    if (clickedPinSlug === '' && results.length > 0) {
      // Scroll THE SEARCH RESULTS LIST to the top of its container
      const scrollParentMobile = document.getElementById('drawer-content')
      const scrollParentSidebar = document.getElementById('sidebarArea')
      try {
        scrollParentMobile?.scrollTo(0, 0)
        scrollParentSidebar?.scrollTo(0, 0)
      } catch (e) {
        console.warn(e)
      }
    }

    if (clickedPinSlug !== '') {
      // Scroll THE SEARCH RESULTS LIST to have the clicked result IN VIEW
      const itemElement = document.getElementById(clickedPinSlug)
      const listElement = document.getElementById('sidebarArea')
      if (hasSidebar && listElement && itemElement) {
        const offset = isTablet ? 500 : 300
        requestAnimationFrame(() =>
          listElement?.scroll({
            top: Number(itemElement.offsetTop) - offset,
            behavior: 'smooth',
          })
        )
      }
    }
  }, [clickedPinSlug, results])

  const handleGoBack = () => {
    onClickPin('')
    resetResults()
  }

  return (
    <div className={classes.root}>
      <button className={classes.topBtn} onClick={handleGoBack}>
        <UpArrow className={classes.upArrow} />
        Suche ändern
      </button>

      <div className={classes.filterToggle} onClick={() => setShowFilters((prev) => !prev)}>
        <div>{showFilters ? 'Filter ausblenden' : 'Filter anzeigen'}</div>
        <IconExpand className={clsx(classes.expandIcon, { [classes.open]: showFilters })} />
      </div>

      {showFilters && (
        <DocFinderFilters
          searchFilters={searchFilters}
          handleFilterChange={handleFilterChange}
          radius={radius}
          setRadius={setRadius}
          setPostalCode={setPostalCode}
          onSearch={onSearch}
          isSearching={isSearching}
          hideQuickLinks
        />
      )}

      <Typography component="p" className={classes.results}>
        {`Suchergebnisse A-Z (${numberOfClinics})`}
      </Typography>

      <div className={classes.itemsWrap}>
        {results?.map((result: Partial<DocSearchResultItem>, index: number) => {
          const isActive = Boolean(result?.slug === hoveredPinSlug || result?.slug === clickedPinSlug)

          return (
            <div id={result?.slug} key={`${result?.slug}_${index}`} style={{ display: 'flex' }}>
              <SearchItem
                active={isActive}
                onHoverPin={onHoverPin}
                result={result}
                onClickPin={onClickPin}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DocFinderSearchList
