import React from 'react'
import { SwipeableDrawer, styled, Box } from '@material-ui/core'

import useStyles from './BottomSwipeableDrawer.styles'

type Props = {
  children: React.ReactNode
  drawer: boolean
  toggleDrawer: () => void
}

const BottomSwipeableDrawer: React.FC<Props> = ({ children, drawer, toggleDrawer }) => {
  const classes = useStyles()

  const drawerBleeding = 0

  const Puller = styled(Box)(({ theme }) => ({
    width: 37,
    height: 5,
    backgroundColor: theme.palette.beige.main,
    borderRadius: 3,
    position: 'absolute',
    top: 12,
    left: 'calc(50% - 18.5px)',
  }))

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={drawer}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      className={classes.root}
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawerPaper,
      }}
    >
      <Puller />
      {children}
    </SwipeableDrawer>
  )
}

export default BottomSwipeableDrawer
