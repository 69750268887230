import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme, { color?: string }>(
  (theme) => ({
    '@global': {
      '&$root': {
        background: 'red',
      },
    },
    root: {
      position: 'sticky',
      top: 0,
      marginBottom: theme.spacing(5),
      backgroundColor: 'white',
      transition: 'all  225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      zIndex: 1000,

      '&::-webkit-scrollbar': {
        display: 'none',
      },

      '&.push': {
        top: 64,

        [theme.breakpoints.up('sm')]: {
          top: 70,
        },
        [theme.breakpoints.up('lg')]: {
          top: 135,
        },
      },
    },

    content: {
      borderColor: '#ccc',
      borderStyle: 'solid',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      WebkitOverflowScrolling: 'touch',
      position: 'relative',
      scrollBehavior: 'smooth',

      '&::-webkit-scrollbar': {
        display: 'none',
      },

      [theme.breakpoints.up('lg')]: {
        overflow: 'hidden',
        position: 'unset',
      },
    },

    asNav: {
      zIndex: 11,
      marginLeft: 32,
      marginRight: 32,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 64,
        marginRight: 64,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1276,
        marginLeft: 82,
        marginRight: 82,
      },
      ['@media(min-width: 1440px)']: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0',
        marginBottom: '0',
      },
    },

    item: {
      display: 'inline-flex',
      minWidth: 132,
      textAlign: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      cursor: 'pointer',

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },

      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
    },

    itemLabel: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.22,
      letterSpacing: 1,
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: '#999999',

      '&.selected': {
        color: theme.palette.common.black,
      },

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: 1.4,
      },

      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.42,
      },
    },

    activeIndicator: {
      position: 'absolute',
      bottom: 0,
      height: 4,
      backgroundColor: (props) => (props?.color ? props.color : '#ccc'),
      width: 0,
      transition: theme.transitions.create('all'),

      [theme.breakpoints.up('sm')]: {
        height: 6,
      },

      [theme.breakpoints.up('lg')]: {
        // height: 8,
      },
    },
  }),
  { name: 'TabFilter' }
)
