import React from 'react'
import { useRef } from 'react'
import { ImageField } from '../../../@types/Fields'

import relativeDimension from '../../../utils/relativeDimension'

type Props = {
  className?: string
  pClassName?: string
  image: ImageField
  desktopImageCovered?: boolean
  factorOverwrite?: number
}

const sizes = [
  ['xs', 256],
  ['s', 512],
  ['m', 768],
  ['l', 1024],
  ['xl', 1280],
  ['xxl', 1440],
  ['xxxl', 1920],
  ['xxxxl', 2560],
]

const sizesHomeStage = [
  ['xs', 256],
  ['s', 512],
  ['m', 768],
  ['l', 800],
  ['xl', 1024],
  ['xxl', 1280],
  ['xxxl', 1440],
  ['xxxxl', 2560],
]

const ResponsiveImage: React.FC<Props> = ({
  className,
  image,
  pClassName,
  desktopImageCovered,
  factorOverwrite = 1, // = 1 Will make it active by default. Remove "= 1" in order to move back to on demand
}) => {
  const ref = useRef(null)
  const { width, refWidth } = relativeDimension(ref)
  const calculatedFactor = width && refWidth ? width / refWidth : 1
  const factor = factorOverwrite || calculatedFactor

  if (!image) return null

  const looseSizes: any = image?.sizes

  return (
    <picture className={pClassName}>
      {desktopImageCovered
        ? sizesHomeStage?.map((item: any, index: number) => (
            <source key={index} media={`(max-width: ${item[1] * factor}px)`} srcSet={looseSizes[item[0]].url} />
          ))
        : sizes?.map((item: any, index: number) => (
            <source key={index} media={`(max-width: ${item[1] * factor}px)`} srcSet={looseSizes[item[0]].url} />
          ))}

      <img
        ref={ref}
        className={className}
        src={image?.sizes?.l?.url}
        alt={image.alt}
        width={image?.width + 'px'}
        height={image?.height + 'px'}
      />
    </picture>
  )
}

export default ResponsiveImage
