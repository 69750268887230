import { DownloadListFields } from '../../../@types/Section/DownloadList'

import Module from '../../components/Module'

import DownloadAccordion from './components/DownloadAccordion'

import useStyles from './DownloadList.styles'

type Props = {
  data: DownloadListFields
}

const DownloadList: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  return (
    <Module id={data.anchor_id} label={data.label} title={data.headline} classNames={{ title: classes.title }}>
      <div className={classes.root}>
        {data.list.map((item, index) => (
          <DownloadAccordion data={item} key={`download_item_${index}`} />
        ))}
      </div>
    </Module>
  )
}

export default DownloadList
