import { makeStyles, styled } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({}))

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: '20px',
      backgroundColor: '#f3f3f3',
      '&:hover': {
        backgroundColor: '#000000',
      },
      '&:hover .MuiTypography-root': {
        color: '#fff',
      },

      '&:hover svg path': {
        fill: '#fff',
      },

      '&:hover button': {
        border: '1px solid #fff',
        backgroundColor: '#000',
      },
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.up('lg')]: {},
    },

    active: {
      backgroundColor: '#000000',
      '& .MuiTypography-root': {
        color: '#fff',
      },
      '& svg path': {
        fill: '#fff',
      },
      '& button': {
        border: '1px solid #fff',
        backgroundColor: '#000',
      },
    },

    title: {
      maxWidth: 250,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 'bold',
      marginBottom: 12,
    },

    subtitle: {
      fontSize: 10,
      lineHeight: '16px',
      marginBottom: 16,
      maxWidth: 250,
    },

    optionsWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    iconWrap: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 15,
    },

    icon: {
      width: 29,
    },

    appointment_url: {
      marginLeft: 15,
    },

    btn: {
      border: '1px solid #000',
      padding: '8px 15px 8px 16px',
      maxWidth: 118,
      borderRadius: '40px',
    },

    btnText: {
      fontSize: 12,
      lineHeight: '12px',
    },

    linkFlex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'SearchItem' }
)
