import React, { useState } from 'react'
import { styled } from '@material-ui/styles'
import {
  Typography,
  TextField,
  FormControl,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { RadioProps } from '@material-ui/core/Radio'
import clsx from 'clsx'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContactFormFields } from '../../../../../@types/Section/Contact'

import { sendMail } from '../../../../../utils/api'

import BaseButton from '../../../BaseButton'
import BaseSelect from '../../../BaseSelect'

import XIcon from '../../../../../public/icons/icon-close.svg'

import useStyles from './Form.styles'
import validation from './Form.validation'

type FormProps = {
  salutation: string
  firstName: string
  lastName: string
  email: string
  phone?: string
  subject: string
  message: string
}

type Props = {
  data: ContactFormFields
  name?: string
}

const Form: React.FC<Props> = ({ data = {}, name }) => {
  const classes = useStyles()

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const [success, setSuccess] = useState<boolean>(false)

  const onSubmit = async (data: FormProps) => {
    try {
      await sendMail(data)

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      /** Google Analytics Event */
      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({ event: 'Kontaktanfrage' })
      }

      /** Matomo */
      if (window) {
        window._mtm = window._mtm || []
        window._mtm.push({
          event: 'contact_form',
          category: 'contact_form',
          action: 'Nachricht abschicken',
          label: 'https://merz-aesthetics.de/service/kontakt',
        })
      }

      setSuccess(true)
    } catch (err) {
      console.log(`err`, err)
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(validation),
  })

  const Square = styled('span')(() => ({
    width: 22,
    height: 22,
    marginRight: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
  }))

  const CustomRadio = (props: RadioProps) => {
    return (
      <Radio
        color="default"
        disableRipple={false}
        checkedIcon={
          <Square>
            <XIcon width={14} height={14} />
          </Square>
        }
        icon={<Square />}
        classes={{
          root: classes.radio,
        }}
        {...props}
      />
    )
  }

  return (
    <>
      {!success && (
        <form name={name} className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.section}>
            <Typography className={clsx(classes.category, classes.pb1)}>Anrede</Typography>
            <Controller
              name="salutation"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  defaultValue=""
                  aria-label="salutation"
                  name="salutation"
                  row
                  data-matomo-name="salutation"
                >
                  <FormControlLabel
                    value="Frau"
                    control={<CustomRadio />}
                    label="Frau"
                    classes={{ root: classes.formControlLabel, label: classes.controlLabel }}
                  />
                  <FormControlLabel
                    value="Herr"
                    control={<CustomRadio />}
                    label="Herr"
                    classes={{ root: classes.formControlLabel, label: classes.controlLabel }}
                  />
                </RadioGroup>
              )}
            />
          </div>

          <div className={classes.section}>
            <Typography className={clsx(classes.category, classes.pb2)}>Persönliche Daten</Typography>
            <div className={classes.grid}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.firstName)}
                      data-matomo-name="firstName"
                      fullWidth
                      variant="outlined"
                      label="Vorname"
                      required
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        className: clsx(classes.inputLabel, classes.label),
                      }}
                    />
                  )}
                />
                <Typography className={classes.errorMessage}>{errors?.firstName?.message}</Typography>
              </FormControl>

              <FormControl className={classes.formControl}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.lastName)}
                      fullWidth
                      variant="outlined"
                      label="Nachname"
                      data-matomo-name="lastName"
                      required
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        className: clsx(classes.inputLabel, classes.label),
                      }}
                    />
                  )}
                />

                <Typography className={classes.errorMessage}>{errors?.lastName?.message}</Typography>
              </FormControl>

              <FormControl className={classes.formControl}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.email)}
                      fullWidth
                      variant="outlined"
                      label="eMail-Adresse"
                      data-matomo-name="email"
                      required
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        className: clsx(classes.inputLabel, classes.label),
                      }}
                    />
                  )}
                />

                <Typography className={classes.errorMessage}>{errors?.email?.message}</Typography>
              </FormControl>

              <FormControl className={classes.formControl}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Telefonnummer"
                      data-matomo-name="phone"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        className: clsx(classes.inputLabel, classes.label),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className={classes.section}>
            <Typography className={clsx(classes.category, classes.pb2)}>Deine Nachricht</Typography>
            <div className={classes.grid}>
              <FormControl fullWidth>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => (
                    <BaseSelect
                      {...field}
                      onChange={(data) => field.onChange(data.value)}
                      label="betreff"
                      data-matomo-name="subject"
                      options={data.topics || []}
                      value={field?.value || ''}
                      required={true}
                      error={Boolean(errors?.subject)}
                    />
                  )}
                />

                <Typography className={classes.errorMessage}>{errors?.subject?.message}</Typography>
              </FormControl>
            </div>
            <FormControl fullWidth className={classes.textareaWrapper}>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    data-matomo-name="message"
                    placeholder="DEINE NACHRICHT*"
                    className={clsx(classes.textarea, {
                      [classes.textareaError]: Boolean(errors.message),
                    })}
                  />
                )}
              />

              <Typography className={classes.errorMessage}>{errors?.message?.message}</Typography>
            </FormControl>

            <BaseButton
              type="primary"
              label="Nachricht abschicken"
              onClick={handleSubmit(onSubmit)}
              fullWidth={isMobile}
            />

            <div style={{ height: 16 }} />

            <Typography className={classes.hint}>* Pflichtfelder</Typography>
            <Typography className={classes.hint} dangerouslySetInnerHTML={{ __html: data.privacy || '' }} />
          </div>
        </form>
      )}

      {success && (
        <div className={classes.successMessage}>
          <p>
            <strong>Ihre Nachricht wurde versendet!</strong>
          </p>
          <p>Vielen Dank für deine Mitteilung. Sie wurde versandt.</p>
        </div>
      )}
    </>
  )
}

export default Form
