import { Container, Typography } from '@material-ui/core'

import clsx from 'clsx'

import { PageProps, PageType, Section, PageData } from '../../../@types/Page'
import { PageSettings } from '../../../@types/PageSettings'
import { Slider } from '../../../@types/Slider'
import { StandardStageFields } from '../../../@types/Section/StandardStage'
import { CampaignStageFields } from '../../../@types/Section/CampaignStage'
import { TextHighlightFields } from '../../../@types/Section/TextHighlight'
import { AccordionFields } from '../../../@types/Section/Accordion'
import { VideoFields } from '../../../@types/Section/Video'
import { CtaTeaser01Fields } from '../../../@types/Section/CtaTeaser01'
import { RelatedTopicsFields } from '../../../@types/Section/RelatedTopics'
import { ComparsionFields } from '../../../@types/Section/Comparsion'
import { DownloadsFields } from '../../../@types/Section/Downloads'
import { BlogTileFields } from '../../../@types/Section/BlogTiles'
import { BlogStageFields } from '../../../@types/Section/BlogStage'
import { TextImageFields, TextImageMode } from '../../../@types/Section/TextImage'
import { TextFields } from '../../../@types/Section/Text'
import { DocSearchFields } from '../../../@types/DocSearch'
import { ContactFields } from '../../../@types/Section/Contact'
import { HotspotImageFields } from '../../../@types/Section/HotspotImage'
import { ZoomImageFields } from '../../../@types/Section/ZoomImage'
import { ImageGalleryFields } from '../../../@types/Section/ImageGallery'
import { SocialMediaFields } from '../../../@types/Section/SocialMedia'
import { ImageSliderFields } from '../../../@types/Section/ImageSlider'
import { DownloadListFields } from '../../../@types/Section/DownloadList'
import { VideoListFields } from '../../../@types/Section/VideoList'
import { AnchorNav } from '../../../@types/Section/AnchorNav'
import { VideoColorBgFields } from '../../../@types/Section/VideoColorBg'
import { SectionWithCopyBlockFields } from '../../../@types/Section/SectionWithCopyBlock'

import Module from '../Module'
import HomeStage from '../HomeStage'
import StandardStage from '../StandardStage'
import CampaignStage from '../CampaignStage'
import TextHighlight from '../TextHightlight'
import Accordion from '../Accordion'
import CTATeaser from '../CTATeaser'
import Comparison from '../Comparison'
import Video from '../Video'
import Download from '../Download'
import BlogTiles from '../BlogTiles'
import BlogStage from '../BlogStage'
import TextImage from '../TextImage'
import Text from '../Text'
import TextImageLandscape from '../TextImageLandscape'
import ZoomImage from '../ZoomImage'
import SocialMedia from '../SocialMedia'
import DownloadList from '../DownloadList'
import VideoList from '../VideoList'
import AnchorNavigation from '../AnchorNavigation'
import TabNavigation from '../TabNavigation'
import VideoColorBg from '../VideoColorBg'
import SliderWithCopyBlock from '../SliderWithCopyBlock'

import ApplicationModule from './modules/ApplicationModule'
import RelatedTopicsModule from './modules/RelatedTopicsModule'
import ImageGaleryModule from './modules/ImageGaleryModule'
import ImageSliderModule from './modules/ImageSliderModule'

import useStyles from './Content.styles'
import DocFinder from '../DocFinder'
import ContactForm from '../ContactForm'
import { TabNavigationFields } from '../../../@types/Section/TabNavigation'

const debug = false

const modules: Record<
  string,
  (key: string, fields: any, invert: boolean, index: number, settings?: PageSettings, content?: PageData) => void
> = {
  home_stage: (key: string, fields: Slider, invert: boolean, index: number) => <HomeStage key={key} data={fields} />,
  standard_stage: (key: string, fields: StandardStageFields, invert: boolean, index: number) => (
    <Container key={key + index} maxWidth="lg">
      <StandardStage key={key + index} data={fields} />
    </Container>
  ),
  text_image: (key: string, fields: TextImageFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      {fields.mode === TextImageMode.Portrait && <TextImage data={fields} invert={invert} />}
      {fields.mode === TextImageMode.Landscape && <TextImageLandscape data={fields} invert={invert} />}
    </Container>
  ),
  image: (key: string, fields: TextImageFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <TextImageLandscape data={fields} />
    </Container>
  ),
  text: (key: string, fields: TextFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <Text data={fields} invert={invert} />
    </Container>
  ),
  text_highlight: (key: string, fields: TextHighlightFields, invert: boolean, index: number) => (
    <Container
      id={fields?.anchor_id || undefined}
      key={key + index}
      maxWidth="lg"
      style={{
        backgroundColor: (fields?.color_overwrite?.invert_module_color && fields?.color_overwrite?.color) || undefined,
      }}
    >
      {debug && <p>{key}</p>}
      <TextHighlight data={fields} invert={invert} />
    </Container>
  ),
  accordion: (key: string, fields: AccordionFields, invert: boolean, index: number) => (
    <Container id={fields.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <Module label={fields.info} title={fields.title}>
        <Accordion data={fields?.entries || []} colorOverwrite={fields?.color_overwrite} />
      </Module>
    </Container>
  ),
  video: (key: string, fields: VideoFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <Video data={fields} invert={invert} />
    </Container>
  ),
  cta_teaser_01: (key: string, fields: CtaTeaser01Fields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <CTATeaser data={fields} />
    </Container>
  ),
  related_topics: (key: string, fields: RelatedTopicsFields, invert: boolean, index: number) => (
    <RelatedTopicsModule key={key + index} fields={fields} invert={invert} />
  ),
  downloads: (key: string, fields: DownloadsFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <Module title={fields.headline} label={fields.label}>
        <Download data={fields} />
      </Module>
    </Container>
  ),
  comparsion: (key: string, fields: ComparsionFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <Comparison data={fields} />
    </Container>
  ),
  blog_tiles: (
    key: string,
    fields: BlogTileFields,
    invert: boolean,
    index: number,
    settings?: PageSettings,
    content?: PageData
  ) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <BlogTiles data={fields} filter={content?.tabfilter} />
    </Container>
  ),
  blog_stage: (key: string, fields: BlogStageFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <BlogStage data={fields} />
    </Container>
  ),
  doc_search: (key: string, fields: DocSearchFields, invert: boolean, index: number, settings?: PageSettings) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <DocFinder data={fields} mapsKey={settings?.mapsKey} />
    </Container>
  ),
  contact: (key: string, fields: ContactFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <ContactForm data={fields} />
    </Container>
  ),
  hotspot_image: (key: string, fields: HotspotImageFields, index) => (
    <ApplicationModule key={key + index} data={fields} />
  ),
  campaign_stage: (key: string, fields: CampaignStageFields, invert: boolean, index: number) => (
    <CampaignStage key={key + index} data={fields} invert={invert} />
  ),
  zoom_image: (key: string, fields: ZoomImageFields, invert: boolean, index: number) => (
    <Container id={fields?.anchor_id || undefined} key={key + index} maxWidth="lg">
      {debug && <p>{key}</p>}
      <ZoomImage data={fields} invert={invert} />
    </Container>
  ),
  image_gallery: (key: string, fields: ImageGalleryFields, invert: boolean, index: number) => (
    <ImageGaleryModule key={key + index} fields={fields} invert={invert} />
  ),
  social_media: (key: string, fields: SocialMediaFields, invert: boolean, index: number) => {
    return (
      <Container key={key + index} maxWidth="lg">
        {debug && <p>{key}</p>}
        <SocialMedia data={fields} invert={invert} />
      </Container>
    )
  },
  image_slider: (key: string, fields: ImageSliderFields, invert: boolean, index: number) => (
    <ImageSliderModule key={key + index} data={fields} invert={invert} />
  ),
  download_list: (key: string, fields: DownloadListFields, invert: boolean, index: number) => (
    <Container key={key + index} maxWidth="lg">
      <DownloadList data={fields} />
    </Container>
  ),
  video_list: (key: string, fields: VideoListFields, invert: boolean, index: number) => (
    <Container key={key + index} maxWidth="lg">
      <VideoList data={fields} />
    </Container>
  ),
  anchor_navigation: (key: string, fields: AnchorNav, invert: boolean, index: number) => (
    <AnchorNavigation key={key + index} data={fields} />
  ),
  tab_navigation: (key: string, fields: TabNavigationFields, invert: boolean, index: number) => (
    <TabNavigation key={key + index} data={fields} />
  ),
  video_color_bg: (key: string, fields: VideoColorBgFields, invert: boolean, index: number) => (
    <VideoColorBg key={key + index} data={fields} />
  ),
  slider_with_copyblock: (key: string, fields: SectionWithCopyBlockFields, invert: boolean, index: number) => (
    <SliderWithCopyBlock key={key + index} data={fields} />
  ),
}

const Content: React.FC<PageProps> = ({ settings, content }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.topMargin]: content?.type !== PageType.Home && content?.type !== PageType.Campaign,
      })}
    >
      {content?.sections?.map((section: Section, index: number) => {
        const sectionType = section.type.toLowerCase()

        return modules[sectionType] ? (
          modules[sectionType]?.(
            sectionType,
            section.fields,
            content?.type === PageType.Campaign,
            index,
            settings,
            content
          )
        ) : (
          <Typography key={sectionType + index}>{sectionType}</Typography>
        )
      })}
    </div>
  )
}

export default Content
