import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      overflow: 'hidden',
      maxWidth: '100%',
      background: 'transparent',

      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    image: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      display: 'block',
      position: 'relative',
      top: 0,
    },
  }),
  { name: 'ZoomImage' }
)
