import React from 'react'
import { Link } from '@material-ui/core'
import clsx from 'clsx'

import { SocialMediaFields, SocialMediaPost } from '../../../@types/Section/SocialMedia'

import Module from '../Module'
import Socials from '../Socials'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import useStyles from './SocialMedia.styles'

type Props = {
  data: SocialMediaFields
  invert?: boolean
}

const SocialMedia: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()

  const socialData = data?.links?.length > 0

  const posts: SocialMediaPost[] = data?.posts || []

  return (
    <div id={data.anchor_id || undefined} className={classes.root}>
      <Module label={data.label} title={data.headline} invert={invert}>
        {socialData && (
          <Socials
            classes={{
              root: classes.socialsRoot,
              item: classes.socialItem,
            }}
            data={data.links}
            invert={!invert}
          />
        )}

        <div className={classes.feed}>
          <div className={clsx(classes.feedItem, classes.feedItemA)}>
            {posts?.[0] && (
              <Link href={posts?.[0]?.url} target="_blank">
                <div className={classes.feedImageWrap}>
                  <div className={classes.feedImage}>
                    <ResponsiveImage image={posts?.[0]?.image} />
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div className={clsx(classes.feedItem, classes.feedItemB)}>
            {posts?.[1] && (
              <Link href={posts?.[1]?.url} target="_blank">
                <div className={classes.feedImageWrap}>
                  <div className={classes.feedImage}>
                    <ResponsiveImage image={posts?.[1]?.image} />
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div className={clsx(classes.feedItem, classes.feedItemC)}>
            {posts?.[2] && (
              <Link href={posts?.[2]?.url} target="_blank">
                <div className={classes.feedImageWrap}>
                  <div className={classes.feedImage}>
                    <ResponsiveImage image={posts?.[2]?.image} />
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div className={clsx(classes.feedItem, classes.feedItemD)}>
            {posts?.[3] && (
              <Link href={posts?.[3]?.url} target="_blank">
                <div className={classes.feedImageWrap}>
                  <div className={classes.feedImage}>
                    <ResponsiveImage image={posts?.[3]?.image} />
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
      </Module>
    </div>
  )
}

export default SocialMedia
