import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Popover } from '@material-ui/core'
import IconExpand from '../../../../../../public/icons/icon-expand.svg'
import IconInfo from '../../../../../../public/icons/icon-info-black.svg'
import useStyles from './DocFinderFilters.styles'
import DistanceSlider from './components/DistanceSlider'
import Checkbox from './components/Checkbox'
import BaseButton from '../../../../BaseButton'
import { DocSearchFilterState } from '../../../../../../@types/DocSearch'

const links = [
  {
    name: 'Berlin',
  },
  {
    name: 'Düsseldorf',
  },
  {
    name: 'Frankfurt',
  },
  {
    name: 'Hamburg',
  },
  {
    name: 'Köln',
  },
  {
    name: 'München',
  },
  {
    name: 'Stuttgart',
  },
]

export type FilterProps = {
  searchFilters: DocSearchFilterState[]
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  radius: number
  setRadius: Dispatch<SetStateAction<number>>
  setPostalCode: (search: string) => void
  onSearch: () => void
  isSearching: boolean
  hideQuickLinks?: boolean
}

const DocFinderFilters: React.FC<FilterProps> = ({
  searchFilters,
  handleFilterChange,
  radius,
  setRadius,
  setPostalCode,
  onSearch,
  isSearching,
  hideQuickLinks,
}) => {
  const classes = useStyles()

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  const isPopoverOpen = Boolean(popoverAnchorEl)

  // Scroll THE FILTERS to top of drawer
  useEffect(() => {
    const resultsElement = document.getElementById('drawer-content')
    resultsElement?.scrollTo(0, 0)
  }, [])

  const renderArealCheckboxes = searchFilters
    ?.filter((item) => item.frontEndGroup === 'Behandlungsareal' && !item.isSelectingTheWholeGroup)
    ?.map((item: DocSearchFilterState, index: number) => (
      <div key={`${item.frontEndName}_${index}`}>
        <Checkbox onChange={handleFilterChange} checked={item.selected} value={item.frontEndName} color={item.color}>
          {item.frontEndName}
          {item.frontEndName === 'Gesicht' && (
            <IconInfo
              className={classes.iconInfo}
              aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          )}
        </Checkbox>
      </div>
    ))

  const renderProductCheckboxes = searchFilters
    ?.filter((item) => item.frontEndGroup === 'Produkte' && !item.isSelectingTheWholeGroup)
    ?.map((item: DocSearchFilterState, index: number) => (
      <div key={`${item?.frontEndName}_${index}`}>
        <Checkbox onChange={handleFilterChange} checked={item.selected} value={item.frontEndName} color={item.color}>
          {item?.frontEndName}
        </Checkbox>
      </div>
    ))

  const renderPraxisCheckboxes = searchFilters
    ?.filter((item) => item.frontEndGroup === 'Praxis' && !item.isSelectingTheWholeGroup)
    ?.map((item: DocSearchFilterState, index: number) => (
      <div key={`${item?.frontEndName}_${index}`}>
        <Checkbox onChange={handleFilterChange} checked={item.selected} value={item.frontEndName} color={item.color}>
          {item?.frontEndName}
        </Checkbox>
      </div>
    ))

  return (
    <div className={classes.root}>
      {!hideQuickLinks && (
        <div className={classes.quickLinksWrap}>
          <Typography component="p" className={classes.quickParagraph}>
            Quick Links:
          </Typography>

          <div className={classes.linksWrap}>
            {links.map((link) => (
              <Typography
                component="p"
                key={link.name}
                className={classes.link}
                onClick={() => setPostalCode(link.name)}
              >
                {link.name}
              </Typography>
            ))}
          </div>
        </div>
      )}

      <Accordion
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExpanded,
          rounded: classes.accordionRounded,
        }}
      >
        <AccordionSummary
          classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
          expandIcon={<IconExpand className={classes.icon} />}
        >
          <Typography component="h3" className={classes.title}>
            Umkreis
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <DistanceSlider radius={radius} setRadius={setRadius} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExpanded,
          rounded: classes.accordionRounded,
        }}
      >
        <AccordionSummary
          classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
          expandIcon={<IconExpand className={classes.icon} />}
        >
          <Typography component="h3" className={classes.title}>
            Behandlungsareal
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          {searchFilters
            ?.filter((item) => item.frontEndGroup === 'Behandlungsareal' && item.isSelectingTheWholeGroup)
            ?.map((item: DocSearchFilterState, index: number) => (
              <div key={`${item?.frontEndName}_${index}`}>
                <Checkbox
                  onChange={handleFilterChange}
                  checked={item.selected}
                  value={item.frontEndName}
                  color={item.color}
                >
                  {item?.frontEndName}
                </Checkbox>
              </div>
            ))}
          {renderArealCheckboxes}

          <Popover
            id="mouse-over-popover"
            open={isPopoverOpen}
            anchorEl={popoverAnchorEl}
            style={{ pointerEvents: 'none' }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className={classes.popover}>
              <Typography component="p" className={classes.details}>
                beinhaltet Stirnfalte, Zornesfalte, Augenringe, Kinn-Kiefer-Linie, Lippen, Raucherfalten,
                Marionettenfalten, Schläfen, seitliche Augenregion, Nasolabialfalten, Wangen, Mundwinkel, Kinn
              </Typography>
            </div>
          </Popover>
        </AccordionDetails>
      </Accordion>

      <Accordion
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExpanded,
          rounded: classes.accordionRounded,
        }}
      >
        <AccordionSummary
          classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
          expandIcon={<IconExpand className={classes.icon} />}
        >
          <Typography component="h3" className={classes.title}>
            Produkte
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          {searchFilters
            ?.filter((item) => item.frontEndGroup === 'Produkte' && item.isSelectingTheWholeGroup)
            ?.map((item: DocSearchFilterState, index: number) => (
              <div key={`${item?.frontEndName}_${index}`}>
                <Checkbox
                  onChange={handleFilterChange}
                  checked={item.selected}
                  value={item.frontEndName}
                  color={item.color}
                >
                  {item?.frontEndName}
                </Checkbox>
              </div>
            ))}
          <div className={classes.twoColumnCheckboxWrapper}>{renderProductCheckboxes}</div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExpanded,
          rounded: classes.accordionRounded,
        }}
      >
        <AccordionSummary
          classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
          expandIcon={<IconExpand className={classes.icon} />}
        >
          <Typography component="h3" className={classes.title}>
            Praxis
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>{renderPraxisCheckboxes}</AccordionDetails>
      </Accordion>
      <div className={classes.btnWrap}>
        <BaseButton
          classNames={classes.btn}
          label={hideQuickLinks ? 'Suche ändern' : 'Suche starten'}
          onClick={onSearch}
          disabled={isSearching}
        />
      </div>
    </div>
  )
}

export default DocFinderFilters
