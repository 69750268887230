import React, { useRef } from 'react'

import { Typography, Link, useMediaQuery, useTheme } from '@material-ui/core'

import NextLink from 'next/link'

import { BlogStageFields } from '../../../@types/Section/BlogStage'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import BackArrow from '../../../public/icons/icon-arrow-black.svg'
import IconPinterest from '../../../public/icons/icon-pinterest-white.svg'
import IconFacebook from '../../../public/icons/icon-facebook-white.svg'
import IconWhatsapp from '../../../public/icons/icon-whatsapp-white.svg'
import IconLinkedin from '../../../public/icons/icon-linked-in-white.svg'
import IconEmail from '../../../public/icons/icon-e-mail-black.svg'

import useStyles from './BlogStage.styles'
import AnkerLink from '../AnkerLink'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

type Props = {
  data: BlogStageFields
  onScrollToContent?: () => void
}

const BlogPage: React.FC<Props> = ({ data, onScrollToContent }) => {
  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const container = useRef<HTMLDivElement | null>(null)

  const overviewButton = data.overviewButton

  const currentPageUrl =
    (typeof window !== 'undefined' && window.location.href && encodeURI(window.location.href)) || ''

  const handleScrollToClick = () => {
    if (onScrollToContent) return onScrollToContent()

    requestAnimationFrame(() => {
      const sibling = container?.current?.parentNode?.nextSibling as HTMLElement | null | undefined

      sibling?.scrollIntoView?.({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    })
  }

  const hasSpacialCharacter = new RegExp(/[®]+/).test(data?.title || '')

  const pageTitle = hasSpacialCharacter
    ? (data?.title || '').replace(new RegExp('®', 'g'), `<sup>®</sup>`)
    : data?.title || ''

  return (
    <div ref={container} className={classes.root}>
      <div className={classes.grid}>
        <div className={classes.back}>
          {overviewButton && (
            <NextLink href={overviewButton.target} passHref>
              <Link
                className={classes.backBtn}
                target={overviewButton.type === 'external' ? '_blank' : undefined}
                onClick={trackedLinkClick({
                  label: overviewButton.label,
                  href: overviewButton.target,
                  position: MotomoLinkTrackingPosition.BODY,
                })}
              >
                <BackArrow />
                <Typography component="p">{overviewButton.label}</Typography>
              </Link>
            </NextLink>
          )}
        </div>
        <div className={classes.main}>
          <Typography variant="h1" className={classes.title} dangerouslySetInnerHTML={{ __html: pageTitle }} />
          <Typography component="p" className={classes.description}>
            {data.copy}
          </Typography>
        </div>
        <div className={classes.image}>
          <div className={classes.scrollDownWrap}>
            <AnkerLink onClick={handleScrollToClick} />
          </div>
          <ResponsiveImage image={data?.[isDesktop ? 'portraitImage' : 'landscapeImage']} />
        </div>
        <div className={classes.category}>
          <Typography component="span" className={classes.infoText}>
            Kategorie
          </Typography>
          <Typography component="span" className={classes.infoText}>
            <i>{data.category}</i>
          </Typography>
        </div>
        <div className={classes.date}>
          <Typography component="span" className={classes.infoText}>
            Datum
          </Typography>
          <Typography component="span" className={classes.infoText}>
            <i>{data.date}</i>
          </Typography>
        </div>
        <div className={classes.socials}>
          <Link
            href={`https://pinterest.com/pin/create/button/?url=${currentPageUrl}`}
            target="_blank"
            rel="nofollow"
            onClick={trackedLinkClick({
              label: 'blog stage Pinterest icon',
              href: `https://pinterest.com/pin/create/button/?url=${currentPageUrl}`,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <IconPinterest width={32} height={32} />
          </Link>

          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`}
            target="_blank"
            rel="nofollow"
            onClick={trackedLinkClick({
              label: 'blog stage facebook icon',
              href: `https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <IconFacebook width={32} height={32} />
          </Link>

          <Link
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentPageUrl}`}
            target="_blank"
            rel="nofollow"
            onClick={trackedLinkClick({
              label: 'blog stage linkedin icon',
              href: `https://www.linkedin.com/sharing/share-offsite/?url=${currentPageUrl}`,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <IconLinkedin width={32} height={32} />
          </Link>

          <Link
            href={`mailto:?&subject=&body=${currentPageUrl}`}
            target="_blank"
            rel="nofollow"
            onClick={trackedLinkClick({
              label: 'blog stage mail icon',
              href: `mailto:?&subject=&body=${currentPageUrl}`,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <IconEmail width={32} height={32} />
          </Link>

          <Link
            href={`https://api.whatsapp.com/send?text=%0a${currentPageUrl}`}
            target="_blank"
            rel="nofollow"
            onClick={trackedLinkClick({
              label: 'blog stage whatsapp icon',
              href: `https://api.whatsapp.com/send?text=%0a${currentPageUrl}`,
              position: MotomoLinkTrackingPosition.BODY,
            })}
          >
            <IconWhatsapp width={32} height={32} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BlogPage
