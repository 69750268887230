import React from 'react'
import { Typography, Grid, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import theme from '../../theme'

import useStyles from './TextImage.styles'
import EditorText from '../EditorText'
import { TextImageFields } from '../../../@types/Section/TextImage'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

type Props = {
  data: TextImageFields
  invert?: boolean
}

const TextImage: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isMedium = useMediaQuery(theme.breakpoints.up('md')) && !isLarge
  return (
    <div className={clsx(data.label ? classes.rootHasLabel : classes.root, { [classes.invert]: invert })}>
      {data.label && (
        <Typography className={clsx(classes.label, { [classes.invert]: invert })}>{data.label}</Typography>
      )}
      <Grid container className={classes.container}>
        {isMedium && <Grid item md={2}></Grid>}
        <Grid item xs={12} md={8} lg={3}>
          <ResponsiveImage className={data.label ? classes.image : classes.imageNoLabel} image={data.image} />
          <Typography className={clsx(classes.caption, { [classes.invert]: invert })}>{data.caption_title}</Typography>
          <Typography className={clsx(classes.description, { [classes.invert]: invert })}>
            {data.caption_copy}
          </Typography>
        </Grid>
        {theme.breakpoints.up('lg') && <Grid item lg={1}></Grid>}
        <Grid item xs={12} lg={8}>
          {data.copy && (
            <EditorText
              className={data.label ? 'has-label ' + classes.content : classes.content}
              content={data.copy}
              invert={invert}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default TextImage
