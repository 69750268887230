import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      '& .MuiPaper-root': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingTop: 42,
        paddingBottom: 42,
        top: 40,
        [theme.breakpoints.up('sm')]: {
          top: 80,
        },
      },
    },
    drawerRoot: {
      '& $drawerPaper': {
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 0,
        },
      },
    },
    drawerPaper: {},
  }),
  { name: 'BottomSwipeableDrawer' }
)
