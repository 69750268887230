import { useEffect, useMemo, useState } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'

import { useDebouncedCallback } from 'use-debounce'

import { TabNavigationFields } from '../../../@types/Section/TabNavigation'
import TabFilter from '../TabFilter'

import useTabNavigationActiveTab from '../../hooks/useTabNavigationActiveTab'

type Props = {
  data: TabNavigationFields
}

const isInViewport = (element: HTMLElement | null) => {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom / 1.5 <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const TabNavigation: React.FC<Props> = ({ data }) => {
  const [selected, setSelected] = useState(0)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  /**
   * TEMP: Disabling this as it does not work in a consistent way and breaks the active tab
   * indicator
   */
  // const activeTabBasedOnScrolPosition = useTabNavigationActiveTab(data?.tab_links)

  const findAndIndicateActiveTab = useDebouncedCallback(() => {
    const tabs = [...data?.tab_links]

    const visibleElements = tabs.map((item) => {
      const el = document.getElementById(item.target_id)

      return isInViewport(el)
    })

    const activeTab = visibleElements.indexOf(true)

    setSelected(activeTab)
  }, 100)

  useEffect(() => {
    window.addEventListener('scroll', findAndIndicateActiveTab)

    return () => window.removeEventListener('scroll', findAndIndicateActiveTab)
  }, [])

  useEffect(() => {
    const dataForActiveTab = data.tab_links[selected]

    if (dataForActiveTab && !isDesktop) {
      const activeTabElementContainer = document.getElementById('tab_filter_content')
      const activeTabElement = document.querySelector(`[data-id=${dataForActiveTab.target_id}]`) as HTMLElement

      activeTabElementContainer?.scrollTo(activeTabElement?.offsetLeft || 0, 0)
    }
  }, [selected, isDesktop])

  const items = useMemo(
    () => data.tab_links.map((item) => ({ id: item.target_id, title: item.label, category: null })),
    [data.tab_links]
  )

  const tabTitles = useMemo(() => items.map((item) => item.title), [items])

  const handleSelect = (title: string, id?: string) => {
    if (!id) return

    const targetElement = document.getElementById(id)

    if (targetElement) {
      const topOffset = 82

      const targetElementYPosition = (targetElement?.offsetTop || 0) - topOffset

      requestAnimationFrame(() => {
        window.scrollTo(0, targetElementYPosition)
      })

      setSelected(tabTitles.indexOf(title) || 0)
    }
  }

  return (
    <TabFilter onClick={handleSelect} selected={selected} items={items} colorOverwrite={data?.color_overwrite} asNav />
  )
}

export default TabNavigation
