import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme, { color?: string }>(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      flex: 1,
      border: 0,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      borderColor: '#cccccc',
      borderStyle: 'solid',
      paddingLeft: 15,
      paddingRight: 15,
      display: 'flex',
      flexDirection: 'column',

      '&$invert': {
        borderColor: theme.palette.beige.main,
        backgroundColor: 'initial',
      },
    },

    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    row: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 8,
      [theme.breakpoints.up('lg')]: {
        paddingTop: 24,
        paddingBottom: 18,
      },
    },

    category: {
      fontFamily: 'ProximaNova-LightIt',
      fontSize: '12px',
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#868686',
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.beige.main,
      },
    },

    date: {
      fontFamily: 'ProximaNova-LightIt',
      fontSize: '12px',
      fontWeight: 300,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#868686',
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.beige.main,
      },
    },

    dash: {
      height: 1,
      backgroundColor: '#868686',
      width: 24,
      marginLeft: 15,
      marginRight: 15,

      '&$invert': {
        backgroundColor: theme.palette.beige.main,
      },
    },

    image: {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      // height: 182,
      // objectFit: 'cover',
      // objectPosition: 'center',
      // [theme.breakpoints.up('sm')]: {
      //   height: 300,
      // },
      // [theme.breakpoints.up('lg')]: {
      //   height: 244,
      // },
    },

    related: {
      // [theme.breakpoints.up('sm')]: {
      //   height: '204px !important',
      // },
      // [theme.breakpoints.up('lg')]: {
      //   height: '381px !important',
      // },
    },

    label: {
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '18px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.44,
      letterSpacing: '1.1px',
      color: theme.palette.common.black,
      marginBottom: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: 1.4,
        marginBottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
        lineHeight: 1.42,
      },
    },
    labelInverted: {
      color: theme.palette.common.white,
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderColor: (props) => (props?.color ? props.color : '#ccc'),
      borderWidth: '1px 0',
      borderStyle: 'solid',
      padding: '9px 0',
      '&:hover': {
        textDecoration: 'none',

        '& $arrowIcon': {
          marginLeft: 18,
        },
      },
    },
    linkInverted: {
      borderColor: '#fff',
    },

    linkLabel: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      color: theme.palette.common.black,
    },
    linkLabelInverted: {
      color: theme.palette.common.white,
    },

    arrowIcon: {
      width: 24,
      height: 24,
      fill: theme.palette.common.black,
      marginLeft: 9,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    arrowIconInverted: {
      fill: theme.palette.common.white,
    },

    invert: {},
  }),
  { name: 'BlogTile' }
)
