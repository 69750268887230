import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './Text.styles'
import EditorText from '../EditorText'
import { TextFields } from '../../../@types/Section/Text'

type Props = {
  data: TextFields
  invert?: boolean
}

const Text: React.FC<Props> = ({ data, invert }) => {
  const classes = useStyles()

  return (
    <div className={clsx(data.label ? classes.rootHasLabel : classes.root, { [classes.invert]: invert })}>
      {data.label && (
        <Typography className={clsx(classes.label, { [classes.invert]: invert })}>{data.label}</Typography>
      )}
      <Grid container>
        <Grid item xs={12} lg={4}></Grid>
        <Grid item xs={12} lg={8}>
          <EditorText
            className={data.label ? 'has-label ' + classes.content : classes.content}
            anchor_id={data?.anchor_id}
            content={data.copy}
            invert={invert}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Text
