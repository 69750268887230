import useStyles from './VideoColorBg.styles'
import { Typography, useMediaQuery, useTheme, Container } from '@material-ui/core'
import ReactPlayer from 'react-player'
import dynamic from 'next/dynamic'

import IconExpand from '../../../public/icons/icon-expand.svg'
import { VideoColorBgFields } from '../../../@types/Section/VideoColorBg'
import { useState } from 'react'

type Props = {
  data: VideoColorBgFields
}

const VideoColorBg: React.FC<Props> = ({ data }) => {
  const [playing, setPlaying] = useState(true)

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const styles = useStyles({
    color: data?.color_overwrite?.color,
    invertModuleColor: data?.color_overwrite?.invert_module_color,
    textColor: data?.color_overwrite?.text_color,
  })

  const handlePlay = () => setPlaying(true)

  const videoFileName = (data?.video_file || '').split('/').pop() || ''

  const [videoName] = videoFileName.split('.')

  const matomoTitle = data?.headline || videoName

  const content = (
    <div className={styles.root}>
      <div className={styles.video}>
        <ReactPlayer
          loop
          muted
          controls={true}
          playsinline={true}
          playing={playing}
          url={data.video_file}
          config={{
            file: {
              attributes: {
                poster: data.preview_image.sizes.xl.url,
                'data-matomo-title': matomoTitle,
              },
              forceVideo: false,
            },
          }}
          width="100%"
          height="100%"
        />
        {!playing && (
          <div className={styles.videoOverlay}>
            <Typography variant="h1" className={styles.videoTitle}>
              {data?.topline}
            </Typography>

            <div onClick={handlePlay} className={styles.playWrap}>
              <IconExpand className={styles.icon} />
            </div>
          </div>
        )}
      </div>

      <div className={styles.descriptionWrap}>
        <Typography className={styles.descriptionTitle}>{data?.headline}</Typography>
        <Typography className={styles.description} dangerouslySetInnerHTML={{ __html: data?.copy || '' }}></Typography>
      </div>
    </div>
  )

  if (isDesktop) return <Container maxWidth="lg">{content}</Container>

  return content
}

export default dynamic(() => Promise.resolve(VideoColorBg), { ssr: false })
