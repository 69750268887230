import { DocSearchFilterWithGroup } from '../../../../@types/DocSearch'

export const backendFilterIds = ['products', 'treatments', 'areas', 'category', 'features', 'specialty']

export const docSearchFilters: DocSearchFilterWithGroup[] = [
  /* Behandlungsareal */

  {
    frontEndName: 'Alle Behandlungsareale auswählen',
    frontEndGroup: 'Behandlungsareal',
    isSelectingTheWholeGroup: true,
  },
  {
    frontEndName: 'Gesicht',
    frontEndGroup: 'Behandlungsareal',
    isSelectingTheWholeGroup: false,
    areas: [
      'cheeks',
      'chin',
      'chin-jaw-line',
      'corner-of-the-mouth',
      'dark-circles-tear-troughs',
      'forehead-wrinkles',
      'frown-line',
      'lateral-eye-region',
      'lips',
      'marionette-folds',
      'nasolabial-folds',
      'smokers-lines',
      'temples',
      'zygomatic-arch',
    ],
  },
  {
    frontEndName: 'Hals & Dekolleté',
    frontEndGroup: 'Behandlungsareal',
    isSelectingTheWholeGroup: false,
    areas: ['cleavage', 'throat'],
  },
  {
    frontEndName: 'Hände',
    frontEndGroup: 'Behandlungsareal',
    isSelectingTheWholeGroup: false,
    products: [],
    treatments: [],
    areas: ['hands'],
  },

  /* Produkte */

  {
    frontEndName: 'Alle Produkte',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: true,
  },
  {
    frontEndName: 'Belotero®',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: false,
    color: '#f47c27',
    products: ['belotero'],
    id: 'belotero',
  },
  {
    frontEndName: 'Ultherapy®',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: false,
    color: '#f9b132',
    products: ['ultherapy'],
    id: 'ultherapy',
  },
  {
    frontEndName: 'Ultherapy Prime®',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: false,
    color: '#f3b43e',
    products: ['ultherapy-prime'],
    id: 'ultherapy-prime',
  },
  {
    frontEndName: 'Botulinumtoxin®',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: false,
    color: '#a72002',
    products: ['bocouture'],
    id: 'bocouture',
  },
  {
    frontEndName: 'Radiesse®',
    frontEndGroup: 'Produkte',
    isSelectingTheWholeGroup: false,
    color: '#00a5c6',
    products: ['radiesse'],
    id: 'radiesse',
  },

  /* Praxis */

  {
    frontEndName: 'Alle auswählen',
    frontEndGroup: 'Praxis',
    isSelectingTheWholeGroup: true,
  },
  {
    frontEndName: 'Privatpraxis',
    frontEndGroup: 'Praxis',
    isSelectingTheWholeGroup: false,
    category: ['private-practice'],
  },
  {
    frontEndName: 'Mit ÖPNV Anbindung',
    frontEndGroup: 'Praxis',
    isSelectingTheWholeGroup: false,
    features: ['pnv-connection'],
  },
  /*
  {
    frontEndName: 'Mit Parkmöglichkeiten',
    frontEndGroup: 'Praxis',
    isSelectingTheWholeGroup: false,
    features: ['parking-space'],
  },
  {
    frontEndName: 'Online Terminvereinbarung möglich',
    frontEndGroup: 'Praxis',
    isSelectingTheWholeGroup: false,
    features: ['online-appointment-booking'],
  },
  */
]
