import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 29,
      },
      [theme.breakpoints.up('lg')]: {
        alignItems: 'start',
      },
    },

    headerWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    formControl: {
      width: '100%',
      position: 'relative',

      [theme.breakpoints.up('sm')]: {
        maxWidth: 438,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 291,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '0 !important',
      },
      '& label.Mui-focused': {
        color: '#868686 !important',
      },
    },

    locationIcons: {
      width: 20,
      height: 20,
      transform: 'rotate(45deg)',
      position: 'absolute',
      right: 14,
      top: 17,
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: theme.palette.beige.light,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: 2.3,
      transform: 'translate(16px, 15px)',
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: 2,
      },
    },

    inputRoot: {
      width: '100%',
      height: 50,
      borderRadius: 0,
      '& fieldset': {
        borderWidth: '1px !important',
        borderColor: '#cccccc !important',
      },
      '&:hover fieldset': {
        borderWidth: '1px !important',
        borderColor: '#868686 !important',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px !important',
        borderColor: '#868686 !important',
      },
      '&.Mui-error fieldset': {
        borderWidth: '1px !important',
        borderColor: `${theme.palette.error.main} !important`,
      },
    },

    input: {
      padding: 14,
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
      },
    },
  }),
  { name: 'DocFinderHeader' }
)
