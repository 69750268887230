import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    rootHasLabel: {
      borderTopWidth: 1,
      borderTopColor: '#cccccc',
      borderTopStyle: 'solid',
      marginTop: 60,
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 100,
        marginBottom: 100,
      },
    },

    label: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.gray.main,
      textTransform: 'uppercase',
      paddingBottom: 24,
      paddingTop: 16,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
        paddingBottom: 40,
        paddingTop: 24,
        position: 'absolute',
      },

      '&$invert': {
        color: theme.palette.beige.main,
      },
    },

    container: {
      flexWrap: 'wrap-reverse',
      [theme.breakpoints.up('lg')]: {
        flexWrap: 'wrap',
      },
    },

    leftRow: {
      alignSelf: 'flex-end',
    },

    image: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginTop: -6,
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        marginTop: 24,
        marginBottom: 0,
      },
    },

    imageNoLabel: {
      width: '100%',
      height: 'auto',
      display: 'block',
      marginTop: 0,
      marginBottom: 10,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },

    caption: {
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,

      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    description: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 24px)',
        maxWidth: 290,
        fontSize: 14,
        lineHeight: 1.43,
      },

      '&$invert': {
        color: theme.palette.common.white,
      },
    },

    invert: {},
  }),
  { name: 'TextImageLandscape' }
)
