import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import { ImageSliderSlide } from '../../../@types/Section/ImageSlider'

import IconArrowLeft from '../../../public/icons/icon-left-arrow.svg'
import IconArrowRight from '../../../public/icons/icon-arrow.svg'

import padNumber from '../../helpers/padNumber'

import useStyles from './ImageSlider.styles'

type Props = {
  data: ImageSliderSlide[]
  onImageClick?: (index: number) => () => void
}

const transitionSpeed = 300

const ImageSliderDesktop: React.FC<Props> = ({ data, onImageClick }) => {
  const itemsLength = data.length

  const defaultStep = itemsLength //+ itemsLength * 0.5

  const sliderWrap = useRef<HTMLDivElement | null>(null)
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const [step, setStep] = useState<number>(defaultStep)
  const [enableTransitions, setEnableTransitions] = useState<boolean>(true)
  const [navDisabled, setNavDisabled] = useState<boolean>(false)

  const classes = useStyles()

  const removeHighlights = (callback?: () => void) => {
    const images = sliderRef.current?.querySelectorAll('img')

    images?.forEach((image) => {
      image.classList.remove('notVisible')
    })

    callback?.()
  }

  const highlightVisibleImages = () => {
    const wrapperWidth = sliderWrap.current?.offsetWidth || 0

    const images = sliderRef.current?.querySelectorAll('img')

    images?.forEach((image) => {
      const boundingRect = image.getBoundingClientRect()

      const xPosition = boundingRect.x

      const isImageVisible = xPosition > 0 && xPosition < wrapperWidth

      if (!isImageVisible) {
        image.classList.add('notVisible')
      }
    })
  }

  useEffect(() => {
    highlightVisibleImages()
  }, [])

  useEffect(() => {
    const shouldReset = step % itemsLength === 0

    if (shouldReset && step !== defaultStep) {
      setNavDisabled(true)
      setTimeout(() => {
        setEnableTransitions(false)

        setStep(defaultStep)
      }, transitionSpeed)
    }

    if (step === 1) {
      setNavDisabled(true)
      setTimeout(() => {
        setEnableTransitions(false)

        setStep(defaultStep + 1)
      }, transitionSpeed)
    }

    if (step === defaultStep || step === defaultStep + 1) {
      setTimeout(() => {
        setEnableTransitions(true)
      }, transitionSpeed)
    }

    setTimeout(() => {
      highlightVisibleImages()
    }, transitionSpeed)
  }, [step])

  useEffect(() => {
    if (navDisabled) {
      setTimeout(() => {
        setNavDisabled(false)
      }, transitionSpeed * 2)
    }
  }, [navDisabled])

  const handleNext = () => {
    removeHighlights()

    if (!navDisabled) {
      setStep(step + 1)
    }
  }

  const handlePrev = () => {
    removeHighlights()

    if (!navDisabled) {
      setStep(step - 1)
    }
  }

  const slidesList = useMemo(() => {
    const result = data.map((slide, index) => {
      const portraitImage = slide.format === 'portrait' && slide.image_p?.sizes.xs
      const landscapeImage = slide.format === 'landscape' && slide.image_l?.sizes.xs

      const image = portraitImage || landscapeImage

      return (
        <div className={classes.imageSliderDesktopSlideWrap} key={`slide_${index}`}>
          <div className={classes.imageSliderDesktopSlide} onClick={onImageClick?.(index)}>
            {image && <img className={classes.imageSliderDesktopSlideImage} src={image?.url || ''} width="100%" />}
            <Typography className={classes.imageSliderDesktopSlideIndicator}>{padNumber(index + 1)}</Typography>
          </div>
        </div>
      )
    })

    return result
  }, [data])

  return (
    <div ref={sliderWrap} className={classes.root}>
      <div className={clsx(classes.sliderControlWrap, classes.sliderControlWrapLeft)}>
        <div className={classes.sliderControlIcon} onClick={handlePrev}>
          <IconArrowLeft />
        </div>
      </div>
      {/* <div className={classes.imageSliderDesktopInner} style={{ transform: `translateX(-${step * 225}px)` }}> */}
      <div
        className={clsx(classes.imageSliderDesktopInner, {
          [classes.imageSliderDesktopInnerTransition]: enableTransitions,
        })}
        style={{ transform: `translateX(-${step * 360 - 90}px)` }}
        ref={sliderRef}
      >
        {slidesList}
        {slidesList}
        {slidesList}
      </div>
      <div className={clsx(classes.sliderControlWrap, classes.sliderControlWrapRight)}>
        <div className={classes.sliderControlIcon} onClick={handleNext}>
          <IconArrowRight />
        </div>
      </div>
    </div>
  )
}

export default ImageSliderDesktop
