import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'

import { DownloadListItem } from '../../../../../@types/Section/DownloadList'

import IconDownload from '../../../../../public/icons/icon-download.svg'
import IconView from '../../../../../public/icons/icon-view-black.svg'
import IconExternalLink from '../../../../../public/icons/icon-external-link-black.svg'

import ResponsiveImage from '../../../ResponsiveImage/ResponsiveImage'

import useStyles from './DownloadAccordion.styles'

type Props = {
  data: DownloadListItem
}

const DownloadAccordion: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  return (
    <Accordion
      classes={{
        root: classes.accordion,
        expanded: classes.accordionExpanded,
        rounded: classes.accordionRounded,
      }}
    >
      <AccordionSummary
        classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
      >
        <div className={classes.titleWrap}>
          <Typography component="h3" className={classes.title}>
            {data.title}
          </Typography>

          <div className={classes.iconContainer}>
            {data.url && data.link_type === 'external' && (
              <a href={data.url} target="_blank" onClick={(e) => e.stopPropagation()}>
                <IconExternalLink className="external" width="40" height="40" />
              </a>
            )}

            {data.file && data.link_type === 'download' && (
              <a href={data.file} target="_blank" onClick={(e) => e.stopPropagation()}>
                <IconView />
              </a>
            )}

            {data.file && data.link_type === 'download' && (
              <a href={data.file} download={data.title} onClick={(e) => e.stopPropagation()}>
                <IconDownload />
              </a>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {data.image && <ResponsiveImage image={data.image} className={classes.image} />}

        {data.copy && <div className={classes.descriptionContainer} dangerouslySetInnerHTML={{ __html: data.copy }} />}
      </AccordionDetails>
    </Accordion>
  )
}

export default DownloadAccordion
