import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
      },
    },

    detail: {
      display: 'inline-flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(4),
      },

      '&:nth-child(2n+1)': {
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 0,
        },
      },

      '&$column': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '50%',
        },
      },

      '&$row': {
        maxWidth: 729,
      },
    },

    label: {
      fontFamily: 'ProximaNova-SemiBold',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      fontSize: 16,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      marginBottom: 8,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 16,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 17,
        lineHeight: 1.44,
        fontSize: 18,
      },
    },

    content: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.67,
        fontSize: 18,
      },
    },

    column: {},

    row: {},
  }),
  { name: 'Details' }
)
