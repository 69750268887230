import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    container: {
      paddingBottom: 17,
      paddingTop: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 0,
      borderColor: '#cccccc',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        paddingBottom: 16,
        paddingTop: 16,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 24,
        paddingTop: 24,
      },
    },

    dropdown: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },

    expandIcon: {
      transform: 'rotate(-180deg)',
      width: 30,
      height: 30,
    },

    arrowIcon: {
      width: 22,
      height: 22,
      [theme.breakpoints.up('lg')]: {
        width: 30,
        height: 30,
      },
      '&.colorOverwrite': {
        color: 'black',
      },
    },

    dropdownLabel: {
      fontFamily: 'ProximaNova-SemiBold',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.45,
      fontSize: 20,
      letterSpacing: 1,
      color: theme.palette.common.black,
      marginRight: 16,
      paddingBottom: 17,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.42,
        fontSize: 24,
      },
    },

    buttonsGroup: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: 0,
      borderColor: '#cccccc',
      borderTopWidth: 1,
      borderStyle: 'solid',
      paddingTop: 16,
      [theme.breakpoints.up('sm')]: {
        borderTopWidth: 0,
        paddingTop: 0,
        width: 80,
      },
      [theme.breakpoints.up('lg')]: {
        width: 96,
      },
    },

    button: {
      borderColor: theme.palette.common.black,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 50,
      width: 32,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      minWidth: 32,
      opacity: 0.2,
      cursor: 'default',
      '&.active': {
        opacity: 1,
        cursor: 'pointer',
      },
      [theme.breakpoints.up('lg')]: {
        width: 40,
        height: 40,
        minWidth: 40,
      },
      '&.colorOverwrite': {
        opacity: 1,
      },
    },
  }),
  { name: 'Controls' }
)
