import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        alignItems: 'start',
      },
    },

    title: {
      fontSize: 24,
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: 2,
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '42px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 33,
        lineHeight: '33px',
        textAlign: 'left',
      },

      '& sup': {
        lineHeight: 0,
        fontSize: '50%',
        WebkitTextStroke: '0.3px #000',
      },
    },

    descriptionWrap: {
      margin: '20px 0 31px 0',
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0 20px 0',
      },
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        margin: '5px 0 20px 0',
        maxWidth: 455,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '12px 0 31px 0',
        maxWidth: 305,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'left',
      },
    },
  }),
  { name: 'DocFinderHeader' }
)
