import React from 'react'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'

import CheckboxDefault from '../../../../../../../../public/icons/button-checkbox-default.svg'
import CheckboxPressed from '../../../../../../../../public/icons/button-checkbox-pressed.svg'

import useStyles from './Checkbox.styles'
import { Global } from '@emotion/react'

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  name?: string
  checked?: boolean
  color?: string
}

const CheckboxComponent: React.FC<Props> = ({ children, value, onChange, checked, color }) => {
  const classes = useStyles()

  return (
    <div className={classes.checkboxControl}>
      <Global styles={{ '.MuiFormControlLabel-root': { marginLeft: 0 } }} />
      <FormControlLabel
        control={
          <Checkbox
            disableRipple={false}
            icon={<CheckboxDefault width={20} height={20} />}
            checkedIcon={<CheckboxPressed width={20} height={20} />}
            color="default"
            className={classes.checkbox}
            onChange={onChange}
            value={value}
            checked={checked}
          />
        }
        label={
          <Typography
            variant="body1"
            className={classes.label}
            style={{ backgroundColor: 'transparent', color: color ? color : '#000' }}
          >
            {children}
          </Typography>
        }
      />
    </div>
  )
}

export default CheckboxComponent
