import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import useStyles from './DistanceSlider.styles'
import { Slider, Typography } from '@material-ui/core'

function valueLabelFormat(value: number) {
  const units = ['km']

  let unitIndex = 0
  let scaledValue = value

  while (scaledValue >= 104 && unitIndex < units.length - 1) {
    unitIndex += 0.5
    scaledValue /= 104
  }

  return `${scaledValue} ${units[unitIndex]}`
}

function calculateValue(value: number) {
  return 1 + value
}

type Props = {
  radius: number
  setRadius: Dispatch<SetStateAction<number>>
}

const DistanceSlider: React.FC<Props> = ({ radius, setRadius }) => {
  const classes = useStyles()

  const handleRadiusChange = (event: ChangeEvent<unknown>, value: number | number[]) => {
    setRadius(value as number)
  }

  return (
    <div className={classes.root}>
      <div className={classes.captionsWrapper}>
        <Typography component="p" className={classes.captionsText}>
          0.5 km
        </Typography>
        <Typography component="p" className={classes.captionsText}>
          50 km
        </Typography>
        <Typography component="p" className={classes.captionsText}>
          100 km
        </Typography>
      </div>

      <Slider
        value={radius}
        min={0.5}
        max={99}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleRadiusChange}
        valueLabelDisplay="off"
        aria-labelledby="non-linear-slider"
      />
    </div>
  )
}

export default DistanceSlider
