import React from 'react'
import ReactPlayer from 'react-player'

import { VideoListItem } from '../../../@types/Section/VideoList'

import VideoConsentOverlay from '../VideoConsentOverlay'

import useStyles from './VideoPlayer.styles'
import stripHtml from '../../helpers/stripHtml'

type Props = {
  data: VideoListItem
}

const VideoPlayer: React.FC<Props> = ({ data }) => {
  const styles = useStyles()

  const isYTVideo = data.video_type === 'yt-url'

  const videoFileName = (data.file || data.url || '').split('/').pop() || ''

  const [videoName] = videoFileName.split('.')

  const matomoTitle = data?.title ? stripHtml(data.title) : videoName

  const CustomPlayer = (
    <div className={styles.player + ' ' + data.title ? styles.image : styles.imageNoLabel}>
      {isYTVideo ? (
        <>
          <VideoConsentOverlay previewImage={data.poster_image} />
          <iframe
            allowFullScreen
            frameBorder="0"
            data-src={`${data.file || data.url || ''}?enablejsapi=1`}
            className="optanon-category-C0004"
          ></iframe>
        </>
      ) : (
        <ReactPlayer
          controls={true}
          playsinline={true}
          url={data.file || data.url || ''}
          config={{
            file: {
              attributes: {
                poster: data.poster_image.sizes.xl.url,
                'data-matomo-title': matomoTitle,
              },
              forceVideo: false,
            },
          }}
          width="100%"
          height="100%"
        />
      )}
    </div>
  )

  return CustomPlayer
}

export default VideoPlayer
