import { makeStyles, styled } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({}))

export default makeStyles(
  (theme) => ({
    root: {
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      backgroundColor: '#121212',
      [theme.breakpoints.up('sm')]: {},
      [theme.breakpoints.up('lg')]: {},
    },

    link: {
      fontSize: 12,
      lineHeight: 1.5,
      color: '#fff',
      letterSpacing: 0,
      textDecoration: 'underline',
      marginBottom: 24,
    },
  }),
  { name: 'FilterLabel' }
)
