import React, { useEffect } from 'react'

import { Tabs, Tab, MenuItem } from '@material-ui/core'

import dynamic from 'next/dynamic'

import { ColorOverwrite } from '../../../@types/Fields'

import BaseSelect from '../BaseSelect'

import TabPanel from './componets/TabPanel'

import { a11yProps } from './Tabs.helpers'

import useStyles from './Tabs.styles'

type Props<T> = {
  data: Array<{ title: string } & T>
  renderTabContent: (item: T) => React.ReactNode
  renderTab?: (item: T, component: React.ReactNode) => React.ReactNode
  onTabClick?: (title: string) => void
  defaultTab?: number // the index of the tab
  colorOverWrite?: ColorOverwrite
}

const TabsComponent = <T extends unknown>({
  data,
  renderTabContent,
  onTabClick,
  defaultTab,
  colorOverWrite,
}: Props<T>): React.ReactElement<T> => {
  const classes = useStyles({ color: colorOverWrite?.color })
  const [value, setValue] = React.useState(defaultTab || 0)

  useEffect(() => {
    setValue(defaultTab || 0)
  }, [defaultTab])

  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue)
  }

  const handleSelectChange = (data: { name?: string; value: number }) => {
    setValue(data.value)
  }

  const handleTabClick = (i: { title: string }) => () => {
    onTabClick && onTabClick(i.title || '')
  }

  const { tabs, content, selectOptions } = React.useMemo(() => {
    const result: [React.ReactNode[], React.ReactNode[], React.ReactNode[]] = [[], [], []]

    data.forEach((item, index) => {
      const selectOption = (
        <MenuItem key={`select_option_${index}`} value={index}>
          {item.title}
        </MenuItem>
      )

      const tab = (
        <Tab
          key={`tab_${index}`}
          disableRipple
          classes={{
            root: classes.tabRoot,
            textColorPrimary: classes.tabTextColorPrimary,
            selected: classes.tabSelected,
          }}
          label={item.title}
          {...a11yProps(index)}
          onClick={onTabClick ? handleTabClick(item) : undefined}
        />
      )

      const content = (
        <TabPanel key={`tab_panel_${index}`} value={value} index={index}>
          {renderTabContent(item)}
        </TabPanel>
      )

      result[0].push(tab)
      result[1].push(content)
      result[2].push(selectOption)
    })

    return {
      tabs: result[0],
      content: result[1],
      selectOptions: result[2],
    }
  }, [data, value])

  return (
    <div className={classes.root}>
      <span className={classes.selectWrap}>
        <BaseSelect
          className={classes.baseSelect}
          labelClassName={classes.selectLabel}
          selectClasses={{ root: classes.select }}
          selectIconClassName={classes.selectIcon}
          value={value}
          variant="standard"
          name="area"
          onChange={handleSelectChange}
          noBorders
        >
          {selectOptions}
        </BaseSelect>
      </span>

      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          className: classes.tabIndicator,
        }}
        aria-label="scrollable force tabs example"
        classes={{ root: classes.tabs, scroller: classes.scroller }}
      >
        {tabs}
      </Tabs>
      {content}
    </div>
  )
}

export default dynamic(() => Promise.resolve(TabsComponent), { ssr: false })
