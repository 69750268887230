import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {},

    socialsRoot: {
      margin: 0,
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(4.5),

      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        marginBottom: theme.spacing(5),
      },

      '& li svg': {
        width: 32,
        height: 32,
        [theme.breakpoints.up('lg')]: {
          width: 40,
          height: 40,
        },
      },

      '& li + li': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          marginLeft: 25,
        },
      },
    },
    socialItem: {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: -4,
    },

    feed: {
      display: 'grid',
      gridTemplate: `
        "A B"
        "C D"
      `,
      rowGap: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        gridTemplate: '"A B C D"',

        '& $feedItem + $feedItem': {
          '& $feedImageWrap': {
            borderLeft: 'none',
          },
        },
      },
    },

    feedItem: {
      width: '100%',
      height: '100%',

      '&:nth-child(even)': {
        '& $feedImageWrap': {
          borderLeft: 'none',
        },
      },
    },

    feedItemA: {
      gridArea: 'A',
    },

    feedItemB: {
      gridArea: 'B',
    },

    feedItemC: {
      gridArea: 'C',
    },

    feedItemD: {
      gridArea: 'D',
    },

    feedImageWrap: {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      padding: '0 12px 0 12px',
    },

    feedImage: {
      position: 'relative',
      width: '100%',
      height: '100%',

      '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        position: 'absolute',
      },
    },
  }),
  { name: 'SocialMedia' }
)
