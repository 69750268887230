import { Typography } from '@material-ui/core'

import InfoIcon from '../../../public/icons/icon-info.svg'

import useStyles from './Disclaimer.styles'

export type Props = {
  title: string
  text?: string
  copy?: string
}

const Disclaimer: React.FC<Props> = ({ title, text, copy }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InfoIcon className={classes.icon} />

      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.content}>
        {copy || text}
      </Typography>
    </div>
  )
}

export default Disclaimer
