import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(0.5),

    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
      overflow: 'hidden',
    },
  },

  sliderControlWrap: {
    display: 'none',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    zIndex: 100,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('lg')]: {
      width: 77,
      justifyContent: 'center',
      '&:hover div': {
        display: 'flex',
      },
    },
  },

  sliderControlWrapLeft: {
    left: 0,

    [theme.breakpoints.up('lg')]: {
      left: theme.spacing(0),
    },
  },

  sliderControlWrapRight: {
    right: 0,

    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(0),
    },
  },

  sliderControlIcon: {
    width: 40,
    height: 40,
    cursor: 'pointer',

    '& *': {
      transition: theme.transitions.create('all'),
    },

    '& svg': {
      fill: theme.palette.common.white,
    },

    '&:hover': {
      '& svg': {
        background: theme.palette.beige.main,
        fill: theme.palette.common.black,
      },
    },

    '&:active': {
      transform: 'scale(0.9)',
    },

    [theme.breakpoints.up('lg')]: {
      background: 'rgba(0, 0, 0, 0.2)',
      display: 'none',
    },
  },

  slides: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
    '& .react-swipeable-view-container': {
      '& [aria-hidden="true"]': {
        // opacity: '0.5',
        zIndex: -1,
        position: 'relative',
        left: '51vw',

        '& $captionTitle, $captionCopy': {
          opacity: 0,
        },
        '& $slideImage': {
          transform: 'scale(0.8)',
          left: 150,
        },
        '& $slideCaption': {
          border: 'none',
        },
      },

      '& [aria-hidden="false"] + [aria-hidden="true"]': {
        left: '-51vw',
      },
    },
  },

  slide: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'inline-flex',
    alignItems: 'center',

    '& img': {
      width: '100%',
      height: '100%',
      userDrag: 'none',
      userSelect: 'none',
      // maxWidth: 320,
    },

    '&[aria-hidden="true"] img': {
      opacity: 1,
      filter: 'brightness(50%)',
    },
  },

  slideContentWrap: {
    maxWidth: 306,
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 536.75,
    },
  },

  slideImage: {
    width: '100%',
    display: 'inline-block',
    transition: theme.transitions.create('all'),
  },

  slideCaption: {
    width: '100%',
    padding: theme.spacing(1, 0, 0, 0),
  },

  captionTitle: {
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '18px',
    textAlign: 'center',
    transition: theme.transitions.create('all'),

    '&$invert': {
      color: theme.palette.beige.main,
    },
  },

  copyWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 96,
  },

  captionCopy: {
    fontSize: 20,
    letterSpacing: 1,
    fontFamily: 'ProximaNova-Semibold',
    textAlign: 'center',
    lineHeight: '28px',
    transition: theme.transitions.create('all'),

    '&$invert': {
      color: theme.palette.common.white,
    },
  },

  invert: {},

  imageFallback: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  fallbackText: {
    '&$invert': {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
  },

  controlls: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(1.5),
  },

  // Modal below

  modalRoot: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      left: -164, // 1/2 of caption width + margin
    },
  },

  modalImage: {
    width: '100%',
    objectFit: 'contain',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 576,
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: 617,
      maxHeight: '90vh',
    },
  },

  modalBackDrop: {
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },

  modalCaption: {
    width: '100%',
    paddingLeft: 36,
    paddingRight: 36,
    paddingTop: 8,

    [theme.breakpoints.up('sm')]: {
      maxWidth: 576,
      paddingLeft: 0,
      paddingRight: 0,
    },

    [theme.breakpoints.up('lg')]: {
      width: 292,
      marginRight: 36,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
    },
  },

  modalCaptionTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.common.white,
    lineHeight: 1.5,
    margin: 0,
  },

  modalCaptionCopy: {
    fontSize: 12,
    color: theme.palette.common.white,
    lineHeight: 1.5,
    margin: 0,
  },

  modalClose: {
    position: 'absolute',
    top: 85,
    right: 32,
    width: 32,
    height: 32,
    cursor: 'pointer',
    zIndex: 101,

    [theme.breakpoints.up('sm')]: {
      top: 64,
      right: 40,
    },

    [theme.breakpoints.up('lg')]: {
      width: 40,
      height: 40,
      top: 60,
      right: 82,
    },
  },

  modalControlWrap: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    zIndex: 100,

    '&.left': {
      left: 20,
    },

    '&.right': {
      right: 20,
    },

    [theme.breakpoints.up('sm')]: {
      '&.left': {
        left: 40,
      },

      '&.right': {
        right: 40,
      },
    },

    [theme.breakpoints.up('lg')]: {
      '&.left': {
        left: 82,
      },

      '&.right': {
        right: 82,
      },
    },
  },

  modalControl: {
    cursor: 'pointer',
    width: 32,
    height: 32,
    tranition: theme.transitions.create('all'),

    '& *': {
      tranition: theme.transitions.create('all'),
    },

    '&:hover': {
      background: theme.palette.beige.main,
    },

    '&:active': {
      transform: 'scale(0.9)',
    },

    [theme.breakpoints.up('lg')]: {
      width: 40,
      height: 40,
    },
  },

  imageSliderDesktopInner: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },

  imageSliderDesktopInnerTransition: {
    transition: 'transform 0.3s',
  },

  imageSliderDesktopSlideWrap: {
    marginLeft: theme.spacing(2.75),
    marginRight: theme.spacing(2.75),
  },

  imageSliderDesktopSlide: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 317,
    overflow: 'hidden',
    '& img': {
      transition: 'transform .3s, filter .5s',
      filter: 'brightness(100%)',

      '&.notVisible': {
        filter: 'brightness(50%)',
      },
    },
    '&:hover img': {
      transform: 'scale(1.1)',
    },
  },

  imageSliderDesktopSlideIndicator: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: theme.palette.gray.dark,
    fontFamily: 'ProximaNova-LightIt',
    marginTop: theme.spacing(1),
    position: 'relative',
    zIndex: 2,
    backgroundColor: '#fff',
    width: '100%',
  },

  imageSliderDesktopSlideImage: {
    cursor: 'pointer',
  },
}))
