import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    header: {
      borderTop: '1px solid #ccc',
      paddingTop: 16,
      marginTop: 20,
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },

    title: {
      color: '#000',
      fontSize: 20,
      fontStyle: 'normal',
      fontFamily: 'ProximaNova-Semibold',
      lineHeight: '32px',
      fontStretch: 'normal',
      letterSpacing: '1px',
      paddingBottom: 24,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '24px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '28px',
        lineHeight: 1,
        paddingBottom: 41,
      },
    },

    label: {
      color: '#868686',
      fontSize: 12,
      fontStyle: 'normal',
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      lineHeight: '18px',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      paddingBottom: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 22,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.43,
        flexGrow: 0,
        maxWidth: '33.33%',
        flexBasis: '33.33%',
      },
    },

    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      rowGap: 40,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        columnGap: 15,
      },
    },
  }),
  { name: 'VideoList' }
)
