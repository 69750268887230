import { makeStyles } from '@material-ui/core'

export default makeStyles(
  () => ({
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  }),
  { name: 'BaseModal' }
)
