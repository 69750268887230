import React, { useRef } from 'react'

import { Typography, useTheme, useMediaQuery } from '@material-ui/core'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import { StandardStageFields } from '../../../@types/Section/StandardStage'

import AnkerLink from '../AnkerLink'

import useStyles from './StandardStage.styles'

type Props = {
  data: StandardStageFields
  onScrollToContent?: () => void
}

const StandardStage: React.FC<Props> = ({ data, onScrollToContent }) => {
  const { page_title, page_subtitle } = data

  const container = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  const classes = useStyles()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const desktopImage = data.image

  const mobileImage = data.image_mobile

  const image = isDesktop ? desktopImage : mobileImage

  const handleScrollToClick = () => {
    if (onScrollToContent) return onScrollToContent()

    requestAnimationFrame(() => {
      const sibling: HTMLElement = container?.current?.parentNode?.nextSibling as HTMLElement
      sibling?.scrollIntoView?.({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    })
  }

  const format = /[®]+/
  const hasSpacialCharacter = format.test(page_title)
  let editedPageTitle = page_title

  if (hasSpacialCharacter) {
    editedPageTitle = page_title.replace(new RegExp('®', 'g'), `<sup>®</sup>`)
  }

  return (
    <div id={data?.anchor_id || ''} className={classes.root} ref={container}>
      <div className={classes.content}>
        <div className={classes.titleWrap}>
          {data?.top_logo && <ResponsiveImage className={classes.topImage} image={data?.top_logo} />}
          <Typography variant="h1" className={classes.title} dangerouslySetInnerHTML={{ __html: editedPageTitle }} />

          <div className={classes.descriptionWrap}>
            <Typography component="p" className={classes.description}>
              {page_subtitle}
            </Typography>
          </div>
        </div>
        {image && (
          <div className={classes.blogInfoWrap}>
            {desktopImage && (
              <div className={classes.imageWrapDesktop}>
                <div className={classes.scrollDownWrap}>
                  <AnkerLink onClick={handleScrollToClick} />
                </div>

                <ResponsiveImage className={classes.img} image={desktopImage} />
              </div>
            )}
            {mobileImage && (
              <div className={classes.imageWrapMobile}>
                <div className={classes.scrollDownWrap}>
                  <AnkerLink onClick={handleScrollToClick} />
                </div>

                <ResponsiveImage className={classes.img} image={mobileImage} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default StandardStage
