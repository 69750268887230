import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      backgroundColor: '#fff',
      borderRadius: 100,
      textDecoration: 'none',
      color: '#000',
      fontFamily: 'ProximaNova-Regular',
      lineHeight: 1.29,
      letterSpacing: 0.5,
      fontSize: 14,
      padding: theme.spacing(1.5, 2.5),
      '&:hover': {
        backgroundColor: `${theme.palette.beige.main} !important`,
        borderColor: `${theme.palette.beige.main} !important`,
      },
      '&:active': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
  }),
  { name: 'LinkButton' }
)
