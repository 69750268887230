import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: 60,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        marginBottom: 100,
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },

    titleWrap: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      columnGap: 13,
      width: '100%',
      marginTop: 84,
      [theme.breakpoints.up('sm')]: {
        width: 564,
        columnGap: 0,
      },
      [theme.breakpoints.up('lg')]: {
        width: 880,
      },
    },

    title: {
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '42px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '64px',
        letterSpacing: 4,
      },

      '& sup': {
        fontSize: 22,
        position: 'relative',
        top: 5,
        [theme.breakpoints.up('sm')]: {
          fontSize: 26,
        },
        [theme.breakpoints.up('lg')]: {
          top: 0,
          fontSize: '32px !important',
        },
      },
    },

    titleSecondary: {
      fontSize: 36,
      lineHeight: '42px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '50px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '80px',
        letterSpacing: 4,
      },
    },

    descriptionWrap: {
      margin: '20px 0 0 0',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        margin: '24px 0 12px 0',
        width: 455,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '30px 0 38px 0',
        width: 619,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    scrollDownWrap: {
      display: 'flex',
      width: '100%',
      position: 'absolute',
      justifyContent: 'center',
    },

    blogInfoWrap: {
      position: 'relative',
      marginTop: 45,
      width: '100%',

      '& picture': {
        display: 'flex',
      },
    },

    img: {
      width: '100%',
      height: '100%',
    },

    topImage: {
      width: '100%',
      height: 72,
      maxWidth: 224,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    imageWrapDesktop: {
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    imageWrapMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
  { name: 'StandartStage' }
)
