import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    checkboxControl: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },

    checkbox: {
      padding: 0,
      marginRight: 15,
    },

    label: {
      cursor: 'pointer',
      fontFamily: 'ProximaNova-Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      display: 'flex',
      alignItems: 'center',
      marginRight: 0,
      color: theme.palette.common.white,
      /*
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        lineHeight: 1.29,
      },
      */
    },
  }),
  { name: 'Checkbox' }
)
