import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
  invertModuleColor?: boolean
  textColor?: string
}

export default makeStyles<Theme, Props>(
  (theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: (props) => (props?.color ? props?.color : '#f1b434'),
      [theme.breakpoints.up('sm')]: {
        marginTop: 198,
      },
    },

    video: {
      width: '100%',
      maxWidth: 836,
      zIndex: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',

      [theme.breakpoints.up('sm')]: {
        top: -96,
      },
    },

    videoTitle: {
      fontSize: 24,
      lineHeight: 1.33,
      letterSpacing: 1,
      fontWeight: 'normal',
      color: '#fff',
      textAlign: 'center',
      maxWidth: 780,
      width: '100%',
      marginBottom: 2,

      [theme.breakpoints.up('sm')]: {
        fontSize: 34,
        lineHeight: 1.41,
        marginBottom: 15,
      },
    },

    playWrap: {
      width: 44,
      height: 44,
      backgroundColor: '#fff',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      [theme.breakpoints.up('sm')]: {
        width: 88,
        height: 88,
      },
    },

    icon: {
      transform: 'rotate(90deg)',
      width: 44,
      height: 44,

      [theme.breakpoints.up('sm')]: {
        width: 64,
        height: 64,
      },
    },

    descriptionWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexDirection: 'column',
      paddingLeft: 36,
      paddingRight: 36,
      marginTop: 14,

      [theme.breakpoints.up('sm')]: {
        marginTop: -65,
      },
    },

    descriptionTitle: {
      fontSize: 20,
      lineHeight: 1.4,
      letterSpacing: 1,
      fontWeight: 600,
      marginBottom: 12,
      color: (props) => (props?.textColor ? props?.textColor : '#000'),

      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: 1.29,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: 1.57,
      letterSpacing: 0.5,
      fontWeight: 'normal',
      textAlign: 'center',
      maxWidth: 1056,
      width: '100%',
      marginBottom: 72,
      color: (props) => (props?.textColor ? props?.textColor : '#000'),
      '& *': {
        margin: 0,
      },

      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
        lineHeight: 1.67,
      },
    },

    videoOverlay: {
      position: 'absolute',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '98%',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  }),
  { name: 'VideoColorBg' }
)
