import React from 'react'

import { Modal } from '@material-ui/core'
import clsx from 'clsx'
import { ImageSliderSlide } from '../../../@types/Section/ImageSlider'

import IconClose from '../../../public/icons/icon-modal-close.svg'
import IconArrowLeftBoxed from '../../../public/icons/icon-arrow-left-boxed.svg'
import IconArrowRightBoxed from '../../../public/icons/icon-arrow-right-boxed.svg'

import useStyles from './ImageSlider.styles'

type Props = {
  data: ImageSliderSlide
  open?: boolean
  onNextImage?: () => void
  onPrevImage?: () => void
  onClose?: () => void
}

const ImageModal: React.FC<Props> = ({ data, open = false, onNextImage, onPrevImage, onClose }) => {
  const classes = useStyles()

  const portraitImage = data.format === 'portrait' && data.image_p
  const landscapeImage = data.format === 'landscape' && data.image_l

  const image = portraitImage || landscapeImage

  const caption = data.caption

  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ className: classes.modalBackDrop }}>
      <div className={classes.modalRoot}>
        <div className={classes.modalClose} onClick={onClose}>
          <IconClose />
        </div>
        <div className={clsx(classes.modalControlWrap, 'left')}>
          <IconArrowLeftBoxed onClick={onPrevImage} className={classes.modalControl} />
        </div>
        <div className={clsx(classes.modalControlWrap, 'right')}>
          <IconArrowRightBoxed onClick={onNextImage} className={classes.modalControl} />
        </div>

        <div className={classes.modalContent}>
          {image ? <img src={image?.sizes?.l?.url} alt={image?.alt} className={classes.modalImage} /> : <div></div>}

          <div className={classes.modalCaption}>
            <p className={classes.modalCaptionTitle}>{caption?.title}</p>
            <p className={classes.modalCaptionCopy}>{caption?.copy}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ImageModal
