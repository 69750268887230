import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'grid',
      border: 0,
      borderTopWidth: '1px',
      borderColor: '#cccccc',
      borderStyle: 'solid',
      paddingTop: 16,
      backgroundColor: theme.palette.common.white,
      marginTop: 60,
      marginBottom: 60,
      gridTemplateAreas: `
        "label"
        "heading"
        "copy"
        "copySidebar"
        "main"
        "disclaimer"
        "secondary"
        "secondarySidebar"
      `,

      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        marginBottom: 80,
      },

      [theme.breakpoints.up('lg')]: {
        paddingTop: 24,
        marginTop: 100,
        marginBottom: 100,
        gridTemplateAreas: `
        "label heading"
        "copySidebar copy"
        "disclaimer main"
        "secondarySidebar secondary"
      `,
        gridTemplateColumns: '33.333333% 66.666667%',
      },

      '&.no-label': {
        border: 'none',
      },
    },
    label: {
      gridArea: 'label',
    },
    heading: {
      gridArea: 'heading',
    },
    copySidebar: {
      gridArea: 'copySidebar',
    },
    copy: {
      gridArea: 'copy',
    },
    disclaimer: {
      gridArea: 'disclaimer',

      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        maxWidth: 292,
      },
    },
    main: {
      gridArea: 'main',
    },
    secondarySidebar: {
      gridArea: 'secondarySidebar',
    },
    secondary: {
      gridArea: 'secondary',
    },

    labelText: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 'normal',
      color: '#868686',
      textTransform: 'uppercase',
      paddingBottom: 15,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 22,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.43,
      },
    },
    headingText: {
      fontFamily: 'ProximaNova-Semibold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '1px',
      color: theme.palette.common.black,
      paddingBottom: 24,
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: '34px',
      },
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.41,
        paddingBottom: 41,
      },
    },
    copyText: {
      fontFamily: 'ProximaNova-Regular',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.5,
      color: theme.palette.common.black,
      paddingBottom: 24,
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 42,
        fontSize: 18,
        lineHeight: 1.67,
      },
    },
  }),
  { name: 'ModuleWrap' }
)
