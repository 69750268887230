import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import { AccordionEntry } from '../../../@types/Section/Accordion'

import IconExpand from '../../../public/icons/icon-expand.svg'

import useStyles from './Accordion.styles'

import EditorText from '../EditorText'
import { ColorOverwrite } from '../../../@types/Fields'

type Props = {
  data: AccordionEntry[]
  colorOverwrite?: ColorOverwrite
}

const AccordionComponent: React.FC<Props> = ({ data, colorOverwrite }) => {
  const classes = useStyles({ color: colorOverwrite?.color })

  const [expanded, setExpanded] = useState<string | boolean>(false)

  const handleExpand = (panel: string) => (event: any, isExpanded: boolean) => setExpanded(isExpanded ? panel : false)

  const renderAccordion = () =>
    data?.map((item, index) => {
      const id = `panel_${index}_${item.anchor_id}_${item.headline}`

      return (
        <Accordion
          key={id}
          expanded={expanded === id}
          classes={{
            root: classes.accordion,
            expanded: classes.accordionExpanded,
            rounded: classes.accordionRounded,
          }}
          id={item?.anchor_id}
          onChange={handleExpand(id)}
        >
          <AccordionSummary
            classes={{ root: classes.accordionSummary, content: classes.accordionContent, expanded: classes.expanded }}
            expandIcon={<IconExpand className={classes.icon} />}
            aria-controls={id}
            id={id}
          >
            <Typography component="h3" className={clsx(classes.title, expanded === id && classes.titleBold)}>
              {item?.headline}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <EditorText className={classes.description + ' no-top-margin'} content={item.copy || ''} />
          </AccordionDetails>
        </Accordion>
      )
    })

  return <>{renderAccordion()}</>
}

export default dynamic(() => Promise.resolve(AccordionComponent), { ssr: false })
