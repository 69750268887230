import { makeStyles, Theme } from '@material-ui/core'

type Props = {
  color?: string
  invertModuleColor?: boolean
  textColor?: string
}

export default makeStyles<Theme, Props>(
  (theme) => ({
    root: {
      marginTop: 60,
      marginBottom: 60,
      [theme.breakpoints.up('sm')]: {
        marginBottom: 80,
        marginTop: 80,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 100,
        marginTop: 100,
      },
    },

    slider: {
      width: '100%',
      backgroundColor: '#ccc',
    },

    descriptionWrap: {
      backgroundColor: '#121212',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '69px 35px 33px 35px',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        padding: '40px 35px 60px 35px',
      },
    },

    title: {
      fontSize: 20,
      lineHeight: '28px',
      color: '#fff',
      letterSpacing: 1,
      marginBottom: 24,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: 34,
        lineHeight: '48px',
        marginBottom: 14,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      color: '#fff',
      letterSpacing: 0.5,
      textAlign: 'center',
      marginBottom: 24,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1056,
        fontSize: 18,
        lineHeight: '30px',
        marginBottom: 60,
      },
    },

    btnWrap: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 262,
      },
    },

    btn: {
      backgroundColor: (props) => (props?.color ? props?.color : '#f1b434'),
      color: (props) => (props?.textColor ? props?.textColor : '#000'),
      fontSize: 14,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 262,
        padding: '13px 18px',
      },
    },
  }),
  { name: 'SliderWithCopyBlock' }
)
