import { makeStyles, styled } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({}))

export default makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('lg')]: {
        padding: '53px 35px 0 37px',
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        alignItems: 'start',
      },

      '& a:hover': {
        textDecorationColor: '#fff',
      },
    },

    link: {
      fontSize: 12,
      lineHeight: 1.5,
      color: '#fff',
      letterSpacing: 0,
      textDecoration: 'underline',
      marginBottom: 24,
      cursor: 'pointer',
    },

    iconWrap: {
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2.5px',
      borderRadius: 20,
      marginRight: 8,
      width: 12,
      height: 12,
    },

    star: {
      width: 8,
    },

    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },

    label: {
      color: '#E2E1DD',
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: 0,
    },

    title: {
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: 0.5,
      fontWeight: 600,
      color: '#fff',
      marginBottom: 17,
    },

    filterLabels: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      marginBottom: 24,
    },

    labels: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 16,
      rowGap: 4,
      flexWrap: 'wrap',
    },

    btnWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 10,
      marginTop: 16,
      paddingTop: 24,
      borderTop: `1px solid ${theme.palette.beige.main}`,

      '& a': {
        width: '100%',
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },

    btn: {
      color: '#000',
      backgroundColor: '#fff',
      width: '100%',
      marginBottom: 8,
    },

    btnSecondary: {
      border: '1px solid #fff',
      width: '100%',
      marginBottom: 8,
    },

    btnLink: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      color: '#fff',
      textDecoration: 'underline',
      padding: '0px 5px 0px 5px',
    },

    shareInfo: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      color: '#fff',
      padding: '15px 0',
    },

    infoWrap: {
      marginBottom: 20,
    },

    textInfo: {
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0,
      color: '#fff',
      marginBottom: 12,
    },

    info: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },

    icon: {
      width: 28,
      marginRight: 16,

      '& path': {
        fill: '#fff',
      },
    },
  }),
  { name: 'DocFinderSearchItemOpen' }
)
