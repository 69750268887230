import { makeStyles } from '@material-ui/core'

export default makeStyles(
  () => ({
    button: {
      position: 'absolute',
      top: 60,
      right: 60,
      padding: 0,
      margin: 0,
      borderRadius: 0,
      borderWidth: 0,
      minWidth: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      width: 40,
      height: 40,
    },
  }),
  { name: 'CloseButton' }
)
