import React, { useMemo, useState, useEffect } from 'react'

import { BlogTileFields } from '../../../@types/Section/BlogTiles'
import { ArticleTarget } from '../../../@types/Fields'
import { TabFilter as TabFilterType } from '../../../@types/Page'

import TabFilter from '../TabFilter'

import BlogTile from '../BlogTile'

import useStyles from './BlogTiles.styles'

type Props = {
  data: BlogTileFields
  filter?: TabFilterType
}

const BlogTiles: React.FC<Props> = ({ data, filter }) => {
  const [hashFilter, setHashFilter] = useState<string>((typeof window !== 'undefined' && window.location.hash) || '')
  const [selectedFilter, setSelectedFilter] = useState<number>(0)
  const classes = useStyles()

  const tiles = data?.tiles || []

  useEffect(() => {
    const slectedFilterIndex = getSelectedFilterIndex()

    setSelectedFilter(slectedFilterIndex)

    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  const articles = useMemo(() => {
    if (!hashFilter || hashFilter === '#Alle') return tiles

    const result = tiles.filter((tile) => {
      const categorie = `#${encodeURIComponent(tile.category)}`

      return categorie === hashFilter
    })

    return result
  }, [hashFilter, tiles])

  const getSelectedFilterIndex = (): number => {
    const hasWindow = typeof window !== 'undefined'

    if (!hasWindow) return 0

    const hashCategory = decodeURIComponent((typeof window !== 'undefined' && window.location.hash) || '').replace(
      '#',
      ''
    )

    const selectedFilterIndex = filter?.filter?.findIndex((item) => item.title === hashCategory) || 0

    return selectedFilterIndex === -1 ? 0 : selectedFilterIndex
  }

  const handleHashChange = () => {
    const slectedFilterIndex = getSelectedFilterIndex()

    setSelectedFilter(slectedFilterIndex)

    setHashFilter(window.location.hash || '')
  }

  const handleTabClick = (title: string) => {
    window.location.hash = title
  }

  // const handleLoadMore = () => {}

  return (
    <div className={classes.root}>
      {filter?.has_filter && (
        <TabFilter onClick={handleTabClick} items={filter?.filter || []} selected={selectedFilter} />
      )}

      <div className={classes.grid}>
        {articles.map((item: ArticleTarget, index) => (
          <BlogTile key={`${item.label}_${index}`} data={item} />
        ))}
      </div>

      {/* data.page < data.pageCount && (
        <div className={classes.loadMoreWrap}>
          <Button variant="outlined" className={classes.button} onClick={handleLoadMore}>
            <Typography className={classes.buttonLabel}>Load more</Typography>
          </Button>
        </div>
      ) */}
    </div>
  )
}

export default BlogTiles
