import React from 'react'
import { Typography, Button } from '@material-ui/core'
import clsx from 'clsx'

import { DownloadsFields, DownloadsItem } from '../../../@types/Section/Downloads'

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import IconDownload from '../../../public/icons/icon-download.svg'

import useStyles from './Download.styles'

type Props = {
  data: DownloadsFields
}

const Download: React.FC<Props> = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {data?.items?.map?.((item: DownloadsItem) => {
        const filePathParts = item.file.split('/')
        const fileName = filePathParts?.[filePathParts.length - 1]?.split?.('.')[0] || ''

        return (
          <div key={item.headline} className={classes.item}>
            <div className={classes.image}>
              {/* <img alt={item.preview_image.alt} src={item.preview_image.url} /> */}
              <ResponsiveImage image={item.preview_image} />
            </div>

            <div className={classes.content}>
              <Typography
                component="h3"
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: item.headline }}
              ></Typography>

              <Typography
                component="span"
                className={classes.description}
                dangerouslySetInnerHTML={{ __html: item.copy }}
              />

              <div className={classes.actions}>
                <Button
                  variant="outlined"
                  className={clsx(classes.button, classes.detailsButton)}
                  href={item.file}
                  target="_blank"
                  disableRipple={true}
                >
                  Ansehen
                </Button>

                <Button
                  variant="outlined"
                  className={clsx(classes.button, classes.downloadButton)}
                  href={item.file}
                  target="_blank"
                  classes={{
                    startIcon: classes.downloadIcon,
                  }}
                  startIcon={<IconDownload width={32} height={32} />}
                  download={fileName}
                  disableRipple={true}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Download
