const isTouchDevice = () => {
  return (
    (window && 'ontouchstart' in window) ||
    (navigator?.maxTouchPoints || 0) > 0 ||
    // @ts-ignore
    (navigator?.msMaxTouchPoints || 0) > 0
  )
}

export default isTouchDevice
